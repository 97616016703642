import { AxiosResponse } from 'axios';
import { DocumentsService } from '@/services';
import { DocumentsStoreInterface } from '@/types';
import { PersonStores } from '@/store';
import { defineStore } from 'pinia';
import download from 'downloadjs';
import { useRoute } from 'vue-router';

export default defineStore('DocumentsStore', {
  state: (): DocumentsStoreInterface => ({
    error: undefined,
    loading: false,
    documents: [],
  }),
  getters: {
    userDocuments: (state) => state.documents,
  },
  actions: {
    catchError(e: AxiosResponse) {
      this.error = e.data.message;
      this.loading = false;
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },
    async deleteDocument(id: number) {
      await DocumentsService.deleteDocument(id);
    },
    async getDocuments() {
      this.loading = true;
      this.error = undefined;
      const route = useRoute();
      const usersStore = PersonStores.useUserProfileStore();
      const userId = usersStore.id || route.params.id;
      const response = await DocumentsService.getDocuments(userId as number);
      this.documents = response.data?.data ?? [];
      this.loaded();
    },
    async uploadDocument(id: number, payload: FormData): Promise<AxiosResponse<unknown>> {
      this.loading = true;
      this.error = undefined;
      const result = await DocumentsService.uploadDocument(id, payload).catch((e) => {
        this.error = e.data.message;
        this.loading = false;
        return e;
      });

      this.loading = false;
      return result;
    },
    async downloadDocument(id: number, name: string) {
      const response = await DocumentsService.getDocument(id).catch(this.catchError);
      const content = response.headers['content-type'];

      download(response.data, name, content);
    },
  },
});
