import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import BillingAdminService from '@/services/BillingAdminService';
import { OrganizationsInterface } from '@/types';
import { BillingAdminOrganizationStateInterface } from '@/types/BillingAdmins';

export default defineStore('BillingAdminOrganizationStore', {
  state: (): BillingAdminOrganizationStateInterface => ({
    organizations: [],
    error: '',
    loading: false,
  }),

  getters: {},

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    async attachOrganizations(payload: {
      organizations: number[];
      billingAdminId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { billingAdminId, organizations } = payload;
      const response = await BillingAdminService.attachOrganizationsToBillingAdmin(organizations, billingAdminId).catch(
        this.catchError,
      );

      this.loaded();
      return response;
    },

    async detachOrganizations(payload: {
      organizations: number[];
      billingAdminId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { billingAdminId, organizations } = payload;
      const response = await BillingAdminService.detachOrganizationsFromBillingAdmin(
        organizations,
        billingAdminId,
      ).catch(this.catchError);
      this.loaded();
      return response;
    },

    async getAddOrganizationsModalData(billingAdminId: number): Promise<OrganizationsInterface[]> {
      const request = await BillingAdminService.getAddOrganizationsModalData(billingAdminId).catch(this.catchError);
      const availableToAdd: OrganizationsInterface[] = request.data?.data;
      return availableToAdd;
    },
  },
});
