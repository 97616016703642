import { app } from '@/main';

export const storeBase = () => {
  /**
   * ...One doToast() To Rule Them All...
   *
   * @param msq: string message
   * @param error:boolean
   */
  const doToast = (msg: string, summary = 'Success', error = false, life = 5000) => {
    app.config.globalProperties.$toast.add({
      severity: error ? 'error' : 'success',
      summary: error ? 'Error' : summary,
      detail: msg,
      life,
    });
  };

  return { doToast };
};
