import { defineStore } from 'pinia';
import { CountriesService } from '@/services';
import { CountryInterface } from '@/types';

export default defineStore('CountriesStore', {
  state: () => {
    return {
      loading: false,
      countries: [] as CountryInterface[],
    };
  },

  actions: {
    async getCountries() {
      this.loading = true;
      const response = await CountriesService.getCountries();
      this.countries = response.data.data;
      this.loading = false;
    },
  },
});
