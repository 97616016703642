import { defineStore } from 'pinia';
import { can, useArrayUtilities } from '@/composables';
import { PERMISSIONS } from '@/enum';
import { ServicesService } from '@/services';
import { CheckboxItemInterface, ServiceStateInterface } from '@/types';

const arrayUtilities = useArrayUtilities();

export default defineStore('ServicesStore', {
  state: (): ServiceStateInterface => ({
    services: [],
    clinicianServices: [],
    serviceIDs: [],
    error: '',
    loading: false,
  }),

  getters: {
    availableServices: (state) => {
      return state.services?.filter((service) => {
        return service.checked;
      });
    },
  },

  actions: {
    /* A method that is called when an error is thrown. */
    catchError(e: string) {
      this.loading = false;
      this.error = e;
      throw e;
    },
    /**
     * update the service
     * @param payload {id, checked}
     */
    updateService(payload: { id: string; checked: boolean }) {
      if (this.serviceIDs) {
        if (!payload.checked) {
          const newArray = arrayUtilities.removeFromArray(this.serviceIDs, payload.id);
          this.serviceIDs = newArray;
        } else {
          this.serviceIDs.push(payload.id);
        }
      }
    },

    /**
     * getServices
     *
     * call API for list of ALL services and org services
     * and merge the two
     *
     * @param commit
     */
    async getServices(organizationId?: number) {
      this.loading = true;
      const response = await ServicesService.getServices().catch(this.catchError);
      const allServices = response.data.data;
      let orgServices: CheckboxItemInterface[];
      if (organizationId) {
        const orgResponse = await ServicesService.getOrganizationServices(organizationId).catch(this.catchError);
        orgServices = orgResponse.data.data;
      }
      const ids: string[] = [];
      /**
       * loop through all the services and assign a checked boolean
       * for the UI
       */
      this.services = !organizationId
        ? allServices
        : allServices.map((service: CheckboxItemInterface) => {
            service.checked = false;

            //loop org selected IDS to compare and set boolean checked
            //and to push just the ORG DISCIPLINE ID string for filtering
            for (let i = 0; i < orgServices.length; i++) {
              if (orgServices[i].id === service.id) {
                ids.push(orgServices[i].id);
                service.checked = true;
                return service;
              }
            }
            return service;
          });

      this.serviceIDs = organizationId ? ids : allServices.map((s: CheckboxItemInterface) => s.id);
      this.error = undefined;
      this.loading = false;
      return this.services;
    },

    async getOrganizationServices(organizationId: number) {
      this.loading = true;

      if (!can([PERMISSIONS.ORGANIZATION_SERVICES, PERMISSIONS.ORGANIZATION_SERVICES_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view organization services';
        console.error(this.error);
        return;
      }

      const services = await ServicesService.getOrganizationServices(organizationId).catch(this.catchError);
      const orgServices = services.data.data;

      this.services = orgServices.map((service: CheckboxItemInterface) => {
        service.checked = true;
        return service;
      });

      this.error = undefined;
      this.loading = false;
    },

    async getClinicianServices(clinicianId: number) {
      if (!clinicianId) return;
      this.loading = true;

      const services = await ServicesService.getClinicianServices(clinicianId).catch(this.catchError);
      this.clinicianServices = services.data.data;

      this.error = undefined;
      this.loading = false;
    },

    async saveServices(organizationId: number) {
      this.loading = true;
      const response = await ServicesService.syncOrganizationServices(organizationId, {
        services: this.serviceIDs as string[],
      }).catch(this.catchError);

      this.loading = false;
      return response.status;
    },
  },
});
