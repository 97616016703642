import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { OrganizationService } from '@/services';
import { OrganizationParentsAssociatedInterface } from '@/types';

export default defineStore('OrganizationParentsStore', {
  state: () => {
    return {
      associatedParents: [] as OrganizationParentsAssociatedInterface[],
      showArchived: false,
      error: '',
      loading: false,
      page: 0,
      parentModalData: [] as { name: string; value: number }[],
      modalDataLoading: false,
    };
  },
  actions: {
    /* A method that is called when an error is thrown. */
    catchError(e: string) {
      this.loading = false;
      this.error = e;
      throw e;
    },

    /* Getting the parents associated with the organization. */
    async getParentsAssociatedOrganization(
      organizationId: number,
      filter: string,
    ): Promise<OrganizationParentsAssociatedInterface[]> {
      this.loading = true;
      const response = await OrganizationService.getAssociatedOrganization(organizationId, filter);
      this.associatedParents = response.data.data;
      this.loading = false;
      return this.associatedParents;
    },

    /**
     * getParentsForTable
     *
     * @return OrganizationParentsAssociatedInterface[]
     */
    async getParentsForTable(
      organizationId: number,
      filter: string,
    ): Promise<OrganizationParentsAssociatedInterface[]> {
      const rawParentsData = await this.getParentsAssociatedOrganization(organizationId, filter).catch(this.catchError);

      const tableData = rawParentsData.map((parent) => {
        return {
          id: parent.id,
          full_name: parent.full_name as string,
          archived_at: !!parent.archived_at,
        };
      });
      this.loading = false;
      return tableData;
    },

    /**
     * attachParents
     *
     * Assign facilitators to a specific organization
     * @param payload {parents: number[]}
     */
    async attachParents(payload: { parents: number[] }, organizationId: number): Promise<AxiosResponse<unknown>> {
      const response = await OrganizationService.attachPerson(payload, organizationId, 'parents');
      return response;
    },

    /**
     * detachParents
     *
     * Remove Parents from a specific organization
     * @param payload {parents: number[]}
     */
    async dettachParents(payload: { parents: number[] }, organizationId: number): Promise<AxiosResponse<unknown>> {
      const response = await OrganizationService.detachPerson(payload, organizationId, 'parents');
      this.loading = false;
      return response;
    },
    /* Getting the data for the modal that allows you to add parents to an organization. */
    async getAddParentsModalData(organizationId: number) {
      this.modalDataLoading = true;
      this.page++;
      const response = await OrganizationService.getPersonAvailableToAdd(organizationId, 'parents', this.page);

      const paginatedParents = response.data.data.map((parent) => {
        return {
          value: parent.id,
          name: parent.full_name as string,
        };
      });

      this.parentModalData = [...this.parentModalData, ...paginatedParents];
      this.modalDataLoading = false;
    },
  },
});
