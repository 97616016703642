import { defineStore } from 'pinia';

import { useReportStore } from '@/store';
import { useDisciplineDropdown, useDropdown, usePersonDropdown, useDownload } from '@/composables';
import { EventReportService, OrganizationService, ReportService, TherapyService } from '@/services';
import {
  DropdownOptionInterface,
  EventReportDownloadFileType,
  EventReportFilterInterface,
  EventReportInterface,
  EventReportStateInterface,
  ServiceInterface,
} from '@/types';

export default defineStore('EventReportStore', {
  state: (): EventReportStateInterface => {
    return {
      queryParams: undefined,
      error: false,
      events: {
        downloading: false,
        loading: false,
        data: [] as EventReportInterface[],
        total: 0,
        limit: 0,
        rsmToggle: 'Events',
      },
      filters: {
        allColumns: true,
        attendees: {
          options: [] as DropdownOptionInterface[],
          selected: null as null | number[],
          loading: false,
        },
        eventTypes: {
          options: [] as { name: string; value: number }[],
          selected: null as string[] | null,
          loading: false,
        },
        disciplines: {
          options: [] as { name: string; value: number }[],
          selected: null as number[] | null,
          loading: false,
        },
        therapyTypes: {
          options: [] as { name: string; value: number }[],
          selected: null as number[] | null,
          loading: false,
        },
        organizations: {
          options: [] as { name: string; value: number }[],
          selected: null as number[] | null,
        },
        order: '',
        order_by: '',
        page: 1,
        per_page: 15,
        searchingByDates: false,
        total_key: '',
        request_key: '',
      },
    };
  },

  getters: {
    canDownload: (state) => state.events.total < state.events.limit,
    showAllColumns: (state) => state.filters.allColumns,
  },

  actions: {
    defineFilters(filters: EventReportFilterInterface) {
      if (filters.organizations !== undefined) {
        filters.organizations = filters.organizations.toString().split(',');
      }

      if (filters.clinicians !== undefined) {
        filters.clinicians = filters.clinicians.toString().split(',');
      }

      if (filters.attendees !== undefined) {
        filters.attendees = filters.attendees.toString().split(',');
      }

      if (filters.disciplines !== undefined) {
        filters.disciplines = filters.disciplines.toString().split(',');
      }

      if (filters.event_types !== undefined) {
        filters.event_types = filters.event_types.toString().split(',');
      }

      if (filters.therapy_result_types !== undefined) {
        filters.therapy_result_types = filters.therapy_result_types.toString().split(',');
      }

      if (filters.per_page !== undefined) {
        filters.per_page = Number(filters.per_page);
      }

      if (filters.page !== undefined) {
        filters.page = Number(filters.page);
      }

      return filters;
    },

    /**
     * Get the attendees available for the specific organization or clinician.
     */
    async getAttendees() {
      const clinicianIds = useReportStore().filters.clinicians.selected;
      const noClinicians = !clinicianIds || !Object.keys(clinicianIds).length;
      const organizationIds = Object.keys(useReportStore().filters.organizations.selected).map((organizationId) =>
        Number(organizationId),
      );
      const startAt = useReportStore().filters.dates[0];
      const endAt = useReportStore().filters.dates[1];
      const attendeeListId = useReportStore().attendeeListCacheId;
      const { attendees } = this.filters;
      attendees.loading = true;
      const params = noClinicians
        ? {
            organizationIds,
            startAt,
            endAt,
            attendeeListId,
          }
        : {
            clinicianIds,
            organizationIds,
            startAt,
            endAt,
            attendeeListId,
          };

      const response = await ReportService.getReportAttendeeFilter(params);
      if (response?.data?.data) {
        const attendeeOptions = usePersonDropdown(response.data.data);
        attendees.options = attendeeOptions;
      }

      attendees.loading = false;
    },

    /**
     * Get the attendance types.
     */
    async getTherapyResultTypes() {
      const { therapyTypes } = this.filters;
      therapyTypes.loading = true;
      const response = await TherapyService.getTherapyResultTypes();
      const therapyTypeData = useDropdown(response.data.data);
      therapyTypes.loading = false;
      therapyTypes.options = therapyTypeData;
    },

    /**
     * Get the disciplines available for the specific organization.
     */
    async getDisciplines() {
      const { disciplines } = this.filters;
      disciplines.loading = true;
      const organizationIds = useReportStore().organizationIds;
      const response = await OrganizationService.getOrganizationDisciplines(organizationIds);
      const disciplineData = useDisciplineDropdown(response.data.data);
      disciplines.loading = false;
      disciplines.options = disciplineData;
    },

    /**
     * Get the event types (services) available for the specific organization.
     */
    async getEventTypes() {
      const { eventTypes } = this.filters;
      eventTypes.loading = true;
      const organizationIds = useReportStore().organizationIds;
      const response = await OrganizationService.getOrganizationServices(organizationIds);
      const eventTypeData = response.data.data.map(({ name }: ServiceInterface) => {
        return { name: name, value: name };
      });
      eventTypes.loading = false;
      eventTypes.options = eventTypeData;
    },

    catchError(e: Error) {
      throw e;
    },

    /**
     * Get the event report data with or without any selected filters.
     */
    async getEventsReport(filters: EventReportFilterInterface) {
      this.events.loading = true;
      const response = await EventReportService.getEventReport(filters).catch(() => {
        this.error = true;
      });

      if (!response) return;

      this.events.data = response.data.data;
      this.events.limit = response.data.meta?.min_records_to_queue;
      this.events.loading = false;
      this.error = false;
    },

    async getEventsCount(filters: EventReportFilterInterface) {
      const response = await EventReportService.getEventsCount(filters).catch(() => {
        this.error = true;
      });

      if (!response) return;
      this.events.total = response.data.count ? (this.events.total = response.data.count) : 0;
      this.error = false;
    },

    /**
     * Download the event report data with or without any selected filters.
     */
    async downloadEventsReport(filters: EventReportFilterInterface, fileType: EventReportDownloadFileType) {
      this.events.downloading = true;
      this.error = false;
      filters.row_count = this.events.total;
      const response = await EventReportService.downloadEventReport(filters, fileType).catch(this.catchError);
      const isRsm = filters.is_rsm ?? 0;
      const reportName = isRsm != 0 ? 'rsm_report' : 'events_report';

      if (response.status === 200) useDownload(response.data, reportName, fileType);

      return response;
    },

    clearStoreFilters() {
      this.queryParams = undefined;
      this.filters.attendees.selected = [];
      this.filters.eventTypes.selected = [];
      this.filters.disciplines.selected = [];
      this.filters.therapyTypes.selected = [];
      this.filters.organizations.selected = [];
      this.filters.searchingByDates = false;
    },
  },
});
