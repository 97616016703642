import { defineStore } from 'pinia';

import { PeopleService, PeopleTypesService } from '@/services';
import { PeopleFilterInterface, PeopleInterface, PeopleTotalsInterface, PersonCreateInterface } from '@/types';

interface PeopleStoreStateInterface {
  header: string;
  error: string | undefined;
  peopleTypes: [];
  loading: boolean;
  people: {
    loading: boolean;
    data: PeopleInterface[];
    total: number;
  };
  totals: {
    loading: boolean;
    data: PeopleTotalsInterface;
  };
}

export default defineStore('PeopleStore', {
  state: (): PeopleStoreStateInterface => {
    return {
      header: 'Users',
      error: undefined,
      loading: false,
      peopleTypes: [],
      people: {
        loading: false,
        data: [] as PeopleInterface[],
        total: 0,
      },
      totals: {
        loading: false,
        data: {} as PeopleTotalsInterface,
      },
    };
  },
  actions: {
    catchError(e: Error) {
      this.error = 'error';
      throw e;
    },
    async createPerson(payload: PersonCreateInterface) {
      const response = await PeopleService.createPerson(payload);
      return response.data.data;
    },

    async getPeople(filters: PeopleFilterInterface) {
      this.people.loading = true;
      const response = await PeopleService.getPeople(filters).catch(this.catchError);
      if (response) {
        this.people.data = response.data.data;
        this.people.total = response.data.meta.total;
      }
      this.people.loading = false;
    },
    async getAdminPeople(filters: PeopleFilterInterface) {
      this.people.loading = true;
      const response = await PeopleService.getAdminPeople(filters).catch(this.catchError);
      this.people.data = response.data.data;
      this.people.total = response.data ? response.data.meta.total : 0;
      this.people.loading = false;
    },
    async getTotals() {
      this.totals.loading = true;
      const response = await PeopleService.getTotals();
      this.totals.data = response.data ? response.data.data : 0;
      this.totals.loading = false;
    },

    /**
     * getpeopleTypes
     *
     * get the people types for dropdown
     */
    async getPeopleTypes() {
      this.loading = true;
      const response = await PeopleTypesService.getPeopleTypes().catch(this.catchError);
      this.peopleTypes = response.data.data;
      this.error = undefined;
      this.loading = false;
    },
  },
});
