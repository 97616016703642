import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { usePeopleStore } from '@/store';
import { can, useDateTime, usePersonTypes } from '@/composables';
import { PERMISSIONS } from '@/enum';
import { NotificationService, ProfileService } from '@/services';
import {
  AuthUserInterface,
  ExternalAssociationInterface,
  GeneralProfileInterface,
  LocationFormInterface,
  LocationInterface,
  NotificationInterface,
  PersonInterface,
  StudentInfoFormInterface,
  UserProfileInterface,
} from '@/types';
import {
  Activity,
  Connections,
  Documents,
  ProgressReport,
  TreatmentPlans,
  UserProfile,
} from '@/views/Users/components';

const DefaultProfileModel = {
  id: undefined,
  selectedUserRoutes: undefined,
  error: undefined,
  loading: false,
  general: undefined,
  student: undefined,
  location: undefined,
  external_id: undefined,
  person: undefined,
  isAuth: false,
  notifications: {
    data: undefined,
    loading: false,
  },
  currentRoute: undefined,
};

const DefaultLocationModel = {
  address: undefined,
  website: undefined,
  phone: undefined,
  zipcode: undefined,
  city_id: undefined,
  city_name: undefined,
  state_id: undefined,
  country_id: undefined,
};

export default defineStore('UserProfileStore', {
  /**
   * note: we need to return state DefaultProfileModel
   * like this in order to use $reset(). its a bug in pinia
   * https://github.com/vuejs/pinia/issues/593
   *
   * @returns state object
   */
  state: (): UserProfileInterface => ({ ...DefaultProfileModel }),

  actions: {
    catchError(e: Error): void {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded(): void {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * setUser
     *
     * on select in UserList.vue set the user info and
     * the header text to the users name and
     * call API for more info
     * @param id
     */
    async setUser(id: number): Promise<void> {
      this.loading = true;
      await this.getProfile(id);

      if (this.person) {
        this.id = this.person.id;
        this.setGeneral();
        this.setLocation();
        this.isAuth = false;
      }

      if (usePersonTypes.isStudent(this.person?.people_type?.acronym)) await this.setStudentInfo();
      this.updateRoutesAndHeader(id);
      this.loaded();
    },

    async updateRoutesAndHeader(id?: number): Promise<void> {
      this.setRoutes(id ?? (this.person?.id as number), this.person?.people_type?.acronym as string);
      this.setHeaderText();
    },

    /**
     * setRoutes()
     *
     * set the routes for injection in the router tab for the
     * particular user selected
     *
     * @param id
     */
    setRoutes(id: number, peopleType: string): void {
      const routes = [
        {
          to: `/user/${id}`,
          label: 'Profile',
          component: UserProfile,
          visible: can([PERMISSIONS.PEOPLE_INDEX]),
          disabled: () => this.currentRoute === 'user.profile',
        },
        {
          to: `/user/${id}/activity`,
          label: 'Activity',
          component: Activity,
          visible: can([PERMISSIONS.PERSON_LOGS_INDEX]),
          disabled: () => this.currentRoute === 'user.activity',
        },
        {
          to: `/user/${id}/connections`,
          label: 'Connections',
          component: Connections,
          visible: can([PERMISSIONS.CONNECTIONS_INDEX]),
          disabled: () => this.currentRoute === 'user.connections',
        },
        {
          to: `/user/${id}/documents`,
          label: 'Documents',
          component: Documents,
          visible: can([PERMISSIONS.PERSON_DOCUMENTS_INDEX]),
          disabled: () => this.currentRoute === 'user.documents',
        },
        {
          to: `/user/${id}/progress-report`,
          label: 'Progress Report',
          component: ProgressReport,
          visible:
            can([PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX, PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX_STUDENTS]) &&
            usePersonTypes.isStudent(peopleType),
          disabled: () => this.currentRoute === 'user.progress',
        },
        {
          to: `/user/${id}/treatment-plans`,
          label: 'Treatment Plans',
          component: TreatmentPlans,
          visible:
            can([PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX, PERMISSIONS.PERSON_TREATMENT_PLANS_INDEX_STUDENTS]) &&
            usePersonTypes.isStudent(peopleType),
          disabled: () => this.currentRoute === 'user.treatmentPlans',
        },
      ];

      this.selectedUserRoutes = routes.filter((route) => route.visible);
    },

    /**
     * setHeaderText()
     *
     * set the header text display based on the user name
     */
    setHeaderText(): void {
      const usersStore = usePeopleStore();
      if (this.person && this.person.display_name) {
        usersStore.header = this.person.display_name;
      } else {
        usersStore.header = 'User';
      }
    },

    /**
     * getProfile on the user
     *
     * call API for User by ID
     *
     * @param commit
     */
    async getProfile(id: number) {
      if (id) {
        this.loading = true;
        const route = useRouter();
        const response = await ProfileService.getProfileById(id).catch((error) => {
          if (error.status === 403) {
            route.push('/');
          }
          this.catchError;
        });
        this.person = response?.data.data;
        this.loading = false;
      }
    },

    /**
     * getProfile on the user
     *
     * call API for User by ID
     *
     * @param commit
     */
    async getAuthUserProfile(): Promise<PersonInterface> {
      this.loading = true;

      const response = await ProfileService.getProfile().catch(this.catchError);

      this.person = response?.data.data;
      if (this.person) {
        this.id = this.person.id;
        this.setAuthUserGeneral(this.person);
        this.setLocation();
        this.isAuth = true;
      }

      this.loaded();

      return this.person as PersonInterface;
    },

    /**
     * set the profile on the user data returned
     * for the general form
     * @param data
     */
    setGeneral(): void {
      if (this.person) {
        const data: PersonInterface = this.person;
        this.general = {
          last_name: data.last_name,
          first_name: data.first_name,
          timezone_id: data.timezone_id,
          person_type_id: data.person_type_id,
          email: data.email,
          zoom_classroom: data.zoom_classroom,
        };
      }
    },

    /**
     * set the profile on the user data returned
     * for the general form
     * @param data
     */
    setAuthUserGeneral(data: AuthUserInterface) {
      this.general = {
        last_name: data.last_name,
        first_name: data.first_name,
        email: data.email,
        timezone_id: data.timezone_id,
      };

      if (data.type) this.general.person_type_id = data.type.id;
    },

    /**
     * set the student info object
     */
    async setStudentInfo(): Promise<void> {
      this.student = {
        grade: this.person?.student_information?.grade,
        gender: this.person?.gender,
        comment: this.person?.student_information?.comment,
        birthday: this.person?.birthday,
      };
    },

    /**
     * set the location info on the user data returned
     * for location form
     * @param data
     */
    setLocation(): void {
      if (this.person) {
        if (this.person.location) {
          const data: LocationInterface = this.person.location;
          if (data) {
            this.location = {
              address: data.address,
              location_type_id: data.location_type?.id,
              website: data.website,
              phone: data.phone,
              zipcode: data.zipcode,
              city_id: data.city?.id,
              city_name: data.city?.name,
              state_id: data.state?.id,
              country_id: data.country?.id,
            };
          }
        } else {
          this.location = DefaultLocationModel;
        }
      }
    },

    /**
     * update the general info form data
     * @returns result status of 200 or error
     */
    async updateGeneralInfo(data: GeneralProfileInterface) {
      if (this.id) {
        const result = await ProfileService.update(`people/${this.id}`, data).catch(this.catchError);
        this.person = result?.data?.data;
        this.general = data;
        await this.updateRoutesAndHeader();
        this.setGeneral();
        this.loaded();
        return result?.status;
      }
      return -1;
    },

    /**
     * update the general profile info form data
     * @returns result status of 200 or error
     */
    async updateGeneralProfileInfo(payload: GeneralProfileInterface) {
      if (this.id) {
        const result = await ProfileService.update(`profile`, payload);
        this.general = payload;
        this.loaded();
        return result?.status;
      }
      return -1;
    },

    /**
     * update the student info form data
     * @returns result status of 200 or error
     */
    async updateStudentInfo(payload: StudentInfoFormInterface) {
      if (this.id) {
        if (payload.birthday) {
          const dateUtils = useDateTime();
          payload.birthday = dateUtils.formatDateForApi(payload.birthday as string);
        } else {
          delete payload.birthday;
        }
        if (!payload.grade) delete payload.grade;
        if (!payload.gender) delete payload.gender;
        if (!payload.comment) payload.comment = '';

        const result = await ProfileService.update(`people/${this.id}`, payload).catch(this.catchError);
        await this.setUser(this.id);
        return result?.status;
      }
    },

    /**
     * update the external ID from the form data
     * @returns result status of 200 or error
     */
    async updateExternalId(payload: ExternalAssociationInterface) {
      if (this.id && this.person) {
        const result = await ProfileService.updateExternalId(`people/${this.id}`, payload).catch(this.catchError);
        this.loaded();
        return result?.status;
      }
      return -1;
    },

    /**
     * update zoom link
     * @returns result status of 200 or error
     */
    async updateZoomLink(data: GeneralProfileInterface) {
      if (this.person) {
        const result = await ProfileService.update(`people/${this.id}`, data).catch(this.catchError);
        this.loaded();
        return result?.status;
      }
      return -1;
    },

    /**
     * if there is any change to the name then update the Header text
     */
    updateHeaderText() {
      const usersStore = usePeopleStore();
      //set the header text
      if (this.general && this.general.display_name) {
        usersStore.header = this.general.display_name;
      } else {
        usersStore.header = 'User';
      }
    },

    /**
     *update the location info form data
     * @returns result.status
     */
    async updateLocationInfo(data: LocationFormInterface) {
      if (this.id) {
        const result = await ProfileService.updateLocation(`people/${this.id}/location`, data).catch(this.catchError);
        this.loaded();
        this.location = data;
        return result?.status;
      }
      return -1;
    },

    async getNotifications() {
      if (this.notifications) {
        this.notifications.loading = true;
        const response = await NotificationService.getNotifications();
        this.notifications.data = response.data.data.map((item: NotificationInterface) => {
          item.status = !!item.notification_person;
          return item;
        });
        this.notifications.loading = false;
      }
    },

    async syncNotifications(payload: number[]) {
      if (this.notifications) {
        this.notifications.loading = true;
        const response = await NotificationService.syncNotifications({ notifications: payload });
        this.notifications.data = response.data.data.map((item: NotificationInterface) => {
          item.status = !!item.notification_person;
          return item;
        });
        this.notifications.loading = false;
      }
    },
  },

  getters: {
    external_association_id: (state: UserProfileInterface) => {
      if (state.person?.external_association) return state.person.external_association.external_id;
    },
    studentInfo: (state) => state.student,
    userId: (state) => state.id,
    isArchived: (state: UserProfileInterface) => !!state.person?.archived_at,
  },
});
