import { ApiPromise, StudentsOfCliniciansParamsInterface } from '@/types';

import { HTTP } from '@/utils';

export default class ClinicianService {
  static resource = 'clinicians';

  static getOrganizationsForClinicianRequest(clinicianId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${clinicianId}/organizations`);
  }

  static getAddOrganizationsModalData(clinicianId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${clinicianId}/organizations/available-to-add`);
  }

  static getAddStudentsModalData(clinicianId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${clinicianId}/students/available-to-add`);
  }

  static attachOrganizationsRequest(organizations: number[], clinicianId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${clinicianId}/attach-organizations`, { organizations });
  }

  static attachStudentsRequest(students: number[], clinicianId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${clinicianId}/attach-students`, { students });
  }

  static detachStudentsRequest(students: number[], clinicianId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${clinicianId}/detach-students`, { students });
  }

  static detachOrganizationsRequest(organizations: number[], clinicianId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${clinicianId}/detach-organizations`, { organizations });
  }

  static getDisciplinesAtthachedForClinician(clinicianId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${clinicianId}/disciplines`);
  }

  static syncDisciplinesRequest(clinicianId: number, disciplines: Array<string>, primary: number): ApiPromise {
    return HTTP.post(`${this.resource}/${clinicianId}/sync-disciplines`, { disciplines, primary });
  }

  /**
   * GET REQUESTS
   */

  static getStudentsOfClinicianRequest(clinicianId: number, params?: StudentsOfCliniciansParamsInterface): ApiPromise {
    if (!params) params = {};
    return HTTP.get(`${this.resource}/${clinicianId}/students`, { params });
  }

  static getStudentsOfMultipleCliniciansRequest(clinicianIds: string): ApiPromise {
    return HTTP.get(`${this.resource}-students${clinicianIds ? `/${clinicianIds}` : ''}`);
  }

  static getCliniciansForOrganizationRequest(organizationsId: number): ApiPromise {
    return HTTP.get(`organizations/${organizationsId}/clinicians`);
  }

  static getCliniciansForOrganizationParentsRequest(organizationsId?: Array<number>): ApiPromise {
    if (organizationsId && organizationsId.length > 0) {
      return HTTP.get(`organizations-clinician-parents`, { params: { organizations: organizationsId } });
    } else {
      return HTTP.get(`organizations-clinician-parents`);
    }
  }

  static getAddCliniciansModalDataRequest(organizationId: number): ApiPromise {
    return HTTP.get(`organizations/${organizationId}/clinicians/available-to-add`);
  }

  /**
   * POST REQUESTS
   */

  static attachCliniciansToOrganizationRequest(clinicians: number[], organizationId?: number): ApiPromise {
    return HTTP.post(`organizations/${organizationId}/attach-clinicians`, { clinicians });
  }

  static detachCliniciansToOrganizationRequest(clinicians: number[], organizationId?: number): ApiPromise {
    return HTTP.post(`organizations/${organizationId}/detach-clinicians`, { clinicians });
  }
}
