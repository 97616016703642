import { defineStore } from 'pinia';
import { TenantService } from '@/services';
import { TenantSettingsInterface } from '@/types';
import { can } from '@/composables';
import { PERMISSIONS } from '@/enum';

export default defineStore('TenantSettingStore', {
  state: () => {
    return {
      loading: {
        settings: false,
      },
      settings: [] as TenantSettingsInterface[],
    };
  },

  actions: {
    async getTenantSettings() {
      this.loading.settings = true;
      const response = await TenantService.getTenantSettings();
      this.settings = response.data.data;
      this.loading.settings = false;
    },

    async saveSettings(payload: TenantSettingsInterface) {
      this.loading.settings = true;
      await TenantService.saveTenantSettings(payload);
      this.loading.settings = false;
    },

    async updateSettings(payload: TenantSettingsInterface) {
      this.loading.settings = true;
      if (can([PERMISSIONS.TENANT_SETTINGS_UPDATE])) await TenantService.updateTenantSettings(payload);
      this.loading.settings = false;
    },
  },
});
