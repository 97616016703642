import './registerServiceWorker';
import Maska from 'maska';
import { createPinia } from 'pinia';
import Tooltip from 'primevue/tooltip';
import { createApp } from 'vue';
import { VueCookieNext } from 'vue-cookie-next';
import { vue3Debounce } from 'vue-debounce';
import components from '@/components';
import BaseLayout from '@/layouts/BaseLayout';
import router from '@/router';
import App from './App.vue';
import { storeBase } from './store/plugins';

export const app = createApp(App);
app.component('BaseLayout', BaseLayout);
app.directive('tooltip', Tooltip);

if (process.env.NODE_ENV !== 'production') {
  app.config.performance = true;
}

app.use(components);
app.use(router);
app.use(Maska);
app.use(VueCookieNext);
app.directive('debounce', vue3Debounce({ lock: true }));
app.provide('doToast', storeBase().doToast);
const pinia = createPinia();
app.use(pinia);
pinia.use(storeBase);
app.mount('#app');
