interface FileTypes {
  csv: string;
  xlsx: string;
  pdf: string;
  mp3: string;
  zip: string;
}

/**
 * The only downloadable file types.
 */
export const MimeTypes = {
  csv: 'text/csv',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  mp3: 'audio/mpeg',
  zip: 'application/zip',
};

/**
 * Force a file download containing the provided data and other parameters.
 * type: 'application/octet-stream'
 */
export function useDownload(data: string | Uint8Array, name: string, type: string) {
  const fileName = `${name}.${type}`;
  const mimeType: string = MimeTypes[type as keyof FileTypes];
  const blob = new Blob([data as keyof FileTypes], { type: mimeType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
