import { LAYOUTS, PERMISSIONS } from '@/enum';

const Reports = () => import(/* webpackChunkName: "reports" */ '@/views/Reports/Reports.vue');
const EventsReport = () =>
  import(/* webpackChunkName: "reports-events" */ '@/views/Reports/EventsReport/EventsReport.vue');
const ReviewReport = () =>
  import(/* webpackChunkName: "reports-review" */ '@/views/Reports/ReviewReport/ReviewReport.vue');
const ProgressReport = () =>
  import(/* webpackChunkName: "reports-progress" */ '@/views/Reports/ProgressReport/ProgressReport.vue');
const PayrollReport = () =>
  import(/* webpackChunkName: "reports-payroll" */ '@/views/Reports/PayrollReport/PayrollReport.vue');
const StudentTotalReport = () =>
  import(/* webpackChunkName: "reports-student-total" */ '@/views/Reports/StudentTotalReport/StudentTotalReport.vue');

export default [
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    beforeEnter: (to: any, from: any, next: any) => {
      if (to.name === 'reports') {
        next('/reports/events');
      } else {
        next();
      }
    },
    children: [
      {
        path: '',
        name: 'reports.events',
        component: EventsReport,
        meta: {
          layout: LAYOUTS.DEFAULT,
          title: 'Reports - eLuma Insight',
          permission: [PERMISSIONS.REPORTS_VIEW_INDEX],
        },
      },
      {
        path: 'review',
        name: 'reports.review',
        component: ReviewReport,
        meta: {
          layout: LAYOUTS.DEFAULT,
          title: 'Reports - Insight TMS',
          permission: [PERMISSIONS.REPORTS_REVIEW_INDEX],
        },
      },
      {
        path: 'progress',
        name: 'reports.progress',
        component: ProgressReport,
        meta: {
          layout: LAYOUTS.DEFAULT,
          title: 'Reports - eLuma Insight',
          permission: [PERMISSIONS.REPORTS_PROGRESS_INDEX],
        },
      },
      {
        path: 'payroll',
        name: 'reports.payroll',
        component: PayrollReport,
        meta: {
          layout: LAYOUTS.DEFAULT,
          title: 'Reports - eLuma Insight',
          permission: [PERMISSIONS.REPORTS_PAYMENT_INDEX],
        },
      },
      {
        path: 'student-total',
        name: 'reports.student-total',
        component: StudentTotalReport,
        meta: {
          layout: LAYOUTS.DEFAULT,
          title: 'Reports - Insight TMS',
          permission: [PERMISSIONS.REPORTS_STUDENT_TOTAL_INDEX],
        },
      },
    ],
    meta: {
      layout: LAYOUTS.DEFAULT,
      title: 'Reports - eLuma Insight',
      permission: [PERMISSIONS.REPORTS_VIEW_INDEX],
    },
  },
];
