import { TimezoneInterface, ZipcodeInterface } from '@/types';
import { TimezonesService, ZipcodeService } from '@/services';

import { defineStore } from 'pinia';

export default defineStore('TimezoneStore', {
  state: () => {
    return {
      loading: false,
      timezones: [] as TimezoneInterface[],
      hostTimezone: {} as TimezoneInterface,
    };
  },

  actions: {
    catchError(e: Error) {
      throw e;
    },

    async getTimezones() {
      this.loading = true;
      const response = await TimezonesService.getTimezones().catch(this.catchError);
      this.timezones = response.data.data;
      this.loading = false;
    },
    async getTimezone(id: number) {
      this.loading = true;
      const response = await TimezonesService.getTimezone(id).catch(this.catchError);
      this.hostTimezone = response.data.data;
      this.loading = false;
    },
    async searchTimezonesByZipcode(zipcode: string) {
      this.loading = true;
      const response = await ZipcodeService.searchTimezonesByZipcode(zipcode);
      this.timezones = response.data.data.map((item: ZipcodeInterface) => {
        const data = item.timezone as TimezoneInterface;
        data.zipcode = item.zipcode;
        return data;
      });
      this.loading = false;
    },
  },
});
