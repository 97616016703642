// Package library components
import AppButton from './AppButton/Button.vue';
import AppDateRangePicker from './AppDateRangePicker/DateRangePicker.vue';

// Primevue global components
import Button from 'primevue/button';
import Card from 'primevue/card';
import Checkbox from 'primevue/checkbox';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Password from 'primevue/password';
import PrimeVue from 'primevue/config';
import Skeleton from 'primevue/skeleton';
import Tag from 'primevue/tag';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

// Application components
import ActionMenu from './ActionMenu/ActionMenu.vue';
import Loader from './Loader';
import FormCard from './Forms/FormCard';
import Icon from './Icon';
import ConfirmAction from './ConfirmAction';

export default {
  // eslint-disable-next-line
  install(Vue: any) {
    Vue.use(PrimeVue);
    Vue.use(ToastService);
    Vue.use(ConfirmationService);
    Vue.component('ActionMenu', ActionMenu);
    Vue.component('Button', Button);
    Vue.component('Card', Card);
    Vue.component('Column', Column);
    Vue.component('DataTable', DataTable);
    Vue.component('Dropdown', Dropdown);
    Vue.component('InputNumber', InputNumber);
    Vue.component('InputSwitch', InputSwitch);
    Vue.component('InputText', InputText);
    Vue.component('Message', Message);
    Vue.component('Password', Password);
    Vue.component('Checkbox', Checkbox);
    Vue.component('Tag', Tag);
    Vue.component('Toast', Toast);
    Vue.component('Skeleton', Skeleton);
    Vue.component('Loader', Loader);
    Vue.component('AppButton', AppButton);
    Vue.component('AppDateRangePicker', AppDateRangePicker);
    Vue.component('Icon', Icon);
    Vue.component('FormCard', FormCard);
    Vue.component('ConfirmAction', ConfirmAction);
  },
};
