import { useDateTime } from '@/composables';
import { ApiPromise, TreatmentPlanServiceCreationInterface } from '@/types';
import { ServiceFilterInterface } from '@/types/Service';
import { TreatmentGoalFormInterface } from '@/types/TreatmentPlans';
import { HTTP } from '@/utils';

export default class TreatmentPlansService {
  static resource = 'treatment-plans';

  /*
    GET REQUESTS
  */

  static getTreatmentPlanNotesRequest(planId: string | undefined): ApiPromise {
    return HTTP.get(`${this.resource}/${planId}/notes`);
  }

  static getTreatmentPlanServicesRequest(planId: string, payload?: ServiceFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof ServiceFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.resource}/${planId}/services${filter}`);
  }

  static getTreatmentFrequencyTypesRequest(): ApiPromise {
    return HTTP.get(`treatment-frequency-types`);
  }

  static getTreatmentPlanTypesRequest(): ApiPromise {
    return HTTP.get(`treatment-plan-types`);
  }

  static getTreatmentServiceTypesRequest(): ApiPromise {
    return HTTP.get(`treatment-service-types`);
  }

  static getTreatmentServiceGoalsTypesRequest(): ApiPromise {
    return HTTP.get(`treatment-goal-types`);
  }

  static getTreatmentGoalsRequest(serviceId: number | undefined): ApiPromise {
    return HTTP.get(`treatment-services/${serviceId}/goals`);
  }

  /*
    POST REQUESTS
  */

  /**
   * addServiceRequest
   *
   * Parses, formats new service data, makes request to add service to student treatment plan
   *
   * @param newService TreatmentPlanServiceCreationInterface
   * @param planId string | undefined
   * @param userId number
   */
  static addServiceRequest(
    newService: TreatmentPlanServiceCreationInterface,
    planId: string | undefined,
    userId: number,
  ): ApiPromise {
    // If a custom frequency is selected, the request needs frequency and interval included
    // in the newService object. If there is not a custom frequency, frequency and interval need to be
    // omitted
    const customFrequencySelected = newService.treatment_frequency_type?.acronym === 'CUSTOM';
    newService.interval = newService.interval?.toLowerCase();
    newService.person_id = userId;
    newService.start_at = useDateTime().formatDateForApi(newService.start_at as string);
    newService.end_at = useDateTime().formatDateForApi(newService.end_at as string);
    newService.treatment_frequency_type_id = newService.treatment_frequency_type?.id;

    // If custom frequency is not selected, remove frequency and interval from the request
    if (!customFrequencySelected) {
      delete newService.frequency;
      delete newService.interval;
    }
    delete newService.treatment_frequency_type;
    return HTTP.post(`${this.resource}/${planId}/services`, newService);
  }

  static addTreatmentPlanNoteRequest(payload: string, planId: string | undefined): ApiPromise {
    const requestPayload = {
      note: payload,
      title: 'Treatment Note',
    };

    return HTTP.post(`${this.resource}/${planId}/notes`, requestPayload);
  }

  static deleteServiceRequest(serviceId: number | undefined, planId: string | undefined): ApiPromise {
    return HTTP.delete(`${this.resource}/${planId}/services/${serviceId}`);
  }

  static expireServiceRequest(serviceId: number | undefined, planId: string | undefined): ApiPromise {
    return HTTP.delete(`${this.resource}/${planId}/services/${serviceId}/expire`);
  }

  static getTreatmentPlanExpireInformation(
    planId: string | undefined,
    treatmentServiceId: number | undefined,
  ): ApiPromise {
    return HTTP.get(`${this.resource}/${planId}/services/${treatmentServiceId}/before-expire`);
  }

  static updateServiceRequest(
    updatedService: TreatmentPlanServiceCreationInterface,
    planId: string | undefined,
    userId: number,
    serviceId: number | undefined,
  ): ApiPromise {
    // If a custom frequency is selected, the request needs frequency and interval included
    // in the newService object. If there is not a custom frequency, frequency and interval need to be
    // omitted
    const customFrequencySelected = updatedService.treatment_frequency_type?.acronym === 'CUSTOM';
    updatedService.interval = updatedService.interval?.toLowerCase();
    updatedService.person_id = userId;
    updatedService.start_at = useDateTime().formatDateForApi(updatedService.start_at as string);
    updatedService.end_at = useDateTime().formatDateForApi(updatedService.end_at as string);
    updatedService.treatment_frequency_type_id = updatedService.treatment_frequency_type?.id;

    // If custom frequency is not selected, remove frequency and interval from the request
    if (!customFrequencySelected) {
      delete updatedService.frequency;
      delete updatedService.interval;
    }
    delete updatedService.treatment_frequency_type;
    return HTTP.patch(`${this.resource}/${planId}/services/${serviceId}`, updatedService);
  }

  static addTreatmentPlanGoalsRequest(
    payload: { treatment_goals: TreatmentGoalFormInterface[] },
    serviceId: number | undefined,
  ): ApiPromise {
    return HTTP.post(`treatment-services/${serviceId}/goals`, payload);
  }

  static updateTreatmentPlanGoalsRequest(
    payload: { treatment_goals: TreatmentGoalFormInterface[] },
    serviceId: number | undefined,
  ): ApiPromise {
    return HTTP.patch(`treatment-services/${serviceId}/goals`, payload);
  }

  static deleteTreatmentPlanGoalsRequest(treatment_goals: number[]): ApiPromise {
    return HTTP.delete(`treatment-goals/delete`, { data: { treatment_goals: treatment_goals } });
  }
}
