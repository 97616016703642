// eslint-disable-next-line
export function extractErrorMessage(err: any): string {
  if (err?.data?.errors) {
    return Object.values(err.data.errors).flat().join(', ');
  } else if (err?.data?.message) {
    return err.data.message;
  } else {
    return 'Failed to process the request';
  }
}
