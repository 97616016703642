import ActivityLogService from '@/services/ActivityLogService';
import { ActivityLogStoreInterface } from '@/types/ActivityLog';
import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';

export default defineStore('ActivityLogStore', {
  state: (): ActivityLogStoreInterface => {
    return {
      error: undefined,
      loading: false,
      logs: [],
      filters: {
        start_at: '',
        end_at: '',
        search: '',
      },
    };
  },
  getters: {
    userLogs: (state) => state.logs,
  },
  actions: {
    catchError(e: AxiosResponse) {
      this.error = e.data.message;
      this.loading = false;
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    getLogs: async function (userId: number) {
      this.loading = true;
      this.error = undefined;
      this.loading = true;
      const response = await ActivityLogService.getLogs(userId, this.filters);
      this.logs = response.data.data;
      this.loaded();
    },
  },
});
