import { ApiPromise, ReviewReportFilterInterface } from '@/types';
import { HTTP } from '@/utils';
import ReportService from './ReportService';
import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';

export default class ReviewReportService extends ReportService {
  static resource = 'review';
  static requestCancel = useRequestCancel();

  static requestUrl(endpoint: string): string {
    return `${ReportService.resource}/${this.resource}/${endpoint}`;
  }

  static getReviewReportTrack(payload: ReviewReportFilterInterface): ApiPromise {
    return HTTP.get(`${this.requestUrl('track')}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_REVIEW_TRACK),
    });
  }

  static getReviewReportClinicians(payload: ReviewReportFilterInterface): ApiPromise {
    return HTTP.get(`${this.requestUrl('clinicians')}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_REVIEW_CLINICIANS),
    });
  }

  static getReviewReportClinicianMinutes(payload: ReviewReportFilterInterface): ApiPromise {
    return HTTP.get(`${this.requestUrl('clinician-minutes')}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_REVIEW_CLINICIAN_MINUTES),
    });
  }

  static getReviewReportTherapyResult(payload: ReviewReportFilterInterface): ApiPromise {
    return HTTP.get(`${this.requestUrl('therapy-result')}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_REVIEW_ATTENDANCE),
    });
  }

  static getReviewReportStudents(payload: ReviewReportFilterInterface): ApiPromise {
    return HTTP.get(`${this.requestUrl('students')}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_REVIEW_STUDENTS),
    });
  }

  static getReviewReportExitedStudents(payload: ReviewReportFilterInterface): ApiPromise {
    return HTTP.get(`${this.requestUrl('exited-students')}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_REVIEW_EXITED_STUDENTS),
    });
  }
}
