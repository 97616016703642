import { AuthUserInterface } from '@/types';
/*eslint-disable*/

export const useChurnZero = (user: AuthUserInterface, externalId: string) => {
  if (!process.env.VUE_APP_ENABLE_CHURN_ZERO) return;
  //@ts-ignore
  let ChurnZero = window.ChurnZero || [];
  //@ts-ignore
  (function (a, b, c, d) {
    const cz = a.createElement(b);
    //@ts-ignore
    cz.type = c;
    //@ts-ignore
    cz.async = true;
    //@ts-ignore
    cz.src = d;
    var s = a.getElementsByTagName(b)[0];
    //@ts-ignore
    s.parentNode.insertBefore(cz, s);
  })(document, 'script', 'text/javascript', 'https://elumatherapy.us1app.churnzero.net/churnzero.js');

  ChurnZero.push(['setAppKey', '1!o5k9SFSuwo-wDAkuCna0TS7uccJFvx6yx7i6ehwaCtAt1261']);
  ChurnZero.push(['setContact', externalId, user.email]);
};

export const useTrackEvent = (pageName?: string) => {
  if (!process.env.VUE_APP_ENABLE_CHURN_ZERO) return;
  //@ts-ignore
  let ChurnZero = window.ChurnZero || [];
  if (pageName) ChurnZero.push(['trackEvent', 'Page Visited', pageName]);
};
