import { LAYOUTS } from '@/enum';

const NotFound = () => import(/* webpackChunkName: "errors-404" */ './404.vue');

export default [
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: {
      layout: LAYOUTS.DEFAULT,
      permission: [],
      title: '404 - eLuma Insight',
    },
  },
];
