import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useDateTime } from '@/composables';
import { PayrollReportService } from '@/services';
import { DateFilterInterface, PaymentInterface } from '@/types';

export const usePaymentStore = defineStore('PaymentStore', () => {
  const payments = ref<PaymentInterface[]>([]);
  const loading = ref<boolean>(true);
  const error = ref<string>('');
  const baseDates = useDateTime().baseDates();
  const start_at = ref<Date>(baseDates[0]);
  const end_at = ref<Date>(baseDates[1]);
  const total = ref<number>(0);

  async function getCurrentUserPayments(organizationIds: number[] = []) {
    loading.value = true;
    const filters: DateFilterInterface = {
      start_at: useDateTime().formatDateForApi(start_at.value),
      end_at: useDateTime().formatDateForApi(end_at.value),
    };
    const response = await PayrollReportService.getCurrAuthUserData(filters, organizationIds);
    payments.value = response.data?.data;
    loading.value = false;
  }

  return {
    payments,
    loading,
    error,
    start_at,
    end_at,
    total,
    getCurrentUserPayments,
  };
});
