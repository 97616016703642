import { LocationQuery } from 'vue-router';
import { useTimezoneStore, useAuthStore } from '@/store';

export const useDateTime = () => {
  /**
   * when selecting a second month in billing invoices set to the last day of the month
   * if dayOffset is 0 its getting the last day of the month before
   * @param date
   * @returns
   */
  const convertInvoiceDateToNextMonth = (date: Date, monthOffset = 1, dayOffset = 0, useIntlDate = true) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const newDate = new Date(year, month + monthOffset, dayOffset);
    return useIntlDate ? formatIntlDate(newDate.toLocaleDateString('en-CA')) : formatDateForApi(newDate);
  };

  /**
   * get the last date of the month
   * @param date
   *
   */
  const getEndOfMonthDate = (dateStr: string, monthOffset = 2) => {
    const start = new Date(dateStr);
    const startMonth = start.getMonth();
    const end = new Date(start.getFullYear(), startMonth + monthOffset, 0);
    return formatDateForApi(end);
  };

  /**
   * add days defaulted to 30 to a date
   */
  const addDaysToDate = (date: Date, days: 30) => {
    const newDate = date.setDate(date.getDate() + days);
    return formatDateForApi(new Date(newDate));
  };

  /**
   * add years defaulted to 1 to date
   */
  const addYearsToDate = (date: string | Date, years = 1) => {
    const dateObject = new Date(date);
    dateObject.setFullYear(dateObject.getFullYear() + years);
    return formatDateForApi(dateObject);
  };

  /**
   * base dates for multiple Calendar date inputs
   * @param monthOffset
   * @returns
   */
  const baseDates = (monthOffset = 1) => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const start = new Date(year, month, 1);
    const end = new Date(year, month + monthOffset, 0);

    return [start, end];
  };

  /**
   * Define a  default start date object for the month
   * start = first of this month
   * @returns start:Date
   */
  const baseMonth = (): Date => {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const start = new Date(year, month, 1);
    return start;
  };

  /**
   * make sure there is an end_at and that it falls after the start_date for billing invoices
   * @param query
   * @returns
   */
  const invoiceDatesFormattedCorrectly = (query: LocationQuery) => {
    if (query['start_at'] && query['end_at'] === undefined) return false;
    if (new Date(query['start_at'] as string).getTime() > new Date(query['end_at'] as string).getTime()) return false;

    return true;
  };

  /**
   * @param date {string} 2022-11-08T12:33:51.859Z or 2022-11-08 12:33:51
   * @returns Returns the Long date:
   */
  const formatLongDate = (date: string) => {
    const [year, month, day] = date
      .split(/T| /)[0]
      .split('-')
      .map((part) => Number(part));
    return new Date(year, month - 1, day).toLocaleDateString('default', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });
  };

  /**
   *  Takes in a timestamp param of: 2022-03-09 17:52:44
   *  And formats to: HH:MM:SS AM/PM
   * @param dateString
   * @param showSeconds
   * @returns time date
   */
  const formatTimeByTimezone = (dateString: string | Date, timezone: string, showDate = false, showSeconds = false) => {
    if (dateString) {
      const str = typeof dateString !== 'string' ? dateString.toString() : dateString;
      const stringDate = checkProperIntlFormatting(str);

      const options: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
      };

      if (timezone) options.timeZone = timezone;

      if (showDate) {
        (options.year = 'numeric'), (options.month = 'numeric'), (options.day = 'numeric');
      }

      if (showSeconds) options.second = 'numeric';

      const dtf = new Intl.DateTimeFormat('en-US', options);
      return dtf.format(Date.parse(stringDate as string));
    }
    return '';
  };

  const formatLocalDateTime = (dateString: string, showSeconds = false, showDate = false) => {
    let date = new Date();
    if (dateString) date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
    };
    if (showSeconds) options.second = 'numeric';

    if (showDate) {
      (options.year = 'numeric'), (options.month = 'numeric'), (options.day = 'numeric');
    }
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  /**
   * Takes in a timestamp param of: 2022-03-09 17:52:44
   * And formats to: HH:MM AM/PM
   */
  const formatAMPMTime = (date: string) => {
    if (date.length === 19) {
      const toFormat = `${date.replace(' ', 'T')}Z`;
      const toUTC = toDate(toFormat).toLocaleString('en');
      try {
        return new Intl.DateTimeFormat('default', {
          hour: 'numeric',
          minute: 'numeric',
        }).format(toDate(toUTC));
      } catch (e) {
        console.error(e);
      }
    } else return date;
  };

  /**
   * return a time string
   * @param time
   * @param fromUTC
   * @returns
   */
  const formatHourMinute = (time: string, fromUTC?: boolean) => {
    if (fromUTC) {
      const tempTimeArr: string[] = time.split(' ');
      const timeArr: number[] = tempTimeArr[0]
        .split('-')
        .concat(tempTimeArr[1].split(':'))
        .map((num) => parseInt(num));

      return new Date(
        Date.UTC(timeArr[0], timeArr[1] - 1, timeArr[2], timeArr[3], timeArr[4], timeArr[5], 0),
      ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  /**
   * Gets the current year.
   */
  const getYear = () => new Date().getFullYear();

  /**
   * gets the age of the participant by their birthday
   * @param dateString
   * @returns
   */
  const getAge = (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  /**
   * checks the contract date or caseload management date windows for validity
   * and returns a boolean
   * @param date
   */
  const dateIsNotValidInWindow = (allowedDates: { start_at: string; end_at: string }, date: Date) => {
    const utcDate = new Date(toUTC(date));
    const startAt = new Date(toUTC(new Date(allowedDates.start_at)));
    const endAt = new Date(toUTC(new Date(allowedDates.end_at)));

    return utcDate > endAt || utcDate < startAt;
  };

  /**
   * Takes in a timestamp param of: 2022-03-09 17:52:44
   * And formats to intl date time  MM-DD-YYYY (en-us)
   */
  const formatIntlDate = (dateString: string, showTime = false) => {
    if (dateString) {
      const str = checkProperIntlFormatting(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      };

      if (showTime) options.hour = options.minute = 'numeric';

      const dtf = new Intl.DateTimeFormat('en-US', options);
      return dtf.format(Date.parse(str));
    }
  };

  /**
   * take the selected time and convert to the SP timezone
   * @param date: Date
   * @param tz: string
   * @param addDiff:boolean
   * @returns new TZ date
   */
  const changeTimezone = (date: Date, tz = 'UTC', addDiff = true) => {
    let tzdate = date;
    if (tz != '') {
      tzdate = new Date(
        date.toLocaleString('en-US', {
          timeZone: tz,
        }),
      );
    }
    const diff = date.getTime() - tzdate.getTime();
    return addDiff ? new Date(date.getTime() + diff) : new Date(date.getTime() - diff);
  };

  /**
   * Takes in a timestamp param of: 2022-03-09 17:52:44
   * And formats to local date MM/DD/YYYY (en-US)
   */
  const getLocalDate = (dateTime: string) => new Date(dateTime).toLocaleDateString('en-US');

  /**
   * check and fix any formatting issues for proper intl date format
   * also add T00:00:00.000 to the string to force the use of the users time
   * @param dateString
   */
  const checkProperIntlFormatting = (dateString: string) => {
    const strAr = dateString.split('-');
    //has to be 3 nodes: mm-dd-yyy
    if (strAr.length < 3) return dateString;
    //if the third node is longer than 3 there is a time sig so return it. if there is a Z remove it
    //to keep it in the local machine timezone
    else if (strAr[2].length > 4) {
      const removeZ = dateString.replace('Z', '');
      return removeZ;
    }
    //if its a YYYY at the end move it to the beginning
    else if (strAr[2].length === 4) return `${strAr[2]}/${strAr[0]}/${strAr[1]}T00:00:00.000`;
    else return `${strAr[0]}-${strAr[1]}-${strAr[2]}T00:00:00.000`;
  };

  /**
   * Takes in a timestamp param of: 2022-03-09 17:52:44
   * And formats to: Oct 01, 2021
   */
  const formatDateShortMonth = (dateString: string) => {
    if (dateString) {
      return new Intl.DateTimeFormat('default', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(toDate(dateString));
    }
  };

  /**
   * convert dateString to date
   * @param dateString
   * @returns Date
   */
  const toDate = (dateString: string) => {
    return new Date(dateString);
  };

  /**
   * Receive the date from api in UTC format and returns it in local date.
   * This same process is done by the calendar. Reference https://time.is/UTC
   * Example: Takes a date from the database 2022-12-05 14:00:00 UTC
   * Using the browser at America/Sao_Paulo gives you 'Mon Dec 05 2022 11:00:00 GMT-0300 (Brasilia Standard Time)'
   * Using the browser at America/New_York gives you 'Mon Dec 05 2022 09:00:00 GMT-0500 (Eastern Standard Time)'
   * @returns Date
   */
  const fromUTCStringToLocalDate = (dateUTC: string) => {
    const [date, time] = dateUTC.split(' ');
    const [year, month, day] = date.split('-').map(Number);
    const [hours, minutes, seconds] = time.split(':').map(Number);

    return new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
  };

  /**
   * Get the timezone from the authUser and fallback to MST if it is not there.
   */
  const timezone = () =>
    useAuthStore() && useAuthStore().user?.timezone?.name
      ? (useAuthStore().user?.timezone?.name as string)
      : 'America/Denver';

  /**
   * Find the timezone within state based on the users browser timezone.
   */
  const findTimezone = () => {
    const timezoneStore = useTimezoneStore();
    return timezoneStore.timezones.find((item) => item.name === timezone());
  };

  /**
   * convert a base date with time to abse UTC date to compare days (not time)
   * this is used in the eventModal for contract date params comparison
   * @param date {date}
   * @returns string;
   */
  const toUTC = (date: Date) => {
    return new Date(formatDateForApi(date.toUTCString(), false));
  };

  /**
   * return all varying dates as string and in the same TZ for comparison
   * @param date
   * @returns
   */
  const formatUTCString = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };
    const dateStr = toUTC(date).toString();
    const dtf = new Intl.DateTimeFormat('en-US', options);
    return dtf.format(Date.parse(dateStr)).replaceAll('-', '/');
  };

  /**
   * Format date to format accepted by API
   * @param dateString {string}
   * @param includeTime {boolean}
   * @returns datestring ex: YYYY-MM-DD or YYYY-MM-DD hh:mm:ss
   */
  const formatDateForApi = (dateString?: string | Date | null, includeTime?: boolean): string => {
    if (!dateString) dateString = new Date().toString();
    if (typeof dateString === 'string') dateString = dateString.padEnd(12, ' ').replaceAll('-', '/');
    const date = new Date(dateString);
    const [month, day, year] = [date.getUTCMonth() + 1, date.getUTCDate(), date.getUTCFullYear()];
    const twoDigitDay = day.toString().length === 1 ? `0${day}` : day;
    const twoDigitMonth = month.toString().length === 1 ? `0${month}` : month;
    if (!includeTime) {
      return `${year}-${twoDigitMonth}-${twoDigitDay}`;
    }
    const minutes =
      date.getUTCMinutes().toString().length === 1 ? '0' + date.getUTCMinutes().toString() : date.getUTCMinutes();
    const hours = date.getUTCHours().toString().length === 1 ? '0' + date.getUTCHours().toString() : date.getUTCHours();
    return `${year}-${twoDigitMonth}-${twoDigitDay} ${hours}:${minutes}:00`;
  };

  /**
   * Format date to format accepted by API without converting timezone
   * @param dateString {string}
   * @param includeTime {boolean}
   * @returns datestring ex: YYYY-MM-DD or YYYY-MM-DD hh:mm:ss
   */
  const formatDateForApiWithoutConvertTimezone = (dateString?: string | Date | null, includeTime?: boolean): string => {
    if (!dateString) dateString = new Date().toString();
    if (typeof dateString === 'string') dateString = dateString.padEnd(12, ' ').replaceAll('-', '/');
    const date = new Date(dateString);
    const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
    const twoDigitDay = day.toString().length === 1 ? `0${day}` : day;
    const twoDigitMonth = month.toString().length === 1 ? `0${month}` : month;
    if (!includeTime) {
      return `${year}-${twoDigitMonth}-${twoDigitDay}`;
    }
    const minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes().toString() : date.getMinutes();
    const hours = date.getHours().toString().length === 1 ? '0' + date.getHours().toString() : date.getHours();
    return `${year}-${twoDigitMonth}-${twoDigitDay} ${hours}:${minutes}:00`;
  };

  const getLastSchoolDay = (date = new Date()): Date => {
    let year = date.getUTCFullYear();
    //if current date falls after june, set year to next year
    if (date.getUTCMonth() > 5) year++;
    return new Date(year, 5, 30);
  };

  const getNumberOfDaysBetweenTwoDates = (dateOne: Date, dateTwo: Date): number => {
    const diff = Math.abs(dateOne.getTime() - dateTwo.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

  const startEndValid = (dateOne: Date, dateTwo: Date): boolean => dateOne < dateTwo;

  const isSameMonthAndYear = (dateToCheck: Date) => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const monthToCheck = dateToCheck.getMonth();
    const yearToCheck = dateToCheck.getFullYear();

    return currentMonth === monthToCheck && currentYear === yearToCheck;
  };

  const setDateStringToSpecificDate = (target: string, value: string): string => {
    const start = new Date(target);
    const [month, day, year] = [start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCFullYear()];
    const endTime = value.split(' ')[1];

    return `${year}-${month}-${day} ${endTime}`;
  };

  const sameDay = (d1: Date, d2: Date) => {
    return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
  };

  /**
   * Converts a date string from "YYYY/MM/DD at HH:MM AM/PM TZ" format to "MM/DD/YYYY"
   * @param dateString A date string in the format "YYYY/MM/DD at HH:MM AM/PM TZ"
   * @returns A date string in the format "MM/DD/YYYY"
   */
  const formatToMMDDYYYY = (dateString: string): string => {
    if (!dateString) {
      return 'N/A';
    }

    const parts = dateString.split(' at ');
    const datePart = parts[0];

    const separator = datePart.includes('/') ? '/' : '-';
    const dateParts = datePart.split(separator);

    if (dateParts.length !== 3) {
      return 'Invalid date';
    }

    const [year, month, day] = dateParts;

    if (parts.length > 1) {
      const timePart = parts[1];
      return `${month}/${day}/${year} at ${timePart}`;
    } else {
      return `${month}/${day}/${year}`;
    }
  };

  /**
   *
   * @param date
   * some dates come in with a T seperator which screws up TZ changes
   * so we need to remove that and parse the date back
   * @returns
   */
  const splitTimeDate = (date: string) => {
    let stringDate = date;
    //if there is still a Time stamp seperator ('T') then split it at the T and format
    if (stringDate.match('T')) {
      const strArray = stringDate.split('T');
      //if there are two nodes and the first node is YYYY-MM-dd then we have a time for the second node
      if (strArray.length === 2 && strArray[0].length == 10) {
        const time = strArray[1].split('.');
        stringDate = `${strArray[0]} ${time[0]}`;
      }
    }
    return stringDate;
  };

  return {
    addDaysToDate,
    addYearsToDate,
    baseDates,
    baseMonth,
    changeTimezone,
    checkProperIntlFormatting,
    convertInvoiceDateToNextMonth,
    dateIsNotValidInWindow,
    findTimezone,
    formatAMPMTime,
    formatHourMinute,
    formatLongDate,
    formatDateForApi,
    formatDateForApiWithoutConvertTimezone,
    formatDateShortMonth,
    formatIntlDate,
    formatLocalDateTime,
    formatTimeByTimezone,
    formatToMMDDYYYY,
    formatUTCString,
    fromUTCStringToLocalDate,
    getAge,
    getLocalDate,
    getEndOfMonthDate,
    getLastSchoolDay,
    getNumberOfDaysBetweenTwoDates,
    getYear,
    invoiceDatesFormattedCorrectly,
    isSameMonthAndYear,
    sameDay,
    setDateStringToSpecificDate,
    splitTimeDate,
    startEndValid,
    timezone,
    toDate,
    toUTC,
  };
};
