import { StudentOrganizationInterface, StudentOrganizationStateInterface } from '@/types/Student';

import { AxiosResponse } from 'axios';
import { StudentService } from '@/services';
import { defineStore } from 'pinia';
import { OrganizationsInterface } from '@/types';

export default defineStore('StudentOrganizationStore', {
  state: (): StudentOrganizationStateInterface => ({
    organizations: [],
    organizationsForStudentSelected: undefined,
    error: '',
    loading: false,
  }),
  getters: {},
  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },
    /**
     * Assign organization to a specific student
     */
    async attachOrganizations(payload: {
      organizations: number[];
      studentId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { studentId, organizations } = payload;
      const response = await StudentService.attachOrganizationsRequest(organizations, studentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * Remove organization from a specific student
     */
    async detachOrganizations(payload: {
      organizations: number[];
      studentId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { studentId, organizations } = payload;
      const response = await StudentService.detachOrganizationsRequest(organizations, studentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * getOrganizationForStudent
     *
     * Retrieve organization assigned to a student
     *
     */
    async getOrganizationsForStudent(studentId: number): Promise<StudentOrganizationInterface | undefined> {
      const response = await StudentService.getOrganizationsForStudentRequest(studentId).catch(this.catchError);
      this.organizationsForStudentSelected = response.data?.data as StudentOrganizationInterface;

      if (!this.organizationsForStudentSelected) {
        return undefined;
      }

      this.loaded();
      return this.organizationsForStudentSelected;
    },

    /**
     * Retrieve organization available to attach to a student
     */
    async getAddOrganizationsModalData(studentId: number): Promise<OrganizationsInterface[]> {
      const request = await StudentService.getAddOrganizationsModalData(studentId).catch(this.catchError);
      const availableToAdd: OrganizationsInterface[] = request.data?.data;

      return availableToAdd;
    },
  },
});
