import { StudentClinicianInterface, StudentClinicianStateInterface } from '@/types/Student';

import { AxiosResponse } from 'axios';
import { StudentService } from '@/services';
import { defineStore } from 'pinia';

export default defineStore('StudentClinicianStore', {
  state: (): StudentClinicianStateInterface => ({
    clinicians: [],
    cliniciansForStudentSelected: [],
    error: '',
    loading: false,
  }),

  getters: {},

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * Assign clinician to a specific student
     */
    async attachClinicians(payload: { clinicians: number[]; studentId: number }): Promise<AxiosResponse<unknown>> {
      const { studentId, clinicians } = payload;
      const response = await StudentService.attachCliniciansRequest(clinicians, studentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * Remove clinician from a specific student
     */
    async detachClinicians(payload: { clinicians: number[]; studentId: number }): Promise<AxiosResponse<unknown>> {
      const { studentId, clinicians } = payload;
      const response = await StudentService.detachCliniciansRequest(clinicians, studentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * getClinicianForStudent
     *
     *
     */
    async getCliniciansForStudent(studentId: number): Promise<StudentClinicianInterface[]> {
      this.loading = true;
      const response = await StudentService.getCliniciansForStudentRequest(studentId).catch(this.catchError);
      this.cliniciansForStudentSelected = response.data?.data as StudentClinicianInterface[];

      if (Array.isArray(this.cliniciansForStudentSelected) && !this.getCliniciansForStudent.length) {
        return [];
      }

      this.loaded();
      return this.cliniciansForStudentSelected;
    },

    /**
     * Retrieve clinician available to attach to a student
     */
    async getAddCliniciansModalData(studentId: number): Promise<{ name: string; value: number }[]> {
      const request = await StudentService.getAddCliniciansModalData(studentId).catch(this.catchError);
      const availableToAdd: StudentClinicianInterface[] = request.data?.data;

      return availableToAdd.map((clinician) => {
        return {
          value: clinician.id,
          name: clinician.display_name as string,
        };
      });
    },
  },
});
