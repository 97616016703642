import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';
import { ApiPromise, ArchiveInterface, PeopleFilterInterface, PersonCreateInterface } from '@/types';
import { HTTP } from '@/utils';

export default class PeopleService {
  static resource = 'people';
  static admin_resource = 'admins';
  static requestCancel = useRequestCancel();

  static createPerson(payload: PersonCreateInterface): ApiPromise {
    return HTTP.post(`${this.resource}`, payload);
  }

  static getPeople(payload: PeopleFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof PeopleFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.resource}${filter}`, { ...this.requestCancel.requestSaverSetCancellation(REQUEST.PEOPLE) });
  }

  static getAdminPeople(payload: PeopleFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof PeopleFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.resource}/${this.admin_resource}${filter}`);
  }

  static getTotals(): ApiPromise {
    return HTTP.get(`${this.resource}/totals`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.PEOPLE_TOTALS),
    });
  }

  static archive(id: number, payload: ArchiveInterface): ApiPromise {
    return HTTP.delete(`${this.resource}/${id}/archive`, { data: payload });
  }

  static unarchive(id: number): ApiPromise {
    return HTTP.post(`${this.resource}/${id}/unarchive`);
  }

  static activateAccess(id: number): ApiPromise {
    return HTTP.get(`${this.resource}/${id}/activate-access`);
  }

  static getArchiveInfo(userId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${userId}/archive-info`);
  }
}
