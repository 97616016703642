import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class StudentService {
  static resource = 'students';

  /**
   * GET REQUESTS
   */

  static getGuardiansForStudentRequest(studentId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/parents`);
  }

  static getAddGuardiansModalData(studentId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/parents/available-to-add`);
  }

  static getAddStudentsRequest(organizationId: number | undefined): ApiPromise {
    return HTTP.get(`organizations/${organizationId}/students/available-to-add`);
  }

  static getStudents(organizationId: number | undefined): ApiPromise {
    return HTTP.get(`organizations/${organizationId}/students`);
  }

  static getOrganizationsForStudentRequest(studentId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/organizations`);
  }

  static getStudentsForOrganizationParentsRequest(orgsId?: number[]): ApiPromise {
    const resquestParams = orgsId ? { params: { organizations: orgsId } } : {};

    return HTTP.get('organizations-student-parents', resquestParams);
  }

  static getAddOrganizationsModalData(studentId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/organizations/available-to-add`);
  }

  static getCliniciansForStudentRequest(studentId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/clinicians`);
  }

  static getAddCliniciansModalData(studentId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/clinicians/available-to-add`);
  }

  static getArchiveInfo(studentId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/archive-student`);
  }

  static getAddFacilitatorsModalData(studentId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${studentId}/facilitators/available-to-add`);
  }

  static getStudentImportTemplate(): ApiPromise {
    return HTTP.get(`${this.resource}/import-template`);
  }

  static getStudentIEPImportTemplate(): ApiPromise {
    return HTTP.get(`${this.resource}/import-iep-template`);
  }

  static getStudentReferralTemplate(): ApiPromise {
    return HTTP.get(`${this.resource}/referral-template`);
  }
  static getStudentIEPExport(): ApiPromise {
    return HTTP.get(`${this.resource}/export-iep-information`);
  }

  static getReferralStudents(): ApiPromise {
    return HTTP.get(`${this.resource}/referral-students`);
  }

  static getReferralOrganizations(): ApiPromise {
    return HTTP.get(`${this.resource}/referral-organizations`);
  }

  static getReferralTypes(): ApiPromise {
    return HTTP.get(`${this.resource}/referral-types`);
  }

  /**
   * POST REQUESTS
   */

  static attachGuardiansRequest(parents: number[], studentId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/attach-parents`, { parents });
  }

  static attachStudentsRequest(students: number[], organizationId: number | undefined): ApiPromise {
    return HTTP.post(`organizations/${organizationId}/attach-students`, { students });
  }

  static detachGuardiansRequest(parents: number[], studentId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/detach-parents`, { parents });
  }

  static detachStudentsRequest(students: number[], organizationId: number | undefined): ApiPromise {
    return HTTP.post(`organizations/${organizationId}/detach-students`, { students });
  }
  static attachOrganizationsRequest(organizations: number[], studentId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/attach-organizations`, { organizations });
  }

  static detachOrganizationsRequest(organizations: number[], studentId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/detach-organizations`, { organizations });
  }

  static attachCliniciansRequest(clinicians: number[], studentId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/attach-clinicians`, { clinicians });
  }

  static detachCliniciansRequest(clinicians: number[], studentId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/detach-clinicians`, { clinicians });
  }

  static importStudentRequest(organizationId: number, file: FormData): ApiPromise {
    return HTTP.post(`organizations/${organizationId}/import-students`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static importStudentIEPRequest(file: FormData): ApiPromise {
    return HTTP.post(`students/import-iep-information`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  static attachFacilitatorsToStudent(facilitators: number[], studentId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/attach-facilitators`, { facilitators });
  }

  static detachFacilitatorsToStudent(facilitators: number[], studentId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${studentId}/detach-facilitators`, { facilitators });
  }
}
