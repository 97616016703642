import { LAYOUTS, PERMISSIONS } from '@/enum';

const Billing = () => import(/* webpackChunkName: "billing" */ '@/views/Billing/Billing.vue');
const BillingReview = () =>
  import(/* webpackChunkName: "billing-review" */ '@/views/Billing/BillingReview/BillingReview.vue');
const BillingInvoices = () =>
  import(/* webpackChunkName: "billing-invoices" */ '@/views/Billing/BillingInvoices/BillingInvoices.vue');

export default [
  {
    path: '/billing',
    name: 'billing',
    component: Billing,
    children: [
      {
        path: '',
        name: 'billing.review',
        component: BillingReview,
      },
      {
        path: 'invoices',
        name: 'billing.invoices',
        component: BillingInvoices,
      },
    ],
    meta: {
      layout: LAYOUTS.DEFAULT,
      title: 'Billing - eLuma Insight',
      permission: [PERMISSIONS.BILLING_INDEX],
    },
  },
];
