import rrulePlugin from '@fullcalendar/rrule';

// This is an override for the rruleplugin to ensure daylight savings time is accounted for
rrulePlugin.recurringTypes[0].expand = function (errd, fr, de) {
  const hours = errd.rruleSet && errd.rruleSet._dtstart ? errd.rruleSet._dtstart.getHours() : 0;
  return errd.rruleSet.between(de.toDate(fr.start), de.toDate(fr.end), true).map((d: Date) => {
    return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), hours, d.getMinutes()));
  });
};

export const modifiedRRulePlugin = rrulePlugin;
