import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class StatesService {
  static resource = 'states';

  static getStates(): ApiPromise {
    return HTTP.get(this.resource);
  }
}
