import {
  ApiPromise,
  LocationFormInterface,
  OrganizationFilterInterface,
  OrganizationIntegrationCustomerInterface,
  OrganizationInterface,
  OrganizationPayloadInterface,
  PersonOrganizationReturnType,
  personOrganizationPayload,
  personOrganizationTypes,
} from '@/types';

import { ActivityLogFilterInterface } from '@/types/ActivityLog';
import { HTTP } from '@/utils';

type OrganizationService = {
  getPerson: <Type extends personOrganizationTypes>(
    organizationId: number | undefined,
    personType: Type,
    filter: string | undefined,
  ) => Promise<PersonOrganizationReturnType<Type>>;
  getTotalStudents: (organizationId: number | undefined) => ApiPromise;
  getPersonAvailableToAdd: <Type extends personOrganizationTypes>(
    organizationId: number | undefined,
    personType: Type,
    page?: number,
  ) => Promise<PersonOrganizationReturnType<Type>>;
  attachPerson: <Type extends personOrganizationTypes>(
    payload: personOrganizationPayload<Type>,
    organizationId: number | undefined,
    personType: Type,
  ) => ApiPromise;
  detachPerson: <Type extends personOrganizationTypes>(
    payload: personOrganizationPayload<Type>,
    organizationId: number | undefined,
    personType: Type,
  ) => ApiPromise;
  transferPerson: <Type extends personOrganizationTypes>(
    payload: personOrganizationPayload<Type> & { destination: number },
    organizationId: number | undefined,
    personType: 'students',
  ) => ApiPromise;
  createOrganization: (payload: OrganizationPayloadInterface) => ApiPromise;
  getOrganizationsTotals: () => ApiPromise;
  getOrganization: (id: number | undefined) => ApiPromise;
  getOrganizationByName: (name: string) => ApiPromise;
  getOrganizations: (payload?: OrganizationFilterInterface) => ApiPromise;
  getOrganizationParents: (service_start_at?: Date, service_end_at?: Date) => ApiPromise;
  getOrganizationContracts: (id: number) => ApiPromise;
  getOrganizationIntegrationCustomer: (id: number) => ApiPromise;
  saveOrganizationIntegrationCustomer: (payload: OrganizationIntegrationCustomerInterface) => ApiPromise;
  getIntegrationCustomers: () => ApiPromise;
  renewOrganizationContracts: (id: number) => ApiPromise;
  getOrganizationContract: (id: number) => ApiPromise;
  getAssociatedOrganization: (id: number, filter: string | undefined) => ApiPromise;
  updateOrganization: (payload: OrganizationInterface, id: number | undefined) => ApiPromise;
  updateOrganizationLocation: (payload: LocationFormInterface, id: number) => ApiPromise;
  getLocation: (id: number | undefined) => ApiPromise;
  getServices: (id: number | undefined) => ApiPromise;
  getOrganizationServices: (orgId: string) => ApiPromise;
  getDisciplines: (id: string) => ApiPromise;
  getOrganizationDisciplines: (orgId: string) => ApiPromise;
  archiveOrganization: (id: number) => ApiPromise;
  unArchiveOrganization: (id: number) => ApiPromise;
  getOrganizationLogs: (id: number, payload: ActivityLogFilterInterface) => ApiPromise;
};

const resource = 'organizations';
const resource_by_name = 'organization-by-name';
const resource_parents = 'organizations-parents';
const resource_organizations_totals = 'organizations-totals';
const resource_organizations_contracts = 'organizations-contracts';
const resource_organizations_contract = 'organizations-contract';
const resource_renew_organizations_contract = 'renew-organizations-contract';
const resource_quickbooks = 'quickbooks';

export const OrganizationService: OrganizationService = {
  getOrganization: (id) => {
    return HTTP.get(`${resource}/${id}`);
  },
  getOrganizationByName: (name) => {
    return HTTP.get(`${resource_by_name}/${name}`);
  },

  getOrganizations: (payload?: OrganizationFilterInterface) => {
    let filter = '';
    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof OrganizationFilterInterface]}`)
        .join('&')}`;
    }
    return HTTP.get(`${resource}${filter}`);
  },

  getOrganizationContracts: (id: number) => {
    return HTTP.get(`${resource_organizations_contracts}/${id}`);
  },

  getOrganizationIntegrationCustomer: (id: number) => {
    return HTTP.get(`${resource_quickbooks}/organization/${id}/customer`);
  },

  saveOrganizationIntegrationCustomer: (payload: OrganizationIntegrationCustomerInterface) => {
    return HTTP.post(`${resource_quickbooks}/organization/customer`, payload);
  },

  getIntegrationCustomers: () => {
    return HTTP.get(`${resource_quickbooks}/customers`);
  },

  getOrganizationContract: (id: number) => {
    return HTTP.get(`${resource_organizations_contract}/${id}`);
  },

  renewOrganizationContracts: (id: number) => {
    return HTTP.post(`${resource_renew_organizations_contract}/${id}`);
  },

  getOrganizationParents: (service_start_at?: Date, service_end_at?: Date) => {
    return HTTP.get(`${resource_parents}`, { params: { service_start_at, service_end_at } });
  },

  getOrganizationsTotals: () => {
    return HTTP.get(`${resource_organizations_totals}`);
  },

  getOrganizationLogs: (id, payload) => {
    let filter = '';
    if (payload) {
      filter = `?${Object.keys(payload)
        .filter((item) => payload[item as keyof ActivityLogFilterInterface] !== '')
        .map((item) => `${item}=${payload[item as keyof ActivityLogFilterInterface]}`)
        .join('&')}`;
    }
    return HTTP.get(`${resource}/${id}/logs${filter}`);
  },

  createOrganization(payload) {
    return HTTP.post(`${resource}`, payload);
  },

  updateOrganization: (payload, id) => {
    return HTTP.put(`${resource}/${id}`, payload);
  },

  updateOrganizationLocation: (payload, id) => {
    return HTTP.put(`${resource}/${id}/location`, payload);
  },

  getLocation: (id) => {
    return HTTP.get(`${resource}/${id}/locations`);
  },

  getServices: (id) => {
    return HTTP.get(`${resource}/${id}/services`);
  },

  getOrganizationServices: (orgId?: string) => {
    return HTTP.get(`organization-services${orgId ? `?organizations=${orgId}` : ''}`);
  },

  getDisciplines: (id) => {
    return HTTP.get(`${resource}/${id}/disciplines`);
  },

  getPerson: (organizationId, personType, filter) => {
    return HTTP.get(`${resource}/${organizationId}/${personType}${filter}`);
  },

  getTotalStudents: (organizationId) => {
    return HTTP.get(`${resource}/${organizationId}/total-students`);
  },

  getOrganizationDisciplines: (orgId?: string) => {
    return HTTP.get(`organization-disciplines${orgId ? `?organizations=${orgId}` : ''}`);
  },

  getAssociatedOrganization: (organizationId, filter) => {
    return HTTP.get(`${resource}/${organizationId}/parents${filter}`);
  },

  getPersonAvailableToAdd: (organizationId, personType, page = 0) => {
    return HTTP.get(`${resource}/${organizationId}/${personType}/available-to-add?page=${page}`);
  },

  attachPerson: (payload, organizationId, personType) => {
    return HTTP.post(`${resource}/${organizationId}/attach-${personType}`, payload);
  },

  detachPerson: (payload, organizationId, personType) => {
    return HTTP.post(`${resource}/${organizationId}/detach-${personType}`, payload);
  },

  archiveOrganization: (organizacionId: number) => {
    return HTTP.delete(`${resource}/${organizacionId}/archive`);
  },

  unArchiveOrganization: (organizacionId: number) => {
    return HTTP.post(`${resource}/${organizacionId}/unarchive`);
  },

  transferPerson: (payload, organizationId, personType) => {
    return HTTP.post(`${resource}/${organizationId}/transfer-${personType}`, payload);
  },
};
