import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { rrulestr } from 'rrule';
import { useCalendarStore } from '@/store';
import { can, useDateTime, useDropdown } from '@/composables';
import { EVENT_TYPE, PERMISSIONS } from '@/enum';
import { CalendarService, TherapyService } from '@/services';
import {
  CalendarBulkAttendanceInterface,
  CalendarBulkAttendanceStoreInterface,
  DropdownOptionInterface,
  EventInputResponseInterface,
  TherapyResultTypeInterface,
} from '@/types';
import { ViewApi } from '@fullcalendar/core';

interface CalendarEventArg {
  date: string;
  dow: number;
  isDisabled: boolean;
  isFuture: boolean;
  isOther: boolean;
  isPast: boolean;
  isToday: boolean;
  text: string;
  view: ViewApi;
}

export default defineStore('CalendarBulkAttendanceStore', {
  state: (): CalendarBulkAttendanceStoreInterface => {
    return {
      displayBulkAttendance: false,
      error: '',
      date: '',
      events: [],
      loading: false,
      therapyTypes: {
        options: [] as DropdownOptionInterface[],
        loading: false,
      },
    };
  },

  getters: {
    bulkAttendanceTitle: (state) => `Edit Attendance for ${useDateTime().formatDateShortMonth(state.date)}`,
  },

  actions: {
    catchError(e: AxiosResponse) {
      this.error = e.data.message;
      this.loading = false;
      throw e;
    },

    /**
     * Defines the bulk attendance icon and event within the calendar header
     * for the specific day. This can easily be utilized by adding the following
     * to the Fullcalendar instance.
     */
    defineBulkAttendance(arg: CalendarEventArg, canBulkUpdate: boolean) {
      const dateElement = document.createElement('span');
      dateElement.innerHTML = arg.text;

      if (!canBulkUpdate) {
        return { domNodes: [dateElement] };
      }

      const iconElement = document.createElement('span');
      const tooltipElement = document.createElement('div');
      iconElement.className = 'material-icons text-gray-600 cursor-pointer';
      iconElement.setAttribute('style', 'float:right;');
      iconElement.innerHTML = 'more_horiz';
      iconElement.addEventListener('mouseover', () => (tooltipElement.style.display = 'inline'));
      iconElement.addEventListener('mouseout', () => (tooltipElement.style.display = 'none'));
      iconElement.addEventListener('click', (event: Event) => {
        event.stopPropagation();
        this.onBulkAttendanceDisplay(arg);
      });
      tooltipElement.setAttribute(
        'style',
        'display:none;top:25px;right:-25px;float:right;width:95px;pointer-events:none;',
      );
      tooltipElement.className = 'absolute z-5 border-round-sm p-1 surface-900 text-center text-white text-xs fadein';
      tooltipElement.textContent = 'More Actions';

      return { domNodes: [dateElement, iconElement, tooltipElement] };
    },

    /**
     * The display bulk attendance modal event.
     */
    async onBulkAttendanceDisplay(arg: CalendarEventArg) {
      this.loading = true;

      if (
        !can([
          PERMISSIONS.THERAPY_RESULTS_BULK_UPDATE,
          PERMISSIONS.THERAPY_RESULTS_DAY_UPDATE,
          PERMISSIONS.THERAPY_RESULTS_STORE,
          PERMISSIONS.THERAPY_EVENT_INDEX,
        ])
      ) {
        this.loading = false;
        this.error = 'Insufficient permissions to edit attendance';
        return;
      }

      this.events = [];

      if (arg) {
        this.date = arg.date;
        const startDate = new Date(this.date);

        const start = useDateTime().formatDateForApi(
          new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
        );
        const endDate = new Date(this.date);
        const end = useDateTime().formatDateForApi(
          new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
        );
        const filtersEvents = {
          start_at: start,
          end_at: end,
        };
        const response = await CalendarService.getEvents(filtersEvents).catch(this.catchError);

        const events = await response.data.data.filter((item: EventInputResponseInterface) => {
          return item.therapy_event_type !== EVENT_TYPE.CASELOAD_MANAGEMENT;
        });

        await this.defineEvents(events, arg);
        await this.onBulkAttendanceOpen();
      }
    },
    /**
     * Open the bulk attendance modal.
     */
    async onBulkAttendanceOpen() {
      this.loading = false;
      this.displayBulkAttendance = !this.displayBulkAttendance;
    },

    /**
     * Close the bulk attendance modal and cleear out the events collection.
     */
    onBulkAttendanceCancel() {
      this.events = [];
      this.displayBulkAttendance = !this.displayBulkAttendance;
    },
    /**
     * check the rrule for empty string, and if its empty AND start end dates dont match
     * then the event date is not valid.
     * @param rrule
     * @param eventStart
     * @param dateStart
     * @returns
     */
    isValidDate(rrule: string, eventStart: string, dateStart: string) {
      const start = useDateTime().fromUTCStringToLocalDate(eventStart);
      const cannotAddEvent = rrule === '' && useDateTime().formatDateForApi(start) !== dateStart;
      return !cannotAddEvent;
    },
    /**
     * Build out a FullCalendar event object.
     */
    async defineEvents(events: EventInputResponseInterface[], arg: CalendarEventArg) {
      const dateStart = useDateTime().formatDateForApi(arg.date);

      const eventData: EventInputResponseInterface[] = [];

      // No idea what the type is suppose to be here.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      events.forEach((event: EventInputResponseInterface) => {
        if (!event.completed && this.isValidDate(event.rrule, event.start_at, dateStart)) {
          const date = event.rrule
            ? rrulestr(event.rrule)
                .all()
                .filter((item) => useDateTime().formatDateForApi(item) === dateStart)
            : event.start_at;
          if (date.length) {
            const start = useDateTime().fromUTCStringToLocalDate(event.start_at);
            const startTime = useDateTime().formatTimeByTimezone(start, event.host_timezone_name);
            const endTime = useDateTime().formatTimeByTimezone(
              new Date(start.setMinutes(start.getMinutes() + event.duration)),
              event.host_timezone_name,
            );
            //mutating the type here from Attendee model to string and TS doesnt like it
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            event.attendees = event.attendees.map((attendee: any) => {
              return attendee.attendee.display_name;
            });
            event.start_time = event.start_at.split(' ')[1];
            event.timeframe = `${startTime} - ${endTime}`;
            eventData.push(event);
          }
        }
      });

      // Assign and sort the events by time.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.events = eventData.sort((a: any, b: any) => {
        const dateA = new Date('2000-01-01 ' + a.timeframe.split(' - ')[0]);
        const dateB = new Date('2000-01-01 ' + b.timeframe.split(' - ')[0]);

        return dateA.valueOf() - dateB.valueOf();
      });
    },

    /**
     * Get the therapy result types that should only be available for selection.
     */
    async getTherapyResultTypes() {
      this.therapyTypes.loading = true;

      if (!can([PERMISSIONS.THERAPY_RESULT_TYPES_INDEX])) {
        this.therapyTypes.loading = false;
        this.error = 'Insufficient permissions to view therapy result types.';
        console.error(this.error);
        return;
      }

      const only = ['Service Provider Cancelled', 'Customer Cancelled', 'No School'];
      const response = await TherapyService.getTherapyResultTypes().catch(this.catchError);
      const options = response.data.data.filter(({ name }: TherapyResultTypeInterface) => {
        return only.find((value) => name === value);
      });
      this.therapyTypes.options = useDropdown(options);
      this.therapyTypes.loading = false;
    },

    /**
     * Execute the bullk attendance request with the required payload.
     */
    async bulkAttendance(payload: CalendarBulkAttendanceInterface) {
      if (
        !can([
          PERMISSIONS.THERAPY_RESULTS_BULK_UPDATE,
          PERMISSIONS.THERAPY_RESULTS_DAY_UPDATE,
          PERMISSIONS.THERAPY_RESULTS_STORE,
        ])
      ) {
        this.error = 'Insufficient permissions to edit attendance';
        return;
      }

      const response = await CalendarService.bulkAttendance(payload).catch(this.catchError);

      const filtesEvents = {
        start_at: useCalendarStore().startDate,
        end_at: useCalendarStore().endDate,
      };

      await useCalendarStore().getEvents(filtesEvents);

      return response.data.data;
    },
  },
});
