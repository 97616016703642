import { defineStore } from 'pinia';
import { useArrayUtilities } from '@/composables';
import { ClinicianService, DisciplineService } from '@/services';
import { CheckboxItemInterface, DisciplineInterface, DisciplineStateInterface } from '@/types';

const arrayUtilities = useArrayUtilities();

export default defineStore('ClinicianDisciplineStore', {
  state: (): DisciplineStateInterface => ({
    disciplineIDs: [],
    disciplines: [],
    error: false,
    loading: false,
    primaryId: undefined,
    clinicianDisciplines: [],
  }),

  actions: {
    catchError(e: Error) {
      this.error = true;
      this.message = e.message;
    },
    updateDisciplineForClinician(payload: { id: string; checked: boolean }) {
      if (this.disciplineIDs) {
        if (!payload.checked) {
          this.disciplineIDs = arrayUtilities.removeFromArray(this.disciplineIDs, payload.id);
        } else {
          this.disciplineIDs.push(payload.id);
        }
      }
      const temp = this.disciplineIDs?.find((element) => element == this.primaryId?.toString());
      if (!temp || (this.disciplineIDs && this.disciplineIDs.length < 2)) this.primaryId = undefined;
    },
    async getClinicianDisciplines(clinicianId: number) {
      this.loading = true;

      const response = await ClinicianService.getDisciplinesAtthachedForClinician(clinicianId).catch(this.catchError);
      if (response) {
        this.clinicianDisciplines = response.data.data;
        this.error = false;
        this.loading = false;
      }
    },
    async getDisciplines(clinicianId: number) {
      try {
        this.loading = false;
        const allDisciplinesRequest = DisciplineService.getDisciplines();
        const clinicianDisciplinesRequest = ClinicianService.getDisciplinesAtthachedForClinician(clinicianId);
        const ids: string[] = [];

        const values = await Promise.all([allDisciplinesRequest, clinicianDisciplinesRequest]);
        const allDisciplines = values[0]?.data?.data;
        const clinicianDisciplines = values[1]?.data?.data;
        this.primaryId = clinicianDisciplines?.find((discipline: DisciplineInterface) => discipline.is_primary)?.id;

        this.disciplines = allDisciplines.map((discipline: CheckboxItemInterface) => {
          discipline.checked = false;

          for (let i = 0; i < clinicianDisciplines.length; i++) {
            if (clinicianDisciplines[i].id === discipline.id) {
              ids.push(clinicianDisciplines[i].id);
              discipline.checked = true;
              return discipline;
            }
          }
          return discipline;
        });

        this.disciplineIDs = ids;
        this.error = false;
        this.loading = false;
      } catch (e) {
        this.error = true;
        this.message = 'there was an error';
      }
    },

    getDisciplinesForDropdown() {
      return this.disciplines
        ?.filter((item: DisciplineInterface) => item.checked)
        .map((discipline: DisciplineInterface) => {
          return {
            id: discipline.id,
            name: discipline.name,
          };
        });
    },

    async saveDisciplines(clinicianId: number) {
      const payload = { disciplines: this.disciplineIDs, primary: this.primaryId };
      const found = this.disciplineIDs?.find((element) => element == payload.primary?.toString());

      if (!this.primaryId || !this.disciplineIDs?.length || !found) delete payload.primary;

      this.loading = false;
      this.error = false;
      return ClinicianService.syncDisciplinesRequest(
        clinicianId,
        payload.disciplines as string[],
        payload.primary as number,
      )
        .then((result) => {
          if (result.status === 200) {
            this.loading = false;
          }
          return result.status;
        })
        .catch((e) => {
          this.error = true;
          this.message = 'there was an error';
          console.error(e);
        });
    },
  },
});
