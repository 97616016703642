import 'vue-router';

import Admin from '@/views/Admin/routes';
import Billing from '@/views/Billing/routes';
import Errors from '@/views/Errors/routes';
import Legal from '@/views/Legal/routes';
import Reports from '@/views/Reports/routes';

export default [...Admin, ...Billing, ...Errors, ...Legal, ...Reports];

declare module 'vue-router' {
  interface RouteMeta {
    layout: string | null;
    permission?: string[];
  }
}
