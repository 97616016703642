import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class FacilitatorService {
  static resource = 'facilitators';

  static getAddOrganizationsModalData(facilitatorId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${facilitatorId}/organizations/available-to-add`);
  }

  static attachOrganizationsToFacilitator(organizations: number[], facilitatorId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${facilitatorId}/attach-organizations`, { organizations });
  }

  static detachOrganizationsFromFacilitator(organizations: number[], facilitatorId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${facilitatorId}/detach-organizations`, { organizations });
  }
}
