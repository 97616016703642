import { AttendeeDropdownOptionInterface, DetailedEventInterface } from '@/types';
import { EVENT_TYPE, PERMISSIONS } from '@/enum';

import { AxiosResponse } from 'axios';
import { can, useDateTime } from '@/composables';
import { defineStore } from 'pinia';
import { useCalendarStore } from '..';
import { EventImpl } from '@fullcalendar/core/internal';

interface OpenEventArg {
  id: number | string;
  startStr: string;
  start: string;
  end: string;
}

export default defineStore('EventsModalsStore', {
  state: () => {
    return {
      loading: false,
      error: '',
      eventToDocumentOrEdit: {} as DetailedEventInterface | null,
      selectedEventCompletedToEdit: false,
      displayEventModal: false,
      displayDocumentEventModal: false,
      eventToEditSelected: false,
      clickedEventClickArgs: undefined as undefined | OpenEventArg | EventImpl,
      preselectedEventType: undefined as undefined | EVENT_TYPE,
      preSelectedDueAt: undefined as undefined | Date,
      preSelectedEventStartAt: undefined as undefined | Date,
      preSelectedAttendees: [] as AttendeeDropdownOptionInterface[],
      selectedAttendees: [] as AttendeeDropdownOptionInterface[],
      isAttendeesSelectedFromDashboard: false,
      filteredMessage: '',
      start: '',
      end: '',
    };
  },
  actions: {
    catchError(e: AxiosResponse) {
      this.error = e.data.message;
      this.loading = false;
      throw e;
    },

    isCaseloadManagement(eventType: string) {
      return eventType === EVENT_TYPE.CASELOAD_MANAGEMENT;
    },

    createNewEvent(eventType?: EVENT_TYPE) {
      this.clickedEventClickArgs = undefined;
      this.eventToDocumentOrEdit = null;
      this.eventToEditSelected = false;
      this.preselectedEventType = eventType;
      this.openEventModal();
    },

    toggleEventModal() {
      this.displayEventModal = !this.displayEventModal;
    },

    openEventModal() {
      this.displayEventModal = true;
    },

    openDocumentEventModal() {
      this.displayDocumentEventModal = true;
    },

    setAttendees(attendees: AttendeeDropdownOptionInterface[], isAttendeesSelectedFromDashboard?: boolean) {
      if (!attendees.length) return this;

      if (isAttendeesSelectedFromDashboard) {
        this.loading = true;
        this.isAttendeesSelectedFromDashboard = true;
      }

      this.preSelectedAttendees = attendees;

      return this;
    },

    getPreSelectedAttendees() {
      return this.preSelectedAttendees;
    },

    async onDocumentEvent(eventInfo: OpenEventArg) {
      if (!can([PERMISSIONS.THERAPY_RESULTS_STORE, PERMISSIONS.THERAPY_EVENT_SHOW])) {
        this.error = 'Insufficient permissions to document event';
        console.error(this.error);
        return;
      }

      this.clickedEventClickArgs = eventInfo;

      const calendarStore = useCalendarStore();
      await calendarStore.getEvent(eventInfo.id.toString());
      this.eventToDocumentOrEdit = calendarStore.selectedEvent;
      if (this.eventToDocumentOrEdit) {
        this.eventToDocumentOrEdit.event_date = eventInfo.startStr.split('T')[0];
        this.eventToDocumentOrEdit.document_date = this.eventToDocumentOrEdit.event_date;

        this.eventToDocumentOrEdit.display_start_at = eventInfo.start;
        this.eventToDocumentOrEdit.display_end_at = eventInfo.end;
      }

      this.selectedEventCompletedToEdit = calendarStore.selectedEvent.completed;
      this.openDocumentEventModal();
    },

    async onEditEvent(eventInfo: EventImpl) {
      if (!can([PERMISSIONS.THERAPY_EVENT_UPDATE, PERMISSIONS.THERAPY_EVENT_SHOW])) {
        this.error = 'Insufficient permissions to edit event';
        console.error(this.error);
        return;
      }
      this.clickedEventClickArgs = eventInfo;

      const calendarStore = useCalendarStore();
      this.eventToDocumentOrEdit = await calendarStore.getEvent(eventInfo.id.toString());

      this.preselectedEventType = this.eventToDocumentOrEdit?.therapy_event_type;
      this.eventToEditSelected = true;
      calendarStore.selectedEvent.start_at = eventInfo.start?.toString() as string;
      calendarStore.selectedEvent.end_at =
        eventInfo?._def?.recurringDef?.typeData.rruleSet._rrule[0].options.until ||
        (eventInfo.end?.toString() as string);
      this.toggleEventModal();
    },
    formatDisplayStartEndAtForTimezone(date: string) {
      return new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(useDateTime().fromUTCStringToLocalDate(date as string));
    },
    async openEventModalById(eventId: number) {
      if (!can([PERMISSIONS.THERAPY_EVENT_SHOW])) {
        this.error = 'Insufficient permissions to document event';
        console.error(this.error);
        return;
      }

      const calendarStore = useCalendarStore();
      await calendarStore.getEvent(eventId.toString());

      calendarStore.selectedEvent.display_start_at = this.formatDisplayStartEndAtForTimezone(
        calendarStore.selectedEvent.display_start_at as string,
      );

      calendarStore.selectedEvent.display_end_at = this.formatDisplayStartEndAtForTimezone(
        calendarStore.selectedEvent.display_end_at as string,
      );

      this.eventToDocumentOrEdit = calendarStore.selectedEvent;

      this.clickedEventClickArgs = {
        id: calendarStore.selectedEvent.id,
        start: calendarStore.selectedEvent.start_at,
        startStr: calendarStore.selectedEvent.start_at.replace(' ', 'T'),
        end: calendarStore.selectedEvent.end_at,
      };

      if (
        !can([PERMISSIONS.THERAPY_RESULTS_STORE]) ||
        calendarStore.selectedEvent.is_past ||
        calendarStore.selectedEvent.completed
      ) {
        if (this.eventToDocumentOrEdit) {
          this.eventToDocumentOrEdit.event_date = this.eventToDocumentOrEdit.start_at.split(' ')[0];
          this.eventToDocumentOrEdit.document_date = this.eventToDocumentOrEdit.event_date;
          this.openDocumentEventModal();
          return;
        }
      }

      this.eventToEditSelected = true;
      this.preselectedEventType = this.eventToDocumentOrEdit?.therapy_event_type;
      this.toggleEventModal();
    },
  },
  getters: {
    isSingleEvent: (state) => state.eventToDocumentOrEdit && !state.eventToDocumentOrEdit.rrule,
  },
});
