import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class BillingAdminService {
  static resource = 'billing-admin';

  static getAddOrganizationsModalData(billingAdminId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${billingAdminId}/organizations/available-to-add`);
  }

  static attachOrganizationsToBillingAdmin(organizations: number[], billingAdminId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${billingAdminId}/attach-organizations`, { organizations });
  }

  static detachOrganizationsFromBillingAdmin(organizations: number[], billingAdminId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${billingAdminId}/detach-organizations`, { organizations });
  }
}
