import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useAuthStore, useOrganizationStore } from '@/store';
import { can } from '@/composables';
import { PERMISSIONS } from '@/enum';
import { ClinicianService, OrganizationService } from '@/services';
import {
  AttendeeDropdownOptionInterface,
  ClinicianInterface,
  ClinicianStateInterface,
  ClinicianTableInterface,
  ExtendedDisciplineInterface,
  StudentInterface,
  StudentsOfCliniciansParamsInterface,
} from '@/types';

export default defineStore('ClinicianStore', {
  state: (): ClinicianStateInterface => ({
    clinicians: [],
    students: [],
    error: '',
    loading: false,
    showArchived: false,
  }),

  getters: {
    orgId: () => (useOrganizationStore().organization.id as number) || useAuthStore().organizationId,
  },

  actions: {
    catchError(e: string) {
      this.loading = false;
      this.error = e;
      throw e;
    },

    /**
     * attachClinicians
     *
     * Assign clinicians to a specific organization
     * @param payload {clinicians: number[] }
     */
    async attachClinicians(payload: { clinicians: number[] }): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      const response = await OrganizationService.attachPerson(payload, organizationId, 'clinicians').catch(
        this.catchError,
      );
      this.error = null;

      return response;
    },

    /**
     * detachClinicians
     *
     * Remove clinicians from a specific organization
     * @param payload {clinicians: number[] }
     */
    async detachClinicians(payload: { clinicians: number[] }): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      const response = await OrganizationService.detachPerson(payload, organizationId, 'clinicians').catch(
        this.catchError,
      );
      this.error = null;

      return response;
    },

    /**
     * getClinicians
     *
     * Retrieve all clinicians assigned to an organzation
     *
     * @return ClinicianInterface[]
     */
    async getClinicians(filter: string): Promise<ClinicianInterface[]> {
      this.loading = true;
      const request = await OrganizationService.getPerson(this.orgId, 'clinicians', filter).catch(this.catchError);
      this.clinicians = request.data?.data;
      this.error = null;
      this.loading = false;

      return this.clinicians as ClinicianInterface[];
    },

    /**
     * getCliniciansForTable
     *
     * Retrieve and format clinicians data for the organization page clinicians table
     * @return ClincianTableInterface[]
     */
    async getCliniciansForTable(filter: string): Promise<ClinicianTableInterface[]> {
      const rawCliniciansData = await this.getClinicians(filter);
      const tableData = rawCliniciansData.map((clinician) => {
        const disciplines = clinician.clinician_disciplines?.map((discipline) => {
          return discipline.name;
        });
        const organizations = clinician.organizations;
        return {
          id: clinician.id,
          name: clinician.display_name as string,
          disciplines,
          organizations,
          archived: !!clinician.archived_at,
        };
      });
      return this.showArchived ? tableData : tableData.filter((item) => !item.archived);
    },

    async getAddCliniciansModalData(): Promise<{ name: string; value: number }[]> {
      const organizationId = this.orgId;
      const request = await OrganizationService.getPersonAvailableToAdd(organizationId, 'clinicians').catch(
        this.catchError,
      );
      const availableToAdd: ClinicianInterface[] = request.data?.data.filter((item) => !item.archived_at);
      return availableToAdd.map((clinician) => {
        return {
          value: clinician.id,
          name: clinician.display_name as string,
        };
      });
    },
    async searchStudentsOfClinician(clinicianId: number, params?: StudentsOfCliniciansParamsInterface) {
      if (!can([PERMISSIONS.CLINICIAN_STUDENTS_INDEX])) {
        this.error = 'Insufficient permissions to view students';
        console.error(this.error);
        return Promise.reject();
      }

      const request = await ClinicianService.getStudentsOfClinicianRequest(clinicianId, params).catch(this.catchError);
      const students: StudentInterface[] = request.data?.data;
      return students;
    },
    async getStudentsByClinician(
      clinicianId: number,
      params?: StudentsOfCliniciansParamsInterface,
    ): Promise<AttendeeDropdownOptionInterface[]> {
      if (!can([PERMISSIONS.CLINICIAN_STUDENTS_INDEX])) {
        this.error = 'Insufficient permissions to view students';
        console.error(this.error);
        return Promise.reject();
      }
      const request = await ClinicianService.getStudentsOfClinicianRequest(clinicianId, params).catch(this.catchError);
      const students: StudentInterface[] = request.data?.data;

      this.students = students;
      return students.map((student) => {
        return {
          value: student.id,
          name: student.display_name as string,
          organizations: student.organizations,
          disciplines: student.treatment_services.map((item) => {
            const extendedDiscipline: ExtendedDisciplineInterface = { ...item.discipline };
            extendedDiscipline.start_at = item.start_at;
            extendedDiscipline.end_at = item.end_at;
            extendedDiscipline.is_group = item.is_group;
            extendedDiscipline.group_size = item.group_size;
            extendedDiscipline.is_active = item.is_active;
            extendedDiscipline.service = item.service;
            extendedDiscipline.treatment_service_type = item.treatment_service_type ?? null;

            return extendedDiscipline;
          }),
        };
      });
    },
  },
});
