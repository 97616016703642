import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { PersonStores } from '@/store';
import { HTTP, PersonService, TreatmentPlanService, TreatmentPlansService } from '@/services';
import {
  ServiceFilterInterface,
  TreatmentGoalFormInterface,
  TreatmentPlanGeneralFormInterface,
  TreatmentPlanGoalInterface,
  TreatmentPlanServiceCreationInterface,
  UserTreatmentPlansStateInterface,
} from '@/types';
import { FREQUENCY_INTERVALS, ALL_FREQUENCY_TYPES } from '@/enum';

export default defineStore('UserTreatmentPlansStore', {
  state: (): UserTreatmentPlansStateInterface => ({
    treatmentPlanCategories: undefined,
    treatmentPlanNotes: [],
    treatmentPlanTypes: undefined,
    userTreatmentPlans: undefined,
    clonedUserTreatmentPlans: undefined,
    treatmentPlanServices: [],
    treatmentPlanServiceTypes: [],
    treatmentPlanFrequencyTypes: [],
    treatmentPlanGoalsTypes: [],
    treatmentPlanGoalsForm: [],
    error: undefined,
    loading: false,
    showExpired: false,
  }),

  getters: {
    planId: (state: UserTreatmentPlansStateInterface) => state.userTreatmentPlans?.id,
  },

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },
    parseTreatmentPlanService(pay: TreatmentPlanServiceCreationInterface) {
      const payload = { ...pay };
      const custom = this.treatmentPlanFrequencyTypes.find((type) => type.acronym === 'CUSTOM');

      switch (payload?.treatment_frequency_type?.acronym) {
        case ALL_FREQUENCY_TYPES.WEEK2:
          payload.interval = FREQUENCY_INTERVALS.WEEKLY;
          payload.frequency = 2;
          break;
        case ALL_FREQUENCY_TYPES.MONTH2:
          payload.interval = FREQUENCY_INTERVALS.MONTHLY;
          payload.frequency = 2;
          break;
        case ALL_FREQUENCY_TYPES.WEEK3:
          payload.interval = FREQUENCY_INTERVALS.WEEKLY;
          payload.frequency = 3;
          break;
        case ALL_FREQUENCY_TYPES.WEEKLY.toUpperCase():
          payload.interval = FREQUENCY_INTERVALS.WEEKLY;
          payload.frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.MONTHLY.toUpperCase():
          payload.interval = FREQUENCY_INTERVALS.MONTHLY;
          payload.frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.SEMESTERLY.toUpperCase():
          payload.interval = FREQUENCY_INTERVALS.SEMESTERLY;
          payload.frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.QUARTERLY.toUpperCase():
          payload.interval = FREQUENCY_INTERVALS.QUARTERLY;
          payload.frequency = 1;
          break;
        case ALL_FREQUENCY_TYPES.YEARLY.toUpperCase():
          payload.interval = FREQUENCY_INTERVALS.YEARLY;
          payload.frequency = 1;
          break;
        default:
          break;
      }

      payload.treatment_frequency_type = custom;
      return payload;
    },

    async addNewTreatmentPlanServiceForPerson(payload: TreatmentPlanServiceCreationInterface) {
      const usersStore = PersonStores.useUserProfileStore();

      if (usersStore.id) {
        const userId = usersStore.id;
        const parsedPayload: TreatmentPlanServiceCreationInterface = this.parseTreatmentPlanService(payload);

        const response = await TreatmentPlansService.addServiceRequest(
          { ...parsedPayload },
          this.planId as string,
          userId,
        ).catch((response) => {
          this.error = 'error';
          return response;
        });
        this.loaded();

        return response;
      }
    },

    async addNewTreatmentPlan() {
      const usersStore = PersonStores.useUserProfileStore();

      if (usersStore.id) {
        const userId = usersStore.id;
        const payload: TreatmentPlanGeneralFormInterface = {
          treatment_plan_type_id: this.userTreatmentPlans?.treatment_plan_category.treatment_plan_type_id as number,
          treatment_plan_category_id: this.userTreatmentPlans?.treatment_plan_category.id as number,
          start_at: this.userTreatmentPlans?.start_at as string,
        };
        const response = await TreatmentPlanService.addTreatmentPlanForPerson(payload, userId).catch(this.catchError);
        this.userTreatmentPlans = response.data.data;
        this.loaded();

        return response.data;
      }
    },

    async archiveTreatmentPlanServiceForPerson(id: number) {
      await TreatmentPlansService.deleteServiceRequest(id, this.planId as string).catch(this.catchError);
    },

    async expireTreatmentPlanService(id: number) {
      await TreatmentPlansService.expireServiceRequest(id, this.planId as string).catch(this.catchError);
    },

    async getTreatmentPlanExpireInformation(treatmentServiceId: number | undefined) {
      const response = await TreatmentPlansService.getTreatmentPlanExpireInformation(
        this.planId,
        treatmentServiceId,
      ).catch(this.catchError);
      return response.data;
    },

    async updateTreatmentPlanServiceForPerson(payload: TreatmentPlanServiceCreationInterface, id: number | undefined) {
      const usersStore = PersonStores.useUserProfileStore();

      if (usersStore.id) {
        const userId = usersStore.id;
        const parsedPayload: TreatmentPlanServiceCreationInterface = this.parseTreatmentPlanService(payload);
        const response = await TreatmentPlansService.updateServiceRequest(
          parsedPayload,
          this.planId as string,
          userId,
          id,
        ).catch((response) => {
          this.error = 'error';
          return response;
        });
        this.loaded();
        await this.getTreatmentPlanNotes();
        return response;
      }
    },

    async addTreatmentPlanNote(payload: string) {
      await TreatmentPlansService.addTreatmentPlanNoteRequest(payload, this.planId).catch(this.catchError);
      this.loaded();
    },
    getTreatmentPlanTypeById(id: number) {
      return this.treatmentPlanTypes?.find((type) => type.id === id);
    },
    async getTreatmentPlanFrequencyTypes() {
      const response = await TreatmentPlansService.getTreatmentFrequencyTypesRequest().catch(this.catchError);
      this.treatmentPlanFrequencyTypes = response.data.data;
      this.loaded();
      return this.treatmentPlanFrequencyTypes;
    },

    async getTreatmentServiceTypes() {
      const response = await TreatmentPlansService.getTreatmentServiceTypesRequest().catch(this.catchError);
      this.treatmentPlanServiceTypes = response.data.data;
      this.loaded();
      return this.treatmentPlanServiceTypes;
    },

    async getUserTreatmentPlanServices(filters?: ServiceFilterInterface) {
      const response = await TreatmentPlansService.getTreatmentPlanServicesRequest(
        this.$state.userTreatmentPlans?.id as string,
        filters,
      ).catch(this.catchError);

      this.treatmentPlanServices = response.data.data;
      this.loaded();
    },

    async getUserTreatmentPlans(id: number) {
      this.loading = true;

      await PersonService.getUserTreatmentPlans(id)
        .then((response) => {
          this.userTreatmentPlans = response.data.data;
          this.clonedUserTreatmentPlans = this.userTreatmentPlans;
          this.loaded();
        })
        .catch(() => {
          this.loading = false;
          this.error = 'error';
        });
    },

    async fetchAllTreatmentPlanFormData() {
      if (this.userTreatmentPlans) {
        this.loading = true;
        const getters = [
          `treatment-plan-categories?treatment_plan_type_id=${this.userTreatmentPlans.treatment_plan_type.id}`,
          'treatment-plan-types',
          `treatment-plans/${this.planId}/services`,
        ];
        const values = await TreatmentPlanService.fetchAllTreatmentPlanFormData(getters).catch(this.catchError);
        this.treatmentPlanCategories = values[0]?.data?.data;
        this.treatmentPlanTypes = values[1]?.data?.data;
        this.treatmentPlanServices = values[2]?.data?.data;

        this.loaded();
      } else {
        this.loading = true;
        const treatmentPlansTypes = await TreatmentPlansService.getTreatmentPlanTypesRequest().catch(this.catchError);
        this.treatmentPlanTypes = treatmentPlansTypes.data.data;
        this.loaded();
      }
    },

    async getTreatmentPlanCategories(id?: number) {
      this.loading = true;

      if (this.userTreatmentPlans) {
        const response = await PersonService.getTreatmentPlanCategories(
          id ?? this.userTreatmentPlans.treatment_plan_type.id,
        ).catch(this.catchError);
        this.treatmentPlanCategories = response.data.data;
      } else {
        const response = await PersonService.getTreatmentPlanCategories(id as number).catch(this.catchError);
        this.treatmentPlanCategories = response.data.data;
      }
      this.loaded();
    },

    /**
     * get User treatment plan notes
     */
    async getTreatmentPlanNotes() {
      if (this.userTreatmentPlans) {
        const response = await TreatmentPlansService.getTreatmentPlanNotesRequest(
          this.$state.userTreatmentPlans?.id,
        ).catch(this.catchError);
        this.treatmentPlanNotes = response.data.data;
        this.loaded();
      }

      return this.treatmentPlanNotes;
    },

    /**
     * get treatment plan types
     */
    async getTreatmentPlanTypes() {
      this.loading = true;
      const response = await TreatmentPlansService.getTreatmentPlanTypesRequest().catch(this.catchError);
      this.treatmentPlanTypes = response.data.data;
      this.loaded();
    },

    /**
     * update General treatment plan
     */
    async updateGeneralTreatmentPlan() {
      const usersStore = PersonStores.useUserProfileStore();

      if (usersStore.id && this.userTreatmentPlans) {
        const postData: TreatmentPlanGeneralFormInterface = {
          treatment_plan_type_id: this.userTreatmentPlans.treatment_plan_type.id,
          treatment_plan_category_id: this.userTreatmentPlans.treatment_plan_category.id,
          start_at: this.userTreatmentPlans.start_at,
        };

        return await HTTP.patch(`person/${usersStore.id}/treatment-plans`, postData)
          .then((result) => {
            if (result.status === 200) {
              this.loading = false;
            }
            return result.status;
          })
          .catch(this.catchError);
      }
    },

    async updateTreatmentPlan() {
      const usersStore = PersonStores.useUserProfileStore();

      if (usersStore.id && this.userTreatmentPlans) {
        return await TreatmentPlanService.updateTreatmentPlan(
          `person/${usersStore.id}/treatment-plans`,
          this.userTreatmentPlans,
        )
          .then((result) => {
            if (result.status === 200) {
              this.loading = false;
            }
            return result.status;
          })
          .catch(this.catchError);
      }
    },

    async getTreatmentPlanGoalsTypes() {
      const response = await TreatmentPlansService.getTreatmentServiceGoalsTypesRequest().catch(this.catchError);
      this.treatmentPlanGoalsTypes = response.data.data;
      return this.treatmentPlanGoalsTypes;
    },

    async getTreatmentPlanGoals(id: number) {
      const response = await TreatmentPlansService.getTreatmentGoalsRequest(id).catch(this.catchError);
      this.treatmentPlanGoalsForm = response.data.data;
      return this.treatmentPlanGoalsForm;
    },

    async addGoal(payload: { treatment_goals: TreatmentGoalFormInterface[] }, id: number) {
      const response = await TreatmentPlansService.addTreatmentPlanGoalsRequest(payload, id).catch(this.catchError);
      this.addGoalToTreatmentPlan(response.data.data[0], id);
    },

    addGoalToTreatmentPlan(goal: TreatmentPlanGoalInterface, id: number) {
      const service = this.treatmentPlanServices.find((service) => service.id === id);
      service?.goals?.push(goal);
    },

    async editGoal(payload: { treatment_goals: TreatmentGoalFormInterface[] }, id: number) {
      const response = await TreatmentPlansService.updateTreatmentPlanGoalsRequest(payload, id).catch(this.catchError);
      return response.data.data;
    },

    async deleteGoal(payload: { treatment_goals: number[] }): Promise<AxiosResponse<unknown>> {
      const { treatment_goals } = payload;
      const response = await TreatmentPlansService.deleteTreatmentPlanGoalsRequest(treatment_goals).catch(
        this.catchError,
      );
      return response.data.data;
    },

    updateGoalData(goal: TreatmentPlanGoalInterface, oldGoalId: number) {
      const service = this.treatmentPlanServices.find((service) => service.id === goal.treatment_service_id);
      const oldGoal = service?.goals?.find((goal) => goal.id === oldGoalId);

      if (oldGoal) {
        oldGoal.id = goal.id;
        oldGoal.treatment_goal_type_id = goal.treatment_goal_type_id;
        oldGoal.treatment_service_id = goal.treatment_service_id;
        oldGoal.description = goal.description;
        oldGoal.deleted_at = goal.deleted_at;
        oldGoal.completed_at = goal.completed_at;
      }
    },
  },
});
