import { ActivityLogFilterInterface } from '@/types/ActivityLog';
import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class ActivityLogService {
  static queryFilter(payload: ActivityLogFilterInterface) {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .filter((item) => payload[item as keyof ActivityLogFilterInterface] !== '')
        .map((item) => `${item}=${payload[item as keyof ActivityLogFilterInterface]}`)
        .join('&')}`;
    }

    return filter;
  }

  static getLogs(person: number, payload: ActivityLogFilterInterface): ApiPromise {
    return HTTP.get(`person/${person}/logs${this.queryFilter(payload)}`);
  }
}
