import { ApiPromise, NotificationPayloadInterface } from '@/types';

import { HTTP } from '@/utils';

export default class NotificationService {
  static resource = 'notifications';

  static getNotifications(): ApiPromise {
    return HTTP.get(`${this.resource}`);
  }

  static syncNotifications(payload: NotificationPayloadInterface): ApiPromise {
    return HTTP.post(`${this.resource}`, payload);
  }
}
