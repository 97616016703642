import { defineStore } from 'pinia';
import { LocationTypesService } from '@/services';
import { LocationTypeInterface } from '@/types';

export default defineStore('LocationTypesStore', {
  state: () => {
    return {
      loading: false,
      types: [] as LocationTypeInterface[],
    };
  },

  actions: {
    async getLocationTypes() {
      this.loading = true;
      const response = await LocationTypesService.getLocationTypes();
      this.types = response.data.data;
      this.loading = false;
    },
  },
});
