import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class PersonService {
  static resource = 'person';

  static getConnections(userId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${userId}/connections`);
  }

  static getUserTreatmentPlans(userId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${userId}/treatment-plans`);
  }

  static getTreatmentPlanCategories(typeId: number): ApiPromise {
    return HTTP.get(`treatment-plan-categories?treatment_plan_type_id=${typeId}`);
  }

  static getAllTreatmentPlanCategories(): ApiPromise {
    return HTTP.get(`treatment-plan-categories`);
  }
}
