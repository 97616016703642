import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import ReportService from './ReportService';
import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';

export default class StudentTotalReportService extends ReportService {
  static resource = 'student-total';
  static requestCancel = useRequestCancel();

  static getStudentTotalReport(payload: { date: Date }): ApiPromise {
    return HTTP.get(`${ReportService.resource}/${this.resource}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_STUDENT_TOTAL),
    });
  }
}
