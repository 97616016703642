import { defineStore } from 'pinia';
import { PeopleTypesService } from '@/services';
import { PeopleTypeInterface } from '@/types';

interface PeopleTypeTitleInterface {
  key: string;
  value: string;
}

interface PeopleTypeAcronymInterface {
  SCHOOL_ADMIN: string;
  SUPPORT_ADMIN: string;
  BILLING_ADMIN: string;
  CLINICIAN: string;
  STUDENT: string;
  PARENT: string;
  FACILITATOR: string;
  SUPER_ADMIN: string;
}

const PEOPLE_TYPE_TITLE: PeopleTypeTitleInterface[] = [
  { key: 'SCHOOL_ADMIN', value: 'school admin' },
  { key: 'SUPPORT_ADMIN', value: 'support admin' },
  { key: 'BILLING_ADMIN', value: 'billing admin' },
  { key: 'CLINICIAN', value: 'service provider' },
  { key: 'STUDENT', value: 'student' },
  { key: 'PARENT', value: 'parent' },
  { key: 'FACILITATOR', value: 'facilitator' },
  { key: 'SUPER_ADMIN', value: 'super admin' },
];

export default defineStore('PeopleTypeStore', {
  state: () => {
    return {
      loading: false,
      types: [] as PeopleTypeInterface[],
      acronym: {} as PeopleTypeAcronymInterface,
    };
  },

  actions: {
    async getPeopleTypes() {
      this.loading = true;
      const response = await PeopleTypesService.getPeopleTypes();
      this.types = response.data.data;
      this.createAcronymObject();
      this.loading = false;
    },
    createAcronymObject() {
      this.types.forEach((type) => {
        const title: string = type.title.trim().toLowerCase();
        const peopleType = PEOPLE_TYPE_TITLE.find((pt) => pt.value == title);
        this.acronym[peopleType?.key as keyof PeopleTypeAcronymInterface] = type.acronym;
      });
    },
  },
  getters: {
    PEOPLE_TYPES: (state) => state.acronym,
  },
});
