import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class CountriesService {
  static resource = 'countries';

  static getCountries(): ApiPromise {
    return HTTP.get(this.resource);
  }
}
