import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';
import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class OrganizationTypeService {
  static resource = 'organization-types';
  static requestCancel = useRequestCancel();

  static getOrganizationTypes(): ApiPromise {
    return HTTP.get(this.resource, { ...this.requestCancel.requestSaverSetCancellation(REQUEST.ORGANIZATION_TYPES) });
  }
}
