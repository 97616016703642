import { defineStore } from 'pinia';
import { can, useDownload, useDateTime } from '@/composables';
import { EVENT_TYPE, PERMISSIONS, SESSION_TYPE } from '@/enum';
import { TherapyService } from '@/services';
import { useCalendarStore } from '@/store';
import {
  AssessmentData,
  AssessmentMaterialData,
  AssessmentRecordFormData,
  CaseloadManagementTypeInterface,
  EventCreationFormInterface,
  EventCreationStateInterface,
  EventDeleteRequestInterface,
  GenericReturnTypeData,
} from '@/types';
import { ParsedOptions } from 'rrule/dist/esm/types';

export default defineStore('TherapyStore', {
  state: (): EventCreationStateInterface => ({
    error: '',
    loading: false,
    caseloadManagementTypes: undefined,
    sessionEventTypes: undefined,
    evaluationTypes: undefined,
    evaluationAssessmentsTypes: undefined,
    allowedDateRange: undefined,
  }),

  getters: {},
  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    async getSessionEventTypes(): Promise<GenericReturnTypeData<SESSION_TYPE>[]> {
      this.loading = true;
      this.error = undefined;

      if (!can([PERMISSIONS.THERAPY_SESSION_TYPES_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view session event types';
        console.error(this.error);
        return [];
      }

      if (this.sessionEventTypes) {
        this.loaded();
        return this.sessionEventTypes;
      }

      const response = await TherapyService.getTherapySessionTypes().catch(this.catchError);
      this.loaded();
      return response.data.data;
    },

    async getEvaluationEventTypes(): Promise<GenericReturnTypeData<SESSION_TYPE>[]> {
      this.loading = true;
      this.error = undefined;

      if (!can([PERMISSIONS.THERAPY_EVALUATION_TYPES_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view evaluation event types';
        console.error(this.error);
        return [];
      }

      if (this.evaluationTypes) {
        this.loaded();
        return this.evaluationTypes;
      }

      const response = await TherapyService.getTherapyEvaluationTypes().catch(this.catchError);
      this.loaded();
      this.evaluationTypes = response.data.data;
      return response.data.data;
    },

    async getEvaluationAssessments(): Promise<AssessmentData[]> {
      this.loading = true;
      this.error = undefined;

      if (!can([PERMISSIONS.THERAPY_EVENT_STORE_EVALUATION_SELECT_ASSESSMENT])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view assessments';
        console.error(this.error);
        return [];
      }

      if (this.evaluationAssessmentsTypes) {
        this.loaded();
        return this.evaluationAssessmentsTypes;
      }

      const response = await TherapyService.getEvaluationAssessmentsTypes().catch(this.catchError);
      this.loaded();
      this.evaluationAssessmentsTypes = response.data.data;
      return response.data.data;
    },

    async downloadEvaluationAssessmentsRecordForm(
      recordForm: AssessmentRecordFormData,
    ): Promise<AssessmentRecordFormData> {
      if (!can([PERMISSIONS.THERAPY_EVENT_STORE_EVALUATION_SELECT_ASSESSMENT])) {
        //TODO add new permissions for downloading record forms when they are available
        this.loading = false;
        this.error = 'Insufficient permissions to download evaluation records';
        console.error(this.error);
        return Promise.reject();
      }

      const response = await TherapyService.downloadEvaluationAssessmentsRecordForm(recordForm.id).catch(
        this.catchError,
      );
      const data = response.data;
      const fileBasename = recordForm.file_path.split('/').reverse()[0];
      const fileName = fileBasename.replace(/(\.\w+)+$/, '');

      useDownload(data, fileName, 'pdf');

      return data;
    },

    async downloadEvaluationAssessmentsMaterial(material: AssessmentMaterialData): Promise<AssessmentMaterialData> {
      if (!can([PERMISSIONS.THERAPY_EVENT_STORE_EVALUATION_SELECT_ASSESSMENT])) {
        this.loading = false;
        this.error = 'Insufficient permissions to download assessment materials';
        console.error(this.error);
        return Promise.reject();
      }

      const response = await TherapyService.downloadEvaluationAssessmentsMaterial(material.id).catch(this.catchError);
      const data = response.data;
      const blob = new Blob([data as keyof { pdf: string }], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);

      window.open(`/assessment-pdf-viewer?url=${url}`, '_blank');

      return data;
    },
    async createEvent(payload: EventCreationFormInterface, rRuleRefId: number) {
      this.loading = true;
      this.error = undefined;

      if (!rRuleRefId) payload.rruleNull = true;

      if (!can([PERMISSIONS.THERAPY_EVENT_STORE])) {
        this.loading = false;
        this.error = 'Insufficient permissions to create calendar events';
        console.error(this.error);
        return;
      }

      const response = await TherapyService.createOrUpdateTherapyEvent(payload).catch(this.catchError);

      this.loaded();
      return response;
    },
    async getTherapyEventAllowedDateRange(org_id: number, eventDate: string) {
      this.loading = true;
      this.error = undefined;
      const response = await TherapyService.getTherapyEventAllowedDateRange(org_id as number, eventDate).catch(
        this.catchError,
      );
      this.allowedDateRange = response.data.data;

      this.loaded();
      return response;
    },
    async deleteEvent(payload: EventDeleteRequestInterface) {
      this.loading = true;
      this.error = undefined;

      if (!can([PERMISSIONS.THERAPY_EVENT_DESTROY])) {
        this.loading = false;
        this.error = 'Insufficient permissions to delete calendar events';
        return;
      }

      const response = await TherapyService.deleteEventRequest(payload).catch(this.catchError);

      this.loaded();
      return response;
    },

    async unlockEvent(id: number) {
      this.loading = true;
      this.error = undefined;

      if (!can([PERMISSIONS.THERAPY_EVENT_UNLOCK])) {
        this.error = 'Insufficient permissions to unlock locked calendar events';
        return;
      }

      const response = await TherapyService.unlockTherapyEvent(id).catch(this.catchError);

      this.loaded();
      this.doToast('Event unlocked, SP may now edit the event', 'Success');
      return response;
    },
    shouldDeleteCurrentEvent(options: ParsedOptions, eventType: string, therapyEventType: string) {
      const calendarStore = useCalendarStore();
      const dateTime = useDateTime();
      const rruleStart = dateTime.formatUTCString(options.dtstart as Date);
      const displayStart = dateTime.formatUTCString(new Date(calendarStore.selectedEvent.start_at as string));

      return rruleStart !== displayStart && eventType === 'this' && therapyEventType !== EVENT_TYPE.CASELOAD_MANAGEMENT;
    },
    async updateEvent(
      payload: EventCreationFormInterface,
      eventId: number,
      eventTypeCondition: string,
      isRecurringEventEdit: boolean,
    ) {
      this.loading = true;
      this.error = undefined;

      const deleteCurrentEvent = isRecurringEventEdit
        ? this.shouldDeleteCurrentEvent(
            payload?.rrule.options as ParsedOptions,
            eventTypeCondition,
            payload.therapy_event_type,
          )
        : false;

      if (!can([PERMISSIONS.THERAPY_EVENT_UPDATE])) {
        this.loading = false;
        this.error = 'Insufficient permissions to update calendar events';
        return;
      }
      if (deleteCurrentEvent && payload?.rrule.options)
        payload.rrule.options.until = new Date(useCalendarStore().selectedEvent.end_at);

      const response = await TherapyService.createOrUpdateTherapyEvent(payload, eventId, eventTypeCondition).catch(
        this.catchError,
      );

      if (deleteCurrentEvent) {
        const deletePayload: EventDeleteRequestInterface = {
          id: eventId,
          date: useCalendarStore().selectedEvent.start_at as string,
          mode: eventTypeCondition as 'this' | 'future' | 'all',
        };

        await this.deleteEvent(deletePayload).catch(this.catchError);
      }
      this.loaded();
      return response;
    },

    //TODO rename this to handle goals
    // async saveTreamentGoalMeasurement(treatmentGoals: GoalInterface) {
    //   this.loading = true;
    //   await TherapyService.saveTherapyResults(treatmentGoals).catch(this.catchError);
    //   this.loading = false;
    // },
    //TODO: plug this in once BE has it figured out
    async getTreatmentGoals(therapy_treatment_id: number) {
      this.loading = true;

      if (!can([PERMISSIONS.TREATMENT_SERVICES_GOALS_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view treatment goals';
        console.error(this.error);
        return;
      }

      await TherapyService.getTreatmentGoals(therapy_treatment_id).catch(this.catchError);
    },

    async getCaseloadManagementTypes(): Promise<CaseloadManagementTypeInterface[]> {
      this.loading = true;

      if (!can([PERMISSIONS.THERAPY_CASELOAD_MANAGEMENT_TYPES_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view caseload management types';
        console.error(this.error);
        return [];
      }

      if (this.caseloadManagementTypes) {
        this.loaded();
        return this.caseloadManagementTypes;
      }
      const response = await TherapyService.getCaseloadManagementTypesRequest().catch(this.catchError);
      this.loaded();
      return response.data.data;
    },
  },
});
