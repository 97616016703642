import { AES, enc } from 'crypto-ts';
import { VueCookieNext } from 'vue-cookie-next';
import { PeopleTypeInterface, PermissionInterface } from '@/types';
import { computed } from 'vue';
import { useAuthStore } from '@/store';
import { usePersonTypes } from './usePersonTypes';

const tenant = computed(() => {
  const tenantFromCookie = VueCookieNext.getCookie('tenant');
  const tokenGuestTenant = VueCookieNext.getCookie('access_token_tenant');
  let guestTenant = undefined;

  if (tokenGuestTenant) {
    guestTenant = tokenGuestTenant.guest_tenant;
  }

  return guestTenant ?? useAuthStore().token?.tenant ?? tenantFromCookie;
});

let permissionsGlobal: string[] = [];

export const setPermissions = (permissions: PermissionInterface[]) => {
  const permissionsNames = permissions.map((permission) => permission.name);
  permissionsGlobal = [...permissionsNames];
};

let token = undefined;

if (VueCookieNext.getCookie('accessToken')) {
  token = VueCookieNext.getCookie('accessToken').access_token;
}

if (localStorage.getItem('permissions') && token) {
  try {
    const permissionsLocal = JSON.parse(
      AES.decrypt(localStorage.getItem('permissions') || '', token || 'Eluma').toString(enc.Utf8),
    );
    permissionsGlobal = [...permissionsLocal.permissionsArray];
  } catch (error) {
    localStorage.removeItem('permissions');
  }
} else {
  localStorage.removeItem('permissions');
}

export const can = (values: string[]) => {
  for (const permission of values) {
    if (permission && permissionsGlobal.includes(permission.trim())) {
      return true;
    }
  }

  return false;
};

export const isValidForRoleByTenant = (peopleType?: PeopleTypeInterface) => {
  if (tenant.value) {
    const isTypeUser = usePersonTypes.isSchoolAdmin();
    const isMasterTenant = !!tenant.value.master;
    let isTypePerson = false;
    let isValid = !(isTypeUser && isMasterTenant);

    if (peopleType && isMasterTenant && isTypeUser) {
      isTypePerson = usePersonTypes.isClinician(peopleType?.acronym);
      isValid = !isValid && !isTypePerson;
    }

    return isValid;
  }
  return false;
};
