const PrivacyPolicy = () => import(/* webpackChunkName: "legal-privacy-policy" */ './PrivacyPolicy');
const TermsOfService = () => import(/* webpackChunkName: "legal-terms-of-service" */ './TermsOfService');

export default [
  {
    name: 'legal.privacy-policy',
    path: '/legal/privacy-policy',
    component: PrivacyPolicy,
    meta: { layout: 'Public' },
  },
  {
    name: 'legal.terms-of-service',
    path: '/legal/terms-of-service',
    component: TermsOfService,
    meta: { layout: 'Public' },
  },
];
