import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class SchoolAdminService {
  static resource = 'school-admin';

  /**
   * GET REQUESTS
   */

  static getOrganizationsForSchoolAdmin(schoolAdminId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${schoolAdminId}/organizations`);
  }

  static getAddOrganizationsModalData(schoolAdminId: string | number): ApiPromise {
    return HTTP.get(`${this.resource}/${schoolAdminId}/organizations/available-to-add`);
  }

  /**
   * POST REQUESTS
   */

  static attachOrganizationsToSchoolAdmin(organizations: number[], schoolAdminId: string | number): ApiPromise {
    return HTTP.post(`${this.resource}/${schoolAdminId}/attach-organizations`, { organizations });
  }

  static detachOrganizationsFromSchoolAdmin(organization: number, schoolAdminId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${schoolAdminId}/detach-organizations`, { organization });
  }
}
