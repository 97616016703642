import { TreeNode } from 'primevue/tree';

/**
 * Flatten the provided deeply nested array of organizations based on
 * a key naming convention of children.
 */
export function useFlattenOrganizations(array: TreeNode[]): TreeNode[] {
  let children: TreeNode[] = [];

  const orgs = array
    .map((mem) => {
      const m = { ...mem };
      // eslint-disable-next-line
      // @ts-ignore
      if (m.children && m.children.length) {
        m.children = m.children.map((child) => {
          return {
            ...child,
            parent_id: child.parent_id || m.id || m.key,
          };
        });
        children = [...children, ...m.children];
      }

      delete m.children;

      return {
        id: m.id || m.key,
        name: m.name || m.data.name,
        parent_id: m.parent_id,
        children: [],
      };
    })
    // eslint-disable-next-line
    // @ts-ignore
    .concat(children.length ? useFlattenOrganizations(children) : children);

  return orgs.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
}

/**
 * Traverse an organization tree and in order to group them together accordingly.
 */
// eslint-disable-next-line
// @ts-ignore
export function useTraverseOrganizationTree(node, path = [], result = []) {
  // eslint-disable-next-line
  // @ts-ignore
  if (!node.children.length) {
    // eslint-disable-next-line
    // @ts-ignore
    result.push(path.concat(node));
  }
  for (const child of node.children) {
    useTraverseOrganizationTree(child, path.concat(node), result);
  }

  return result;
}

export interface OrganizationConnections {
  id: number;
  name: string;
  parent_id: number | null;
  ancestors: OrganizationConnections[];
  children: OrganizationConnections[];
}

export interface OrganizationAncestorsConnections {
  id: number;
  name: string;
  parent_id: number | null;
  children?: OrganizationAncestorsConnections[];
}

/**
 * Build an array of all grouped organizations in order to parse them easily.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useGroupOrganizations(data: any): OrganizationAncestorsConnections[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const organizations: any[] = [];

  if (data) {
    data.forEach((item: OrganizationConnections) => {
      if (item.ancestors) {
        const ancestors = item.ancestors.map((ancestor: OrganizationConnections) => {
          return {
            id: ancestor.id,
            name: ancestor.name,
            parent_id: ancestor.parent_id,
            children: useFlattenOrganizations(item.children),
          };
        });

        ancestors.push({
          id: item.id,
          name: item.name,
          parent_id: item.parent_id,
          children: useFlattenOrganizations(item.children),
        });

        organizations.push(ancestors);
      }
    });
  }

  return organizations;
}
