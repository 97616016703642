import { PeopleService } from '@/services';
import { ArchiveInterface } from '@/types';
import { defineStore } from 'pinia';

interface State {
  isShow: boolean;
  person: {
    id: number;
    display_name: string;
    archived_at: string;
    people_type: {
      acronym: string;
    };
  };
}

export default defineStore('ArchiveStore', {
  state: (): State => {
    return {
      isShow: false,
      person: {
        id: 0,
        display_name: '',
        archived_at: '',
        people_type: {
          acronym: '',
        },
      },
    };
  },
  actions: {
    async unarchive(id: number) {
      return await PeopleService.unarchive(id).catch((e) => {
        throw e;
      });
    },
    async archive(id: number, payload: ArchiveInterface) {
      return await PeopleService.archive(id, payload).catch((e) => {
        throw e;
      });
    },
  },
});
