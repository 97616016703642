import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useAuthStore, useOrganizationStore } from '@/store';
import { OrganizationService } from '@/services';
import { BillingAdmin, BillingAdminModal, BillingAdminPayload, BillingAdminState, BillingAdminTable } from '@/types';

export default defineStore('BillingAdminStore', {
  state: (): BillingAdminState => ({
    billingAdmins: [],
    error: '',
    loading: false,
    showArchived: false,
  }),

  getters: {
    orgId: () => (useOrganizationStore().organization.id as number) || useAuthStore().organizationId,
  },

  actions: {
    catchError(e: string) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    /**
     *
     * attachBillingAdmins
     *
     * Assign school-admins to a specific organization
     * @param BillingAdmins: number[]
     */
    async attachBillingAdmins(billingAdmins: number[]): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      this.loading = true;
      const payload = { billing_admins: billingAdmins } as BillingAdminPayload;
      return await OrganizationService.attachPerson(payload, organizationId, 'billing-admins')
        .then((result) => {
          this.loading = false;
          return result;
        })
        .catch(this.catchError);
    },

    /**
     *
     * detachBillingAdmins
     *
     * Remove school-admins from a specific organization
     * @param billing_admins: number[]
     */
    async detachBillingAdmins(billingAdmins: number[]): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      const payload = { billing_admins: billingAdmins } as BillingAdminPayload;
      return await OrganizationService.detachPerson(payload, organizationId, 'billing-admins')
        .then((result) => {
          this.loading = false;
          return result;
        })
        .catch(this.catchError);
    },

    /**
     *
     * getBillingAdmins
     *
     * Retrieve all school-admins assigned to an organization
     *
     * @param commit
     */
    async getBillingAdmins(filter: string): Promise<BillingAdmin[]> {
      const organizationId = this.orgId;
      this.loading = true;
      return await OrganizationService.getPerson(organizationId, 'billing-admins', filter)
        .then((result) => {
          this.loading = false;
          this.billingAdmins = result.data?.data;
          return this.billingAdmins;
        })
        .catch(this.catchError);
    },

    /**
     * getBillingAdminsForTable
     *
     *
     * Retrieve and format school-admins data for the organization page student table
     * @return StudentTableInterface[]
     */
    async getBillingAdminsForTable(filter: string): Promise<BillingAdminTable[]> {
      await this.getBillingAdmins(filter);
      const tableData = this.billingAdmins.map((billingAdmin) => {
        return {
          id: billingAdmin.id,
          name: billingAdmin.display_name as string,
          archived: !!billingAdmin.archived_at,
        };
      });
      return this.showArchived ? tableData : tableData.filter((item) => !item.archived);
    },

    /**
     *
     * getBillingAdmins
     *
     * Retrieve all school-admins assigned to an organization
     *
     * @param commit
     */
    async getBillingAdminsAvailableToAdd(): Promise<BillingAdminModal[]> {
      const organizationId = this.orgId;
      this.loading = true;
      return await OrganizationService.getPersonAvailableToAdd(organizationId, 'billing-admins')
        .then((result) => {
          this.loading = false;
          return result.data?.data
            .filter((item) => !item.archived_at)
            .map((billingAdmin) => {
              return {
                value: billingAdmin.id,
                name: billingAdmin.display_name as string,
              };
            });
        })
        .catch(this.catchError);
    },
  },
});
