import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export const useRouting = () => {
  /**
   * Define a route instance.
   */
  const route = useRoute();

  /**
   * Define a router instance.
   */
  const router = useRouter();

  /**
   * Helper method to fire a callback function whenever the route object is changed.
   */
  const routeChangeWatcher = (cb: () => void) => watch(route, cb);

  /**
   * Add a single query param to the router instance and go to the revised route.
   */
  const addQueryParam = (key: string, value: string) => {
    const query = { [key]: value };

    if (route !== null && router !== null) {
      router.push({ query: { ...route.query, ...query } });
    }
  };

  /**
   * Add a multiple query param to the router instance and go to the revised route.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const addQueryParams = (params: any) => {
    const query = params;

    if (route !== null && router !== null) {
      router.push({ query: { ...route.query, ...query } });
    }
  };

  /**
   * Redirect a user to the provided url.
   */
  const redirectTo = (value: string) => router.push(value);

  /**
   * Remove all query params from the router instance and go to the revised route.
   */
  const removeAllQueryParam = () => router.replace({ query: Object.assign({}, {}) });

  /**
   * Remove a query param from the router instance and go to the revised route.
   */
  const removeQueryParam = (key: string) => {
    const query = Object.assign({}, route.query);
    delete query[key];
    router.replace({ query });
  };

  /**
   * Reset all query params from the router instance and go to the revised route.
   */
  const resetQueryParams = (params: { key: string; value: string }) => {
    router.replace({ query: Object.assign({}, params) });
  };

  return {
    route,
    router,
    redirectTo,
    addQueryParam,
    addQueryParams,
    removeAllQueryParam,
    removeQueryParam,
    resetQueryParams,
    routeChangeWatcher,
  };
};
