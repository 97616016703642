import { GenericDropdownInterface, DisciplineInterface, PersonInterface } from '@/types';

/**
 * Generate a collection of drop down options.
 */
export function useDropdown(options: GenericDropdownInterface[]) {
  return options.map(({ name, id }: GenericDropdownInterface) => {
    return { name: name, value: id };
  });
}

/**
 * Generate a collection of discipline drop down options.
 */
export function useDisciplineDropdown(options: DisciplineInterface[]) {
  return useDropdown(options);
}

/**
 * Generate a collection of people drop down options.
 */
export function usePersonDropdown(options: PersonInterface[]) {
  return options.map(({ display_name, id, archived_at }: PersonInterface) => {
    const name = `${display_name} ${archived_at ? '(Archived)' : ''}`;
    return { name, value: id };
  });
}
