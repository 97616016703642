/**
 * Deeply clones any type of value in order to block reactivity.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDeepClone(data: any): JSON {
  return JSON.parse(JSON.stringify(data));
}

/**
 * Check if two objects are equal.
 * @param obj1
 * @param obj2
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useIsEqual(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    const areObjects = typeof val1 === 'object' && typeof val2 === 'object';
    if ((areObjects && !useIsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}
