import { defineStore } from 'pinia';
import { useArrayUtilities } from '@/composables';
import { HTTP } from '@/services';
import { CheckboxItemInterface, DisciplineInterface, DisciplineStateInterface } from '@/types';

const arrayUtilities = useArrayUtilities();
export default defineStore('DisciplineStore', {
  state: (): DisciplineStateInterface => ({ disciplines: [], disciplineIDs: [], error: '', loading: false }),

  actions: {
    /* A method that is called when an error is thrown. */
    catchError(e: string) {
      this.loading = false;
      this.error = e;
      throw e;
    },
    /**
     * update the discipline
     */
    updateDiscipline(payload: { id: string; checked: boolean }) {
      if (this.disciplineIDs) {
        if (!payload.checked) {
          const newArray = arrayUtilities.removeFromArray(this.disciplineIDs, payload.id);
          this.disciplineIDs = newArray;
        } else {
          this.disciplineIDs.push(payload.id);
        }
      }
    },

    /**
     * getDisciplines
     *
     * call API for list of ALL disciplines and org disciplines
     * and merge the two
     *
     */
    async getDisciplines(organizationId?: number) {
      this.loading = true;
      const response = await HTTP.get('disciplines').catch(this.catchError);
      const allDisciplines = response.data.data;
      let orgDisciplines: CheckboxItemInterface[];
      if (organizationId) {
        const orgResponse = await HTTP.get(`organizations/${organizationId}/disciplines`).catch(this.catchError);
        orgDisciplines = orgResponse.data.data;
      }
      const ids: string[] = [];
      /**
       * loop through all the disciplines and assign a checked boolean
       * for the UI
       */
      this.disciplines = !organizationId
        ? allDisciplines
        : allDisciplines?.map((discipline: CheckboxItemInterface) => {
            discipline.checked = false;

            //loop org selected IDS to compare and set boolean checked
            //and to push just the ORG DISCIPLINE ID string for filtering
            for (let i = 0; i < orgDisciplines.length; i++) {
              if (orgDisciplines[i].id === discipline.id) {
                ids.push(orgDisciplines[i].id);
                discipline.checked = true;
                return discipline;
              }
            }
            return discipline;
          });

      this.disciplineIDs = organizationId ? ids : allDisciplines.map((d: CheckboxItemInterface) => d.id);
      this.error = undefined;
      this.loading = false;
      return this.disciplines;
    },

    async getDisciplinesForDropdown(organizationId: number) {
      if (!this.disciplines?.length) {
        await this.getDisciplines(organizationId);
      }

      return this.disciplines
        ?.filter((item: DisciplineInterface) => item.checked)
        .map((discipline: DisciplineInterface) => {
          return {
            id: discipline.id,
            name: discipline.name,
          };
        });
    },

    async getDisciplinesOfOrganizationForDropdown(organizationId: number) {
      try {
        this.loading = true;
        const orgDisciplinesUrl = HTTP.get(`organizations/${organizationId}/disciplines`);

        const values = await orgDisciplinesUrl;
        const orgDisciplines = values?.data?.data;

        /**
         * loop through all the disciplines and assign a checked boolean
         * for the UI
         */
        const dataReturn = (this.disciplines = orgDisciplines.map((discipline: DisciplineInterface) => {
          return {
            id: discipline.id,
            name: discipline.name,
          };
        }));

        this.error = undefined;
        this.loading = false;
        return dataReturn;
      } catch (e) {
        this.error = 'error';
      }
    },

    async saveDisciplines(organizationId: number) {
      this.loading = true;
      return HTTP.post(`organizations/${organizationId}/sync-disciplines`, {
        disciplines: this.disciplineIDs,
      })
        .then((result) => {
          if (result.status === 200) {
            this.loading = false;
          }
          return result.status;
        })
        .catch((e) => {
          this.error = e;
        });
    },
  },
});
