import { ApiPromise, ProgressReportDownloadInterface, ProgressReportFilterInterface } from '@/types';
import { HTTP } from '@/utils';
import ReportService from './ReportService';

export default class ProgressReportService extends ReportService {
  static resource = 'progress';

  static getProgressReport(payload: ProgressReportFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof ProgressReportFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${ReportService.resource}/${this.resource}${filter}`);
  }

  static downloadProgressReport(payload: ProgressReportDownloadInterface): ApiPromise {
    return HTTP.post(`${ReportService.resource}/${this.resource}/download`, payload, { responseType: 'blob' });
  }
}
