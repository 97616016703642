import { ComputedRef, isRef, Ref, ref, unref, watchEffect } from 'vue';
import { useClinicianStore } from '@/store';
import { EVENT_TYPE } from '@/enum';
import {
  AttendeeDropdownOptionInterface,
  ClinicianOrganizationInterface,
  DropdownOptionInterface,
  StudentsOfCliniciansParamsInterface,
} from '@/types';

// Stores all possible attendee and discipline dropdown options
const allAttendees = ref<AttendeeDropdownOptionInterface[]>([]);
const allDisciplines = ref<DropdownOptionInterface[]>([]);
const isGroupEligibleAttendees = ref<AttendeeDropdownOptionInterface[]>([]);
const treatmentServiceMap: Record<number, string[]> = {};

/**
 * useGetInitialDropdownOptions
 *
 * Fetches all possible attendee and discipline options for the given clinician. Is used in the
 * useGetDropdownOptions hook but can also be called directly without reactive updates.
 *
 * @param clinicianId {number} Id of clinician
 */
export async function useGetInitialDropdownOptions(
  clinicianId: number,
  eventDate: Date,
): Promise<{
  availableAttendees: Ref<AttendeeDropdownOptionInterface[]>;
  availableDisciplines: Ref<DropdownOptionInterface[]>;
}> {
  const clinicianStore = useClinicianStore();
  const requestParams: StudentsOfCliniciansParamsInterface = { clinician_disciplines: true };
  const response = await clinicianStore.getStudentsByClinician(clinicianId, requestParams);
  const availableAttendees = ref<AttendeeDropdownOptionInterface[]>(response);
  const availableDisciplines = ref<DropdownOptionInterface[]>([]);
  allAttendees.value = availableAttendees.value;

  // Loops through each attendee and pushes each unique discipline to the available disciplines
  // at the same time, we push any attendees who have a group eligible discipline to their own array
  availableAttendees.value.forEach((attendee) => {
    attendee.disciplines?.forEach((discipline) => {
      const serviceEndDate = new Date(discipline.end_at);
      if (!discipline.is_active && serviceEndDate < eventDate) return;
      if (discipline.is_group && discipline.group_size !== 1) {
        // if the attendee has a discipline that is group eligible, push that attendee to an array
        // if they are not already in that array
        const alreadyInArray = isGroupEligibleAttendees.value.findIndex((eligibleAttendee) => {
          return attendee.value === eligibleAttendee.value;
        });
        if (alreadyInArray === -1) {
          isGroupEligibleAttendees.value.push(attendee);
        }
      }
      // if no values in the availableDisciplines array equal the current loop discipline value
      // push it to the array
      if (availableDisciplines.value.every((element) => element.value !== discipline.id)) {
        availableDisciplines.value.push({ name: discipline.name, value: discipline.id });
      }
    });
  });
  //sort by name
  availableAttendees.value = availableAttendees.value.sort((a, b) => a.name.localeCompare(b.name));
  allDisciplines.value = availableDisciplines.value;
  getAttendeesTreatmentServices(eventDate);
  return { availableAttendees, availableDisciplines };
}

/**
 * useGetDropdownOptions
 *
 * Hook for updating the options of two dropdowns, based upon the values chosen in either dropdown.
 * If you choose a discipline, the attendee options update based upon who currently has that discipline.
 * If you choose a new attendee, the discipline options filter to what the currently selected attendees can
 * attend a session for while also filtering the selectable attendees based upon their group eligibility, and whether
 * they have the same discipline or not.
 *
 * Note: Call this hook once in setup, with selectedDiscipline and selectedAttendees as the model values for the dropdowns
 * and you are good to go
 * @param clinicianId {number} Id of clinician
 * @param selectedDiscipline {Ref<number> | number} Id of the currently selected discipline
 * @param selectedAttendees {Ref<AttendeeDropdownOptionInterface[]> | AttendeeDropdownOptionInterface[]} Array of attendees currently selected
 * @param eventType {ComputedRef<{ id: number; label: EventType }>} Event type to determine whether filtering is necessary
 */
export async function useGetDropdownOptions(
  clinicianId: number,
  selectedDiscipline: Ref<number> | number,
  selectedAttendees: Ref<AttendeeDropdownOptionInterface[]> | AttendeeDropdownOptionInterface[],
  eventType: ComputedRef<{ id: number; label: EVENT_TYPE }>,
  caseloadOrganization: Ref<ClinicianOrganizationInterface>,
  eventDate: Ref<Date>,
): Promise<{
  availableAttendees: Ref<AttendeeDropdownOptionInterface[]>;
  availableDisciplines: Ref<DropdownOptionInterface[]>;
}> {
  const { availableAttendees, availableDisciplines } = await useGetInitialDropdownOptions(clinicianId, eventDate.value);
  // For checking if attendees have a certain discipline
  const disciplineMap: Record<number, number[]> = createInitialDisciplineMap(eventDate.value);
  const studentMap: Record<number, Array<{ service: string; discipline: number }>> = createInitialStudentMap(
    eventDate.value,
  );

  const updateAvailableAttendeesAndDisciplines = () => {
    const disciplineId: number = isRef(selectedDiscipline) ? unref(selectedDiscipline) : selectedDiscipline;
    const attendees: AttendeeDropdownOptionInterface[] = isRef(selectedAttendees)
      ? unref(selectedAttendees)
      : selectedAttendees;
    const disciplineArray: DropdownOptionInterface[] = [];

    const filterAttendeesByOrganization = () => {
      const allowedOrg = attendees[0]?.organizations[0].id;
      availableAttendees.value = availableAttendees.value.filter((attendee) => {
        if (attendee.organizations.length) return attendee.organizations[0].id === allowedOrg;
      });
      availableAttendees.value = availableAttendees.value.sort((a, b) => a.name.localeCompare(b.name));
    };

    // if attendee(s) are selected, this function can be called to filter down the attendees
    // to include only attendees with the current disciplines of selected attendees which they are
    // eligible for groups with and only allow the first attendees org
    const multipleAttendeeDisciplineFilter = () => {
      const attendeeArray: AttendeeDropdownOptionInterface[] = [];
      // loops through disciplines of the first attendee
      if (!attendees.length) {
        return;
      }

      attendees[0]?.disciplines.forEach((discipline) => {
        if (discipline.is_group && discipline.group_size !== 1 && discipline.is_active) {
          // loop through our group eligible attendees
          isGroupEligibleAttendees.value.forEach((attendee) => {
            // loop through our group eligible attendees disciplines
            attendee.disciplines?.forEach((possibleDiscipline) => {
              // if the discipline matches the discipline we are checking against, move to next condition
              if (possibleDiscipline.id === discipline.id) {
                // if this matching discipline is group, add this attendee to the list of eligible attendees
                if (possibleDiscipline.is_group) {
                  // checks to see if the attendee is already included in the options list
                  const alreadyInArray = attendeeArray.findIndex((eligibleAttendee) => {
                    return attendee.value === eligibleAttendee.value;
                  });
                  if (alreadyInArray === -1) {
                    attendeeArray.push(attendee);
                  }
                }
              }
            });
          });
        }
      });
      availableAttendees.value = attendeeArray;
      if (
        ![EVENT_TYPE.EVALUATION, EVENT_TYPE.CONSULTATION, EVENT_TYPE.RECORD_REVIEW].includes(eventType.value?.label)
      ) {
        filterAttendeesByOrganization();
      }
    };

    const filterAttendeeByEventTypeAndServiceEndDate = (eventType: string) => {
      const filteredAttendees = ref<AttendeeDropdownOptionInterface[]>([]);

      availableAttendees.value.forEach((attendee) => {
        if (
          treatmentServiceMap[attendee.value].includes(eventType) &&
          filteredAttendees.value.indexOf(attendee) === -1
        ) {
          if (attendee.value) filteredAttendees.value.push(attendee);
        }
      });
      availableAttendees.value = filteredAttendees.value;
    };

    if (eventType.value?.label === EVENT_TYPE.IEP_MEETING) {
      availableAttendees.value = allAttendees.value;
      return;
    }
    if (eventType.value?.label === EVENT_TYPE.CASELOAD_MANAGEMENT) {
      if (caseloadOrganization?.value && caseloadOrganization?.value.id) {
        availableAttendees.value = allAttendees.value.filter((attendee) => {
          if (attendee.organizations.length) return attendee.organizations[0].id === caseloadOrganization?.value.id;
        });
      }
      return;
    }

    if (!attendees.length) {
      if (!disciplineId) {
        // no attendees selected, return all disciplines
        availableAttendees.value = allAttendees.value;
      } else {
        // discipline is selected, return attendees who can attend this discipline type
        availableAttendees.value = allAttendees.value.filter((attendee) =>
          // if our discipline map array value holds the attendee value, add them to the options list
          disciplineMap[disciplineId]?.includes(attendee.value),
        );
      }
      // return all disciplines since no attendee selected
      disciplineArray.push(...allDisciplines.value);
    } else if (attendees.length === 1) {
      if (disciplineId) {
        // if attendees selected is 1 and the discipline has been selected already, store the discipline in a variable
        const attendeeDiscipline = attendees[0].disciplines.find((discipline) => discipline.id === disciplineId);
        if (attendeeDiscipline?.is_group) {
          // if discipline chosen and attendee chosen can group, return all group eligible attendees with that discipline
          availableAttendees.value = isGroupEligibleAttendees.value.filter((attendee) =>
            disciplineMap[disciplineId]?.includes(attendee.value),
          );
        } else {
          // groups not allowed for this attendee, return no attendees
          availableAttendees.value = [];
        }
      } else {
        if (attendees[0]?.disciplines.length === 1) {
          // if the chosen attendee only has one discipline, store it in a variable
          const attendeeDiscipline = attendees[0].disciplines[0];
          if (attendeeDiscipline.is_group) {
            // if attendee only has one discipline and can group, return attendees with same discipline and can group
            availableAttendees.value = isGroupEligibleAttendees.value.filter((attendee) =>
              disciplineMap[attendeeDiscipline.id]?.includes(attendee.value),
            );
          } else {
            // groups not allowed for this attendee, return no attendees
            availableAttendees.value = [];
          }
        } else {
          // if attendee selected has more than one discipline, call the attendee discipline filter function
          multipleAttendeeDisciplineFilter();
        }
      }
      // regardless of any of the conditions above, if attendees selected is one, return all disciplines for that attendee in the chosen service
      attendees[0]?.disciplines?.forEach((discipline) => {
        if (
          disciplineArray.every((element) => element.value !== discipline.id) &&
          studentMap[attendees[0].value].find(
            (element) => element.service === eventType.value?.label && element.discipline === discipline.id,
          )
        ) {
          disciplineArray.push({ name: discipline.name, value: discipline.id });
        }
      });
    } else {
      // if more than one attendee chosen
      if (disciplineId) {
        // if discipline chosen, return all group eligible attendees with that discipline
        availableAttendees.value = isGroupEligibleAttendees.value.filter((attendee) =>
          disciplineMap[disciplineId].includes(attendee.value),
        );
      } else {
        // if discipline hasnt been selected, call the attendee discipline filter function
        multipleAttendeeDisciplineFilter();
      }
      // more than one attendee selected, return disciplines that all selected attendees are eligible for
      allDisciplines.value.forEach((discipline) => {
        // if every selected attendee is included in the disciplineMap array and the discipline is attach to the actual service, push it to the array
        if (
          attendees.every(
            (attendee) =>
              disciplineMap[discipline.value].includes(attendee.value) &&
              studentMap[attendee.value].find(
                (element) => element.service === eventType.value?.label && element.discipline === discipline.value,
              ),
          )
        ) {
          disciplineArray.push(discipline);
        }
      });
    }

    // the selected attendee(s) need to be added back to the available attendees array
    // so the multiselect can still have them be selectable/deselectable in the dropdown
    if (attendees.length) {
      attendees.forEach((attendee) => {
        const alreadyInArray = availableAttendees.value.findIndex((eligibleAttendee) => {
          return attendee.value === eligibleAttendee.value;
        });
        if (alreadyInArray === -1 || !availableAttendees.value.length) {
          availableAttendees.value.push(attendee);
        }
      });
      if (
        ![EVENT_TYPE.EVALUATION, EVENT_TYPE.CONSULTATION, EVENT_TYPE.RECORD_REVIEW].includes(eventType.value?.label)
      ) {
        filterAttendeesByOrganization();
      }
    }
    filterAttendeeByEventTypeAndServiceEndDate(eventType.value?.label);

    // set the returning availableDisciplines value to the disciplineArray
    availableDisciplines.value = disciplineArray;
  };

  if (isRef(selectedAttendees) && isRef(selectedDiscipline) && isRef(eventDate)) {
    watchEffect(updateAvailableAttendeesAndDisciplines);
    watchEffect(() => useGetInitialDropdownOptions(clinicianId, eventDate.value));
  } else {
    updateAvailableAttendeesAndDisciplines();
  }

  return { availableAttendees, availableDisciplines };
}

/**
 * createInitialDisciplineMap
 *
 * Creates an object map of discipline id keys to array of attendee ids as values.
 * It makes checking whether an attendee has a certain discipline very simple.
 * ex: { 1: [1,2,3,4,5], 2: [2,4] 3: [1,2,3] }
 * Now if you want to check to see if attendee 1 can join a discipline 2 meeting,
 * you can just do disciplineMap[2].includes(1);
 */
const createInitialDisciplineMap = (eventDate: Date): Record<number, number[]> => {
  const objectMap: Record<number, number[]> = {};
  allDisciplines.value.forEach((discipline) => {
    objectMap[discipline.value] = [];
  });
  allAttendees.value.forEach((attendee) => {
    attendee.disciplines.forEach((discipline) => {
      const serviceEndDate = new Date(discipline.end_at);
      if (discipline.is_active || serviceEndDate > eventDate) {
        objectMap[discipline.id].push(attendee.value);
      }
    });
  });
  return objectMap;
};

const createInitialStudentMap = (eventDate: Date): Record<number, { service: string; discipline: number }[]> => {
  const objectMap: Record<number, { service: string; discipline: number }[]> = {};
  allAttendees.value.forEach((student) => {
    objectMap[student.value] = [];
    student.disciplines.forEach((discipline) => {
      const serviceEndDate = new Date(discipline.end_at);
      if (discipline.is_active || serviceEndDate > eventDate) {
        objectMap[student.value].push({ service: discipline.service?.name as string, discipline: discipline.id });
      }
    });
  });

  return objectMap;
};

const getAttendeesTreatmentServices = (eventDate: Date) => {
  allAttendees.value.forEach((attendee) => {
    treatmentServiceMap[attendee.value] = [];
    attendee.disciplines.forEach((discipline) => {
      const serviceEndDate = new Date(discipline.end_at);
      if (discipline.is_active || serviceEndDate > eventDate) {
        if (discipline.evalutaion_only && discipline.service?.name !== EVENT_TYPE.EVALUATION) return;
        treatmentServiceMap[attendee.value].push(discipline.service?.name as string);
      }
    });
  });
};
