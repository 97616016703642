import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { can } from '@/composables';
import { PERMISSIONS } from '@/enum';
import ActivityLogDashboardService from '@/services/ActivityLogDashboardService';
import { ActivityLogDashboardStoreInterface } from '@/types/ActivityLog';

interface PreferenceInterface {
  id: number;
  person_id: number;
  name: string;
  value: string;
}

export default defineStore('ActivityLogDashboardStore', {
  state: (): ActivityLogDashboardStoreInterface => ({
    error: undefined,
    loading: false,
    students: [],
    logs: [],
    filters: {
      start_at: '',
      end_at: '',
      search: '',
      person_id: '',
      event_type: '',
    },
    currentPage: 0,
    oldFilters: '',
  }),

  getters: {
    userLogs: (state) => state.logs,
  },

  actions: {
    catchError(e: AxiosResponse) {
      this.error = e.data ? e.data.message : 'There was an error';
      this.loading = false;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    setOldFilters() {
      this.oldFilters = `${this.filters.start_at}-${this.filters.end_at}-${this.filters.search}`;
    },

    getFiltersHash() {
      return `${this.filters.start_at}-${this.filters.end_at}-${this.filters.search}`;
    },

    restorePaginationState(force?: boolean) {
      if (force || this.getFiltersHash() !== this.oldFilters) {
        this.currentPage = 0;
        this.logs = [];
        this.filters.per_page = 100;
      }

      this.setOldFilters();
    },

    async getDashboardActivityDateRangePreference(personId: number) {
      this.loading = true;
      const response = await ActivityLogDashboardService.getDashboardActivityDateRangePreference(personId).catch(
        (response) => this.catchError(response),
      );

      const preference = response?.data.data.find(
        (preference: PreferenceInterface) => preference.name === 'dashboard_activity_date_range',
      );

      this.loading = false;

      return preference ? preference.value.split(',').map((days: string) => Number(days)) : [0, 27];
    },

    async getLogs(organizanizationsIds?: Array<number>) {
      if (!can([PERMISSIONS.DASHBOARD_ACTIVITY_INDEX])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view activity logs';
        return;
      }

      this.restorePaginationState();

      if (this.currentPage === -1 || this.loading) return;

      this.loading = true;
      this.error = undefined;
      this.currentPage++;

      const sendFilter = { ...this.filters };

      if (this.filters.person_id) sendFilter.search = '';

      const response = await ActivityLogDashboardService.getLogs(sendFilter, this.currentPage, organizanizationsIds);

      if (response) {
        if (response.data.meta.current_page === response.data.meta.last_page) this.currentPage = -1;

        this.logs = this.filters.event_type !== '' ? response.data.data : this.logs.concat(response.data.data);
      }
      this.loaded();
    },

    //TODO: leave this commented out for now until we see if we need it or not
    // async getStudents() {
    //   if (!can([PERMISSIONS.DASHBOARD_ACTIVITY_STUDENTS])) {
    //     this.loading = false;
    //     this.error = 'Insufficient permissions to view student activity';
    //     console.error(this.error);

    //     return;
    //   }

    //   this.loading = true;

    //   const response = await ActivityLogDashboardService.getDashboardActivityStudents().catch((response) =>
    //     this.catchError(response),
    //   );

    //   this.students = response?.data.data;

    //   this.loaded();
    // },
  },
});
