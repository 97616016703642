import { LocationFormInterface, LocationInterface } from '@/types';

import { OrganizationService } from '@/services';
import { defineStore } from 'pinia';
import { formatPhone } from '@/composables';

export default defineStore('OrganizationLocationStore', {
  state: () => {
    return {
      loading: true,
      location: {} as LocationInterface,
    };
  },

  actions: {
    /**
     * Get the organization location data from the API and save it to state.
     */
    async getLocation(organizationId: number) {
      const response = await OrganizationService.getLocation(organizationId).catch(this.catchError);
      const data = response.data.data;
      data.location_type_id = data.location_type.id;
      data.city_id = data.city.id;
      data.city_name = data.city.name;
      data.state_id = data.state.id;
      data.country_id = data.country.id;
      data.phone = formatPhone(data.phone);

      this.location = data;
      this.loading = false;
    },
    catchError(e: Error) {
      this.loading = false;
      throw e;
    },
    /**
     * Update the organization location and return the response.
     */
    async updateLocation(payload: LocationFormInterface, organizacionId: number) {
      this.loading = true;
      const response = await OrganizationService.updateOrganizationLocation(payload, organizacionId);
      const data = response.data.data;
      data.location_type_id = data.location_type.id;
      data.city_id = data.city.id;
      data.city_name = data.city.name;
      data.state_id = data.state.id;
      data.country_id = data.country.id;
      data.phone = formatPhone(data.phone);

      this.location = data;
      this.loading = false;
      return response;
    },
  },
});
