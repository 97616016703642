import { TreeNode } from 'primevue/tree';
import { ComplianceStudent } from '@/types';

export const useArrayUtilities = () => {
  /**
   * Flatten the provided deeply nested array based on a key naming convention of children.
   */
  const useFlatten = (array: TreeNode[]): TreeNode[] => {
    let children: TreeNode[] = [];

    return array
      .map((mem) => {
        const m = { ...mem };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (m.children && m.children.length) children = [...children, ...m.children];

        delete m.children;

        return m;
      })
      .concat(children.length ? useFlatten(children) : children);
  };

  const useValueNameSort = (arr: ComplianceStudent[]) => {
    return arr.sort((a, b) => {
      if ((a.percentage as number) > (b.percentage as number)) {
        return 1;
      } else if ((a.percentage as number) < (b.percentage as number)) {
        return -1;
      }

      const nameA = (a.full_name as string).toUpperCase();
      const nameB = (b.full_name as string).toUpperCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      }

      return 0;
    });
  };

  const removeFromArray = (array: Array<string>, value: string) => {
    return array.filter((val) => val !== value);
  };

  return {
    removeFromArray,
    useFlatten,
    useValueNameSort,
  };
};
