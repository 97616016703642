import { defineStore } from 'pinia';
import { OrganizationTypesService } from '@/services';
import { OrganizationTypeInterface } from '@/types';

export default defineStore('OrganizationTypeStore', {
  state: () => {
    return {
      loading: false,
      types: [] as OrganizationTypeInterface[],
    };
  },

  actions: {
    async getOrganizationTypes() {
      this.loading = true;
      const response = await OrganizationTypesService.getOrganizationTypes();
      this.types = response.data.data;
      this.loading = false;
    },
  },
});
