import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { REQUEST } from '@/enum';
import { useRequestCancel } from '@/composables/useRequestCancel';

interface DateFilter {
  start_at: string;
  end_at: string;
}

const joinFilters = (filters: DateFilter, organizations?: number[]) => {
  return (
    `${Object.keys(filters)
      .map((item) => `${item}=${filters[item as keyof DateFilter]}`)
      .join('&')}&` + organizations?.map((organization) => `organizations[]=${organization}`).join('&')
  );
};

export default class ComplianceService {
  static resource = 'widget/compliance';
  static requestCancel = useRequestCancel();

  static getAll(filters: DateFilter, threshold?: number, organizations?: number[]): ApiPromise {
    if (threshold) {
      return HTTP.get(`${this.resource}?${joinFilters(filters, organizations)}&threshold=${threshold}`, {
        ...this.requestCancel.requestSaverSetCancellation(REQUEST.ALL_COMPLIANCE),
      });
    }

    return HTTP.get(`${this.resource}?${joinFilters(filters, organizations)}`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.COMPLIANCE),
    });
  }

  static getOutOfComplianceDateRangePreference(personId: number): ApiPromise {
    return HTTP.get(`/people/${personId}/preferences?name=out_of_compliance_date_range`);
  }
}
