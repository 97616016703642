import { ApiPromise, LocationFormInterface } from '@/types';

import { HTTP } from '@/utils';

export default class LocationService {
  static resource = 'locations';

  static updateLocation(payload: LocationFormInterface, id: number): ApiPromise {
    return HTTP.patch(`${this.resource}/${id}`, payload);
  }
}
