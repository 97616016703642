import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class PeopleTypesService {
  static resource = 'people-types';

  static getPeopleTypes(): ApiPromise {
    return HTTP.get(`${this.resource}`);
  }
}
