import { defineStore } from 'pinia';
import { AxiosResponse } from 'axios';
import { ClinicianService } from '@/services';
import { ClinicianStudentInterface, ClinicianStudentStateInterface } from '@/types';

export default defineStore('ClinicianStudentStore', {
  state: (): ClinicianStudentStateInterface => ({
    students: [],
    studentsForClinicianSelected: [],
    error: '',
    loading: false,
  }),

  getters: {},

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * attachStudents
     *
     * Assign students to a specific clinician
     */
    async attachStudents(payload: {
      students: number[];
      clinicianId: number | string;
    }): Promise<AxiosResponse<unknown>> {
      const { clinicianId, students } = payload;
      const response = await ClinicianService.attachStudentsRequest(students, clinicianId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * detachStudents
     *
     * Remove students from a specific clinician
     */
    async detachStudents(payload: {
      students: number[];
      clinicianId: number | string;
    }): Promise<AxiosResponse<unknown>> {
      const { clinicianId, students } = payload;
      const response = await ClinicianService.detachStudentsRequest(students, clinicianId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * getAddStudentsModalData
     *
     * Retrieve all students available to attach to a clinician
     *
     */
    async getAddStudentsModalData(clinicianId: number | string): Promise<{ name: string; value: number }[]> {
      const request = await ClinicianService.getAddStudentsModalData(clinicianId).catch(this.catchError);
      const availableToAdd: ClinicianStudentInterface[] = request.data?.data;

      return availableToAdd.map((student) => {
        return {
          value: student.id as number,
          name: student.display_name as string,
        };
      });
    },
  },
});
