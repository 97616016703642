import { defineStore } from 'pinia';
import { TreeNode } from 'primevue/tree';
import { useAuthStore } from '@/store';
import { useDateTime, usePersonDropdown, usePersonTypes, useRouting } from '@/composables';
import { ReportService } from '@/services';
import { DropdownOptionInterface } from '@/types';

interface BaseDates {
  start: Date;
  end: Date;
}

interface OrganizationTreeNode extends TreeNode {
  isRsm: boolean;
}

export default defineStore('ReportStore', {
  state: () => {
    return {
      referrer: undefined,
      filters: {
        clinicians: {
          options: [] as DropdownOptionInterface[],
          selected: [] as number[] | undefined,
          loading: false,
        },
        organizations: {
          options: [] as OrganizationTreeNode[],
          selected: {},
          isRsm: false,
          loading: false,
        },
        dates: [] as Date[],
      },
      attendeeListCacheId: '',
      is_rsm: 0,
      loading: false,
      $routing: useRouting(),
    };
  },

  getters: {
    clinicians: (state) => {
      const arr: DropdownOptionInterface[] = state.filters.clinicians.options;
      const ids = state.filters.clinicians.selected;
      const clinicianNames: string[] = [];
      if (ids?.length) {
        ids.forEach((id) => {
          const findClinicians = arr.find((item) => item.value === +id);
          if (findClinicians) clinicianNames.push(findClinicians.name);
        });

        return clinicianNames.length ? clinicianNames.join(', ') : [];
      }
      return undefined;
    },

    organizations: (state) => {
      const arr: TreeNode[] = state.filters.organizations.options.flat();
      const ids = Object.keys(state.filters.organizations.selected);
      const organizationNames: string[] = [];

      ids.forEach((id) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const findOrganization = arr.find((item) => item.key === +id);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (findOrganization) organizationNames.push(findOrganization.label);
      });

      return organizationNames.length ? organizationNames.join(', ') : 'All Organizations';
    },

    organizationIds: (state) => `${Object.keys(state.filters.organizations.selected).toString()}`,
  },

  actions: {
    /**
     * Get the clinicians available for the specific organization.
     */
    async getReportServiceProviderFilter(start_at: Date | string, end_at: Date | string) {
      const { clinicians } = this.filters;
      this.loading = true;
      clinicians.loading = true;
      const arrayOrganizationIdsNumbers = Object.keys(this.filters.organizations.selected).map(Number);
      const response = await ReportService.getReportServiceProviderFilter(
        arrayOrganizationIdsNumbers,
        start_at,
        end_at,
      );
      const clinicianOptions = usePersonDropdown(response.data.data);
      clinicians.loading = false;
      clinicians.options = clinicianOptions;
      this.filterSelectedClinicians();
      this.loading = false;
    },

    /**
     * if selecting a new org make sure the previously selected clinician is a part of it
     * and if not then remove them
     */
    filterSelectedClinicians() {
      if (!usePersonTypes.isClinician()) {
        const { clinicians } = this.filters;

        clinicians.selected = clinicians.selected?.filter((id) =>
          clinicians.options.find((clinician) => clinician.value === id),
        );

        clinicians.selected?.length
          ? this.$routing.addQueryParam('clinicians', clinicians.selected.join(','))
          : this.$routing.removeQueryParam('clinicians');
      }
    },

    /**
     * Get all available organization parents.
     */
    async getReportOrganizationFilter(service_start_at: Date, service_end_at: Date) {
      const { organizations } = this.filters;
      this.loading = true;
      organizations.loading = true;
      const response = await ReportService.getReportOrganizationFilter(service_start_at, service_end_at);
      organizations.loading = false;
      organizations.options = response.data.data;
      this.loading = false;
    },

    /**
     * Define a reusable default start and end date object.
     */
    baseDates(): BaseDates {
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();

      return {
        start: new Date(year, month, 1),
        end: new Date(year, month + 1, 0),
      };
    },

    /**
     * Define all necessary default filters and selected values based on query params.
     */
    async defineDefaults() {
      await this.defineDefaultRsm();
      await this.defineDefaultDates();
      await this.defineDefaultParentsOrganizations();
      await this.defineDefaultClinician();
      await this.defineDefaultQueryParams();
    },

    defineDefaultQueryParams() {
      const startAt = useDateTime().formatDateForApi(this.filters.dates[0]);
      const endAt = useDateTime().formatDateForApi(this.filters.dates[1]);
      const clinicians = String(this.filters.clinicians.selected);
      const defaultParams = {
        start_at: startAt,
        end_at: endAt,
      };

      const query: {
        start_at: string;
        end_at: string;
        is_rsm?: number;
        clinicians?: string;
      } = usePersonTypes.isClinician() ? defaultParams : { ...defaultParams, is_rsm: this.is_rsm };

      if (clinicians.length) query.clinicians = clinicians;

      this.$routing.addQueryParams(query);
    },

    /**
     * Define the default date range based on the current url params or by setting the dates
     * based on the current month.
     */
    async defineDefaultRsm() {
      const query = this.$routing.route.query;

      if (query.rsm) {
        this.is_rsm = Number(query.is_rsm);
      } else {
        this.is_rsm = 0;
      }
    },

    async defineDefaultDates() {
      const query = this.$routing.route.query;
      let start, end;

      if (query.start_at && query.end_at) {
        const startDate = new Date(String(query.start_at));
        const startMonth = startDate.getMonth();
        const startYear = startDate.getFullYear();
        start = new Date(startYear, startMonth + 1);

        const endDate = new Date(String(query.end_at));
        const endMonth = endDate.getMonth();
        const endDay = endDate.getDate() + 1;
        const endYear = endDate.getFullYear();
        end = new Date(endYear, endMonth, endDay);
      } else {
        const dates = this.baseDates();
        start = dates.start;
        end = dates.end;
      }

      this.filters.dates = [start, end];
      this.defineDateQueryParams();
    },

    /**
     * Handle the date range picker change event by setting the date range within state
     * so it can be used throughout other reporting components.
     */
    onDateRangeChange(event: Date[]) {
      this.filters.dates = [event[0], event[1]];
      this.defineDateQueryParams();
    },

    /**
     * Define the date range url query params.
     */
    defineDateQueryParams() {
      const dateUtils = useDateTime();
      const selectedDates = this.filters.dates;
      const startAt = dateUtils.formatDateForApi(selectedDates[0]);
      let endAt = dateUtils.formatDateForApi(selectedDates[1]);

      if (!endAt) {
        console.error('No end date was selected.');
        endAt = startAt;
      }

      const defaultParams = {
        start_at: startAt,
        end_at: endAt,
      };

      const params = usePersonTypes.isClinician() ? defaultParams : { ...defaultParams, is_rsm: this.is_rsm };
      this.$routing.addQueryParams(params);
    },

    /**
     * Define the default clinician(s) to be selected whether or not a query param is available.
     */
    async defineDefaultClinician() {
      const query = this.$routing.route.query;
      const clinicians: number[] = [];
      this.filters.clinicians.selected = [];

      if (query.clinicians) {
        query.clinicians
          .toString()
          .split(',')
          .forEach((item) => clinicians.push(+item));
      }

      if (usePersonTypes.isClinician()) {
        const user = useAuthStore().user?.id;

        if (user) {
          this.filters.clinicians.selected = !user ? [] : [user];
        }
      } else {
        this.filters.clinicians.selected = query.clinicians ? clinicians : [];
      }
    },

    /**
     * Define the default organization(s) to be selected whether or not a query param is available.
     */
    async defineDefaultParentsOrganizations() {
      const query = this.$routing.route.query;
      const organizations: { [key: string]: boolean } = {};

      if (query.organizations) {
        query.organizations
          .toString()
          .split(',')
          .forEach((item) => (organizations[item] = true));
      }

      this.filters.organizations.selected = query.organizations ? organizations : {};
    },
  },
});
