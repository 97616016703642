import { ApiPromise, CitySearchInterface } from '@/types';

import { HTTP } from '@/utils';

export default class CitiesService {
  static resource = 'cities';

  static searchCities(payload: CitySearchInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof CitySearchInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.resource}${filter}`);
  }
}
