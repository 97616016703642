import axios, { InternalAxiosRequestConfig } from 'axios';
import { useAuthStore } from '@/store';
import router from '@/router';

export interface ResponseDataInterface {
  data: Record<string, unknown>;
}

interface AuthorizationConfigInterface {
  config: InternalAxiosRequestConfig;
  token: string;
  isImpersonating: boolean;
  isTenantSwitching: boolean;
  originalToken: string | undefined;
  originalTenantToken: string;
  tenant: number;
  isTenantMaster: boolean;
}

/**
 * The available public routes that do not require an authentication token.
 */
const publicAPIRoutes: string[] = [
  'auth/activate-account',
  'auth/login',
  'auth/forgot-password',
  'auth/reset-password',
  'auth/verify-token-expired',
];

/**
 * Get the authorization Bearer token string to be used within each
 * authenticated request.
 */
const getBearer = (token: string): string => `Bearer ${token}`;

/**
 * Get all authorization configuration settings for every request.
 */
const getAuthorizationConfig = (authConfiguration: AuthorizationConfigInterface): InternalAxiosRequestConfig => {
  const {
    token,
    config,
    isImpersonating,
    isTenantMaster,
    isTenantSwitching,
    originalTenantToken,
    originalToken,
    tenant,
  } = authConfiguration;

  config.headers.Authorization = getBearer(token);
  config.headers.tenant = tenant;
  if (isImpersonating) config.headers['original-auth-token'] = originalToken;

  if (isTenantSwitching && originalTenantToken && !isTenantMaster)
    config.headers['original-tenant-token'] = originalTenantToken;

  return config;
};

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    accept: 'application/json',
  },
});

/**
 * Add a Request interceptor for calls to inject
 * the token we got on login.
 */
HTTP.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url && publicAPIRoutes.includes(config.url)) return config as InternalAxiosRequestConfig;

    let axiosConfig: InternalAxiosRequestConfig = config;

    const authStore = useAuthStore();
    const { token, impersonateToken, impersonateTenantToken, isSwitchTenant } = authStore;

    if (token) {
      const authConfiguration = {
        config: axiosConfig,
        token: impersonateToken?.access_token ?? impersonateTenantToken?.access_tenant_token ?? token.access_token,
        isImpersonating: !!impersonateToken?.access_token,
        isTenantSwitching: isSwitchTenant,
        originalToken: impersonateTenantToken?.access_tenant_token ?? token.access_token,
        originalTenantToken: token.access_token,
        tenant: impersonateTenantToken?.guest_tenant?.id ?? token.tenant.id,
        isTenantMaster: !!impersonateTenantToken?.guest_tenant?.master,
      } as AuthorizationConfigInterface;

      axiosConfig = getAuthorizationConfig(authConfiguration);
    } else {
      if (!authStore.loading) {
        router.push('/account/login');
        return axiosConfig;
      }
    }
    return axiosConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error.response);
  },
);

/**
 * or catch any 401 at the service layer
 * I will leave this here for now
 */
HTTP.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.request && 401 === error.request.status) {
      const authStore = useAuthStore();
      if (!authStore.loading) authStore.stopLogin();
    }
    // Check if theres any cancellation request
    if (!axios.isCancel(error)) return Promise.reject(error.response);
  },
);
