import { GuardianInterface, GuardianStateInterface } from '@/types';

import { AxiosResponse } from 'axios';
import { StudentService } from '@/services';
import { defineStore } from 'pinia';

export default defineStore('GuardianStore', {
  state: (): GuardianStateInterface => ({ guardians: [], guardiansForStudentSelected: [], error: '', loading: false }),
  getters: {},
  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },
    /**
     * attachGuardians
     *
     * Assign parents to a specific student
     */
    async attachGuardians(payload: { parents: number[]; studentId: number | string }): Promise<AxiosResponse<unknown>> {
      const { studentId, parents } = payload;
      const response = await StudentService.attachGuardiansRequest(parents, studentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * detachGuardians
     *
     * Remove students from a specific organization
     */
    async detachGuardians(payload: { parents: number[]; studentId: number | string }): Promise<AxiosResponse<unknown>> {
      const { studentId, parents } = payload;
      const response = await StudentService.detachGuardiansRequest(parents, studentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    /**
     * getGuardiansForStudent
     *
     * Retrieve all guardians assigned to a student
     *
     */
    async getGuardiansForStudent(studentId: number | string): Promise<GuardianInterface[]> {
      this.loading = true;
      const response = await StudentService.getGuardiansForStudentRequest(studentId).catch(this.catchError);
      this.guardiansForStudentSelected = response.data?.data as GuardianInterface[];

      if (Array.isArray(this.guardiansForStudentSelected) && !this.getGuardiansForStudent.length) {
        return [];
      }

      this.loaded();
      return this.guardiansForStudentSelected;
    },

    /**
     * getAddGuardiansModalData
     *
     * Retrieve all guardians available to attach to a student
     *
     */
    async getAddGuardiansModalData(studentId: number | string): Promise<{ name: string; value: number }[]> {
      const request = await StudentService.getAddGuardiansModalData(studentId).catch(this.catchError);
      const availableToAdd: GuardianInterface[] = request.data?.data;

      return availableToAdd.map((guardian) => {
        return {
          value: guardian.id,
          name: guardian.display_name as string,
        };
      });
    },
  },
});
