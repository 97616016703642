import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { SchoolAdminOrganizationStateInterface } from '@/types/SchoolAdmins';
import SchoolAdminService from '@/services/SchoolAdminService';
import { OrganizationsInterface } from '@/types';

export default defineStore('SchoolAdminOrganizationStore', {
  state: (): SchoolAdminOrganizationStateInterface => ({
    organizations: [],
    error: '',
    loading: false,
  }),

  getters: {},

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * Assign organization to a specific school admin
     */
    async attachOrganizations(payload: {
      organizations: number[];
      schoolAdminId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { schoolAdminId, organizations } = payload;
      const response = await SchoolAdminService.attachOrganizationsToSchoolAdmin(organizations, schoolAdminId).catch(
        this.catchError,
      );

      this.loaded();
      return response;
    },

    /**
     * Remove organization from a specific school admin
     */
    async detachOrganizations(payload: {
      organization: number;
      schoolAdminId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { schoolAdminId, organization } = payload;
      const response = await SchoolAdminService.detachOrganizationsFromSchoolAdmin(organization, schoolAdminId).catch(
        this.catchError,
      );

      this.loaded();
      return response;
    },

    /**
     * Retrieve organization available to attach to a school admin
     */
    async getAddOrganizationsModalData(schoolAdminId: number): Promise<OrganizationsInterface[]> {
      const request = await SchoolAdminService.getAddOrganizationsModalData(schoolAdminId).catch(this.catchError);
      const availableToAdd: OrganizationsInterface[] = request.data?.data;
      return availableToAdd;
    },
  },
});
