export const useCurrency = () => {
  /**
   * format a number for US currency
   * @param value
   * @returns
   */
  const formatCurrency = (value: number) => {
    return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  return { formatCurrency };
};
