import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class ZipcodeService {
  static resource = 'zipcodes';

  static searchTimezonesByZipcode(zipcode: string): ApiPromise {
    return HTTP.get(`${this.resource}?search=${zipcode}`);
  }
}
