import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class ImpersonateService {
  static url = 'impersonate';

  static impersonate(id: number): ApiPromise {
    return HTTP.get(`${this.url}/${id}`);
  }

  static leaveImpersonate(): ApiPromise {
    return HTTP.get(`${this.url}/leave`);
  }
}
