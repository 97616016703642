import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UtilizationService } from '@/services';
import { useDateTime } from '@/composables';
import { UtilizationAnalyticsInterface, SOWReportingTableInterface, TimeRatioReportingTableInterface } from '@/types';

export const useUtilizationStore = defineStore('UtilizationStore', () => {
  const loading = ref<boolean>(false);
  const sOWTableData = ref<SOWReportingTableInterface[]>([]);
  const timeRatioTableData = ref<TimeRatioReportingTableInterface[]>([]);
  const sOWFilterDate = ref<string>(useDateTime().formatDateForApi(new Date()));
  const timeRatioFilterDate = ref<string>(useDateTime().formatDateForApi(new Date()));

  const getUtilizationDashboardWidgetData = async () => {
    loading.value = true;

    const response = await UtilizationService.getDashboardWidgetData(useDateTime().formatDateForApi(new Date()));
    sOWTableData.value = generateSOWData(response.data.data);
    timeRatioTableData.value = generateTimeRatioData(response.data.data);
    loading.value = false;
  };

  const updateSOWData = async () => {
    const response = await UtilizationService.getDashboardWidgetData(sOWFilterDate.value);
    sOWTableData.value = generateSOWData(response.data.data);
  };

  const updateTimeRatioData = async () => {
    const response = await UtilizationService.getDashboardWidgetData(timeRatioFilterDate.value);
    timeRatioTableData.value = generateTimeRatioData(response.data.data);
  };

  const generateSOWData = (data: UtilizationAnalyticsInterface[]): SOWReportingTableInterface[] => {
    return data.map(({ clinician, organization, sow_utilization }) => {
      return {
        clinician: clinician,
        organization: organization.name as string,
        sow_utilization: Number(sow_utilization),
      };
    });
  };

  const generateTimeRatioData = (data: UtilizationAnalyticsInterface[]): TimeRatioReportingTableInterface[] => {
    return data.map(
      ({ clinician, organization, direct_time_percentage, indirect_time_percentage, evaluation_time_percentage }) => {
        return {
          clinician: clinician,
          organization: organization.name as string,
          direct_time_percentage: Number(direct_time_percentage),
          indirect_time_percentage: Number(indirect_time_percentage),
          evaluation_time_percentage: Number(evaluation_time_percentage),
        };
      },
    );
  };

  const updateSOWFilterDate = async (date: Date) => {
    sOWFilterDate.value = useDateTime().formatDateForApi(date);
    await updateSOWData();
  };

  const updateTimeRatioFilterDate = async (date: Date) => {
    timeRatioFilterDate.value = useDateTime().formatDateForApi(date);
    await updateTimeRatioData();
  };

  return {
    loading,
    sOWTableData,
    timeRatioTableData,
    getUtilizationDashboardWidgetData,
    updateSOWFilterDate,
    updateTimeRatioFilterDate,
  };
});
