import { defineStore } from 'pinia';
import { OrganizationService } from '@/services';
import { OrganizationIntegrationCustomerInterface, QuickbooksCustomerInterface } from '@/types';

interface IntegrationCustomerInterface {
  id: number;
  name: string;
}

export default defineStore('OrganizationIntegrationStore', {
  state: () => {
    return {
      customers: [] as QuickbooksCustomerInterface[],
      error: false,
      loading: false,
      message: '',
      integrationCustomer: {} as IntegrationCustomerInterface,
    };
  },
  actions: {
    load() {
      this.loading = true;
      this.error = false;
      this.message = '';
    },
    loaded(msg = '') {
      if (!this.error) {
        this.loading = false;
        this.error = false;
        this.message = msg;
      }
    },
    /* A method that is called when an error is thrown. */
    catchError(e: string) {
      this.loading = false;
      this.error = true;
      this.message = e;
      throw e;
    },

    /* Getting the quickbooks customers to assign for integration. */
    async getIntegrationCustomers() {
      this.load();
      const response = await OrganizationService.getIntegrationCustomers();
      this.customers = response.data.data;
      this.loaded();
    },
    async saveOrganizationIntegrationCustomer(payload: OrganizationIntegrationCustomerInterface) {
      this.load();
      await OrganizationService.saveOrganizationIntegrationCustomer(payload).catch(this.catchError);
      this.loaded('Quickbooks Has Been Integrated');
    },
    /* Getting the quickbooks customer to assign by default for integration. */
    async getOrganizationIntegrationCustomer(organizationId: number) {
      this.load();
      const response = await OrganizationService.getOrganizationIntegrationCustomer(organizationId).catch(
        this.catchError,
      );
      if (response.data) this.integrationCustomer = response.data.data;
      this.loaded();
    },
  },
});
