import { StudentInterface } from '@/types';

import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { ParentStudentStateInterface } from '@/types/Student';
import ParentService from '@/services/ParentService';

export default defineStore('ParentStudentStore', {
  state: (): ParentStudentStateInterface => ({ students: [], studentsForParent: [], error: '', loading: false }),

  getters: {},

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    async attachStudents(payload: { students: number[]; parentId: number }): Promise<AxiosResponse<unknown>> {
      const { parentId, students } = payload;
      const response = await ParentService.attachStudentsToParent(students, parentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    async detachStudent(payload: { students: number[]; parentId: number }): Promise<AxiosResponse<unknown>> {
      const { parentId, students } = payload;
      const response = await ParentService.detachStudentsFromParent(students, parentId).catch(this.catchError);

      this.loaded();
      return response;
    },

    async getAddStudentsModalData(parentId: number): Promise<{ name: string; value: number }[]> {
      const request = await ParentService.getAddStudentsModalData(parentId).catch(this.catchError);
      const availableToAdd: StudentInterface[] = request.data?.data;

      return availableToAdd.map((student) => {
        return {
          value: student.id,
          name: student.display_name as string,
        };
      });
    },
  },
});
