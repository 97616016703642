export const helpScoutConfigs = {
  /**
   * The public help scout configuration.
   *
   */
  public: {
    /**
     * The base url.
     *
     */
    baseurl: 'eluma-public.helpscoutdocs.com',

    /**
     * The form identifier.
     *
     */
    formid: '1268587b-70a7-464f-bde5-ab9c645077fa',

    /**
     * The default page.
     *
     */
    page: 'login page',

    profileLink: '',
  },

  /**
   * The private help scout configuration.
   *
   * @type {Object}
   */
  private: {
    /**
     * The base url.
     *
     */
    baseurl: 'eluma.helpscoutdocs.com',

    /**
     * The form identifier.
     *
     */
    formid: '1268587b-70a7-464f-bde5-ab9c645077fa',

    /**
     * The user profile url.
     *
     */
    profileLink: 'https://secure.vstherapy.com/viewprofile/?user_id=',
  },
};
