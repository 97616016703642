import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';

export default class EvaluationService {
  static resource = 'widget/evaluation';
  static requestCancel = useRequestCancel();

  static get(organizationIds: Array<number>): ApiPromise {
    const evaluationResource = process.env.NODE_ENV === 'development' ? `widget/evaluation?limit=5` : this.resource;
    return HTTP.get(`${evaluationResource}`, {
      params: {
        organizations: organizationIds,
      },
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.EVALUATION),
    });
  }
}
