import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useAuthStore, useOrganizationStore } from '@/store';
import { OrganizationService, StudentService } from '@/services';
import {
  StudentInterface,
  StudentModalData,
  StudentPayload,
  StudentStateInterface,
  StudentTableInterface,
} from '@/types';

export default defineStore('StudentStore', {
  state: (): StudentStateInterface => ({
    students: [],
    error: '',
    totals: 0,
    page: 0,
    loading: false,
    showArchived: false,
    studentModalData: [],
    modalDataLoading: false,
  }),

  getters: {
    orgId: () => (useOrganizationStore().organization.id as number) || useAuthStore().organizationId,
    totalStudents: (state) => state.totals,
  },

  actions: {
    /**
     * catch errors
     * @param e
     */
    catchError(e: Error) {
      if (this.error === undefined) {
        this.error = 'error';
      }
      this.loading = this.modalDataLoading = false;
      this.doToast('There was an error loading data', 'Error', true);
      throw e;
    },

    /**
     *
     * attachStudents
     *
     * Assign students to a specific organization
     * @param payload {students: number[], orgId?: number}
     */
    async attachStudents(students: number[], orgId?: number): Promise<AxiosResponse<unknown>> {
      const organizationId = orgId || this.orgId;
      this.error = null;
      const payload = { students: students } as StudentPayload;
      const response = await OrganizationService.attachPerson(payload, organizationId, 'students').catch((error) =>
        this.catchError(error),
      );

      await this.getTotalStudents(organizationId);
      this.loading = false;
      return response;
    },

    /**
     *
     * detachStudents
     *
     * Remove students from a specific organization
     * @param payload {students: number[], orgId: number}
     */
    async detachStudents(students: number[]): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      this.error = null;
      const payload = { students: students } as StudentPayload;
      const response = await OrganizationService.detachPerson(payload, organizationId, 'students').catch((error) =>
        this.catchError(error),
      );

      await this.getTotalStudents(organizationId);
      this.loading = false;
      return response;
    },

    /**
     *
     * transferStudents
     *
     * Remove students from current organization and attach to new organization
     * @param payload {students: number[], destination: number}
     */
    async transferStudents(students: number[], destination: number): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      this.error = null;
      const payload = { students: students, destination } as StudentPayload & { destination: number };
      const response = await OrganizationService.transferPerson(payload, organizationId, 'students').catch((error) =>
        this.catchError(error),
      );

      await this.getTotalStudents(organizationId);
      this.loading = false;
      return response;
    },

    /**
     *
     * getStudents
     *
     * Retrieve all students assigned to an organzation
     *
     * @param commit
     */
    async getStudents(filter: string): Promise<StudentInterface[]> {
      const organizationId = this.orgId;
      this.loading = true;
      const response = await OrganizationService.getPerson(organizationId, 'students', filter).catch((error) =>
        this.catchError(error),
      );

      this.students = response.data?.data;
      await this.getTotalStudents(organizationId);
      this.loading = false;
      return this.students;
    },

    /**
     *
     * getStudentsForTable
     *
     * Retrieve and format students data for the organization page student table
     * @return StudentTableInterface[]
     */
    async getStudentsForTable(filter: string): Promise<StudentTableInterface[]> {
      const rawStudentsData = await this.getStudents(filter);
      return rawStudentsData.map((student) => {
        return {
          id: student.id,
          name: student.display_name as string,
          age: student.age,
          grade: student.student_information?.grade,
          disciplines: student.treatment_services_disciplines.join(', '),
          notifications: student.notifications,
          archived: !!student.archived_at,
        };
      });
    },

    /**
     * get all the students not belonging to an org paginated for adding to an org
     */
    async getAddStudentsModalData() {
      const organizationId = this.orgId;
      this.modalDataLoading = true;
      this.page++;
      const result = await OrganizationService.getPersonAvailableToAdd(organizationId, 'students', this.page).catch(
        this.catchError,
      );

      this.modalDataLoading = false;

      const paginatedStudents: StudentModalData[] = result.data?.data
        .filter((item) => {
          if (!item.archived_at) {
            return true;
          }
          return false;
        })
        .map((student) => {
          return {
            value: student.id,
            name: student.display_name as string,
          };
        });

      this.studentModalData = [...this.studentModalData, ...paginatedStudents];
    },
    async importStudent(id: number, payload: FormData): Promise<AxiosResponse<unknown>> {
      this.loading = true;
      this.error = undefined;
      const result = await StudentService.importStudentRequest(id, payload).catch((e) => {
        this.error = e.data.message;
        this.loading = false;
        return e;
      });

      await this.getTotalStudents(this.orgId);
      this.loading = false;
      return result;
    },
    async getTotalStudents(organizationId?: number) {
      const response = await OrganizationService.getTotalStudents(organizationId || this.orgId);
      this.totals = response.data.data.total_students;
      return this.totals;
    },
  },
});
