import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class DisciplineService {
  static resource = 'disciplines';

  static getDisciplines(): ApiPromise {
    return HTTP.get(`${this.resource}`);
  }
}
