import { defineStore } from 'pinia';
import Pubnub, { GetChannelMetadataResponse, MessageEvent, ObjectCustom } from 'pubnub';
import { MessageCenterServer } from '@/services';
import {
  ApiGroupChannelInterface,
  AuthUserInterface,
  ChannelMetadataSimpleInterface,
  ChannelPersonReadMetadata,
  FetchMessageData,
  MessageCenterNewChatPayload,
  MessageCenterStoreInterface,
} from '@/types';

export default defineStore('MessageCenterStore', {
  state: (): MessageCenterStoreInterface => {
    return {
      visible: false,
      loading: false,
      _pubnub: undefined,
      currentChannel: 'Channel-Barcelona',
      myGroupChannels: [],
      channelsWithoutOldMessages: {},
      subChannels: {},
      chats: {
        channels: {},
      },
      softDeletedChannels: {
        channels: {},
      },
      userId: '',
      person: undefined,
      screen: 'list-chats',
      userMetadata: null,
      error: undefined,
      channelsMetadata: [],
      recipients: [],
      invalidRecipients: false,
      peopleNames: {},
      careTeamsByChannel: [],
    };
  },

  actions: {
    async setupPubNub(userId: number, person: AuthUserInterface) {
      this._pubnub = new Pubnub({
        publishKey: process.env.VUE_APP_PUBNUB_PUBLISH_KEY as string,
        subscribeKey: process.env.VUE_APP_PUBNUB_SUBSCRIBE_KEY as string,
        userId: userId.toString(),
      });

      this.userId = userId.toString();
      this.person = person;
    },

    catchError(e: string) {
      this.error = e;
    },

    async setCurrentChannel(channel: string) {
      await this.updateMyReadMetaToTrue(channel, this.userId);

      this.currentChannel = channel;
    },

    updateReadMeta(channel: string, personId: string) {
      const channelPeople = channel.split('_')[1].split('-');

      this._pubnub?.objects
        .getChannelMetadata({
          channel: channel,
        })
        .then((meta: GetChannelMetadataResponse<ObjectCustom>) => {
          const currentCustomMeta = meta.data.custom ?? {};

          if (currentCustomMeta.read_messages) {
            const readPeopleMeta = JSON.parse(<string>currentCustomMeta.read_messages);

            readPeopleMeta.forEach((person: ChannelPersonReadMetadata, key: string) => {
              readPeopleMeta[key].read = person.person_id == personId;
            });

            currentCustomMeta.read_messages = JSON.stringify(readPeopleMeta);
          } else {
            const readPeopleMeta: Array<ChannelPersonReadMetadata> = [];

            channelPeople.forEach((person) => {
              readPeopleMeta.push({ person_id: person, read: person === personId });
            });

            currentCustomMeta.read_messages = JSON.stringify(readPeopleMeta);
          }

          this._pubnub?.objects.setChannelMetadata({
            channel: channel,
            data: {
              custom: currentCustomMeta,
            },
          });
        })
        .catch(() => {
          const currentCustomMeta = { read_messages: '' };

          if (currentCustomMeta.read_messages) {
            const readPeopleMeta: Array<ChannelPersonReadMetadata> = [];

            channelPeople.forEach((person) => {
              readPeopleMeta.push({ person_id: person, read: person === personId });
            });

            currentCustomMeta.read_messages = JSON.stringify(readPeopleMeta);
          }

          this._pubnub?.objects.setChannelMetadata({
            channel: channel,
            data: {
              custom: currentCustomMeta,
            },
          });
        });
    },

    setMyReadMessageLocallyToFalse(channel: string) {
      const channelMetadataObject = this.channelsMetadata.find(
        (channelMetadata: ChannelMetadataSimpleInterface) => channelMetadata.channel.id === channel,
      );
      const channelPeople = channel.split('_')[1].split('-');

      if (!channelMetadataObject) return;

      if (channelMetadataObject.channel.custom.read_messages) {
        const readPeopleMeta = JSON.parse(<string>channelMetadataObject.channel.custom.read_messages);

        const userData = readPeopleMeta.find(
          (data: { person_id: string; read: boolean }) => data.person_id === this.userId,
        );

        userData.read = false;

        channelMetadataObject.channel.custom.read_messages = JSON.stringify(readPeopleMeta);
      } else {
        const readPeopleMeta: Array<ChannelPersonReadMetadata> = [];

        channelPeople.forEach((person) => {
          readPeopleMeta.push({ person_id: person, read: person !== this.userId });
        });

        channelMetadataObject.channel.custom.read_messages = JSON.stringify(readPeopleMeta);
      }
    },

    async updateMyReadMetaToTrue(channel: string, personId: string) {
      const channelPeople = channel.split('_')[1].split('-');

      const response = await this._pubnub?.objects
        .getChannelMetadata({
          channel: channel,
        })
        .catch(() => {
          const currentCustomMeta = { read_messages: '' };

          if (currentCustomMeta.read_messages) {
            const readPeopleMeta: Array<ChannelPersonReadMetadata> = [];

            channelPeople.forEach((person) => {
              readPeopleMeta.push({ person_id: person, read: person === personId });
            });

            currentCustomMeta.read_messages = JSON.stringify(readPeopleMeta);
          }

          this._pubnub?.objects.setChannelMetadata({
            channel: channel,
            data: {
              custom: currentCustomMeta,
            },
          });
        });

      const meta = response?.data;

      if (!meta) return;

      const currentCustomMeta = meta.custom ?? {};

      let dataStringified = '';

      if (currentCustomMeta.read_messages) {
        const readPeopleMeta = JSON.parse(<string>currentCustomMeta.read_messages);

        const userData = readPeopleMeta.find(
          (data: { person_id: string; read: boolean }) => data.person_id == personId,
        );

        userData.read = true;

        dataStringified = JSON.stringify(readPeopleMeta);
        currentCustomMeta.read_messages = dataStringified;
      } else {
        const readPeopleMeta: Array<ChannelPersonReadMetadata> = [];

        channelPeople.forEach((person) => {
          readPeopleMeta.push({ person_id: person, read: person === personId });
        });

        dataStringified = JSON.stringify(readPeopleMeta);
        currentCustomMeta.read_messages = dataStringified;
      }

      this._pubnub?.objects.setChannelMetadata({
        channel,
        data: {
          custom: currentCustomMeta,
        },
      });

      const channelMetadataObject = this.channelsMetadata.find(
        (channelMetadata: ChannelMetadataSimpleInterface) => channelMetadata.channel.id === channel,
      );

      if (channelMetadataObject) {
        channelMetadataObject.channel.custom.read_messages = dataStringified;
      }
    },

    async createAndSetupFirstGroupChannel() {
      if (this.myGroupChannels.length === 0) {
        const response = await MessageCenterServer.createGroupChannel(this.userId).catch(this.catchError);
        const myGroupChannel = response?.data.data[0].group_channel;
        this.myGroupChannels.push(response?.data.data[0]);

        await this._pubnub?.channelGroups.addChannels({
          channels: [`private_${this.userId}`],
          channelGroup: myGroupChannel,
        });
      }
    },

    async bootProcess() {
      this.loading = true;
      await this.getMyGroupChannels();
      await this.createAndSetupFirstGroupChannel();
      await this.getSubChannels();
      this.setUpListeners();
      await this.getPeopleNamesAndCareTeamMetadata();
      await this.getChannelsMetadata();

      await this.getUserMetadata();
      await this.getMyOldMessagesChannels();

      this._pubnub?.subscribe({
        channelGroups: this.myGroupChannelsNames,
      });
      this.loading = false;
    },

    setUpListeners() {
      const listener = {
        message: async (messageEvent: MessageEvent) => {
          if (messageEvent.message.action === 'connectToChannel') {
            await this.getChannelsMetadata();
            await this.getMyOldMessagesChannel(messageEvent.message.channel);
            await this.getSubChannels();
            await this.getPeopleNamesAndCareTeamMetadata();
            this._pubnub?.subscribe({
              channels: [messageEvent.message.channel],
            });
            this.setMyReadMessageLocallyToFalse(messageEvent.message.channel);
            return;
          }

          if (messageEvent.publisher !== this.userId) {
            this.setMyReadMessageLocallyToFalse(messageEvent.channel);
          }
          if (this.visible && messageEvent.channel === this.currentChannel && this.screen === 'chat') {
            if (messageEvent.publisher !== this.userId) {
              await this.setCurrentChannel(messageEvent.channel);
            }
          }
          await this.restoreSoftDeletedChannel(messageEvent.channel);

          this.insertMessage(messageEvent);
        },
      };

      this._pubnub?.addListener(listener);
    },

    insertMessage(messageEvent: MessageEvent) {
      const messageData: FetchMessageData = {
        ...messageEvent,
        actions: {
          ['trick_typescript']: {
            ['trick_typescript']: [],
          },
        },
      };

      messageData.uuid = messageData.uuid ?? messageData.publisher;
      if (!this.chats?.channels[messageEvent.channel]) {
        return;
      }

      const channelMessages = this.chats?.channels[messageEvent.channel];
      const lastMessage = channelMessages[channelMessages.length - 1];

      if (lastMessage.timetoken !== messageData.timetoken) {
        this.chats?.channels[messageEvent.channel].push(messageData);
      }
    },

    async getRecipients(search: string) {
      this.invalidRecipients = false;

      const response = await MessageCenterServer.getRecipients(search).catch(this.catchError);
      this.recipients = response?.data.data;

      if (this.recipients.length === 0) {
        this.invalidRecipients = true;
      }

      return this.recipients;
    },

    async getMyGroupChannels() {
      const response = await MessageCenterServer.getMyGroupChannels().catch(this.catchError);
      this.myGroupChannels = response?.data.data;
      return this.myGroupChannels;
    },

    async getPeopleNamesFromSingleChannels() {
      let singleIds = [];
      for (const channelName of this.mySingleChannels) {
        const ids = channelName.substring(8).split('-');
        singleIds.push(...ids);
      }

      this.careTeamsByChannel.forEach((ct) => {
        if (ct.people_care_team_ids) {
          singleIds.push(...ct.people_care_team_ids.split(','));
        }
      });

      singleIds = Array.from(new Set(singleIds));
      const response = await MessageCenterServer.getPeopleNames(singleIds).catch(this.catchError);
      Object.assign(this.peopleNames, response?.data);
    },

    async getCareTeamByChannels() {
      const response = await MessageCenterServer.getCareTeamsByChannels(this.mySingleChannels).catch(this.catchError);
      if (response?.data) {
        this.careTeamsByChannel = response.data;
      }
    },

    async getPeopleNamesAndCareTeamMetadata() {
      await this.getCareTeamByChannels();
      await this.getPeopleNamesFromSingleChannels();
    },

    async startNewChatConversations(payload: MessageCenterNewChatPayload, message = '') {
      this.loading = true;

      const allPeopleIdsForTempChannelName = [
        ...new Set([...payload.singlePeopleIds, this.person?.id as number, ...payload.teamsParticipants]),
      ];
      const channelName = 'private_' + allPeopleIdsForTempChannelName.sort((a, b) => a - b).join('-');

      if (this.softDeletedChannels.channels[channelName] || this.chats.channels[channelName]) {
        this.restoreSoftDeletedChannel(channelName);
        await this.sendMessage(message, channelName);
        await this.setCurrentChannel(channelName);
        this.screen = 'chat';
        this.loading = false;
        return;
      }

      const allPeopleIds = [...new Set([...payload.singlePeopleIds, this.person?.id as number])];

      const sendPayload: MessageCenterNewChatPayload = {
        singlePeopleIds: allPeopleIds,
        teamsNames: [...new Set([...payload.teamsNames])],
        teamsParticipants: [...new Set([...payload.teamsParticipants])],
        teams: payload.teams,
      };

      const response = await MessageCenterServer.startNewChatConversations(sendPayload, message);
      const newChannelName = response?.data.channel_name;

      await this.getChannelsMetadata();

      await this.getSubChannels();
      await this.getPeopleNamesAndCareTeamMetadata();

      await this.getMyOldMessagesChannel(newChannelName);

      await this.setCurrentChannel(newChannelName);
      this.screen = 'chat';
      this.loading = false;
    },

    async getSubChannels() {
      if (this.myGroupChannelsNames.length === 0) return;

      for (const group_channel of this.myGroupChannelsNames) {
        const result = await this._pubnub?.channelGroups.listChannels({
          channelGroup: group_channel,
        });

        if (result?.channels) {
          this.subChannels[group_channel] = result.channels;
        }
      }
    },

    async sendMessage(message: string, channel?: string) {
      this.updateReadMeta(channel ?? this.currentChannel, this.userId);

      const messageData = <MessageEvent>{
        timetoken: '',
        message: {
          text: message,
          person_id: this.userId,
        },
      };

      const result = await this._pubnub
        ?.publish({
          message: messageData.message,
          channel: channel ?? this.currentChannel,
          storeInHistory: true,
          sendByPost: true,
        })
        .catch(this.catchError);

      if (result?.timetoken) {
        messageData.timetoken = result?.timetoken.toString();
      }

      return result ? messageData : null;
    },

    getAllChannelsByChunksOfFiveHundred() {
      const chunksOfFiveHundred = [];
      const allChannels = [...this.mySingleChannels];

      while (allChannels.length > 0) {
        const chunk = allChannels.splice(0, 500);
        chunksOfFiveHundred.push(chunk);
      }

      return chunksOfFiveHundred;
    },

    /** Fetch the old messages and create the chats structure */
    async getMyOldMessagesChannels() {
      if (this.mySingleChannels.length === 0) return;

      const chunksOfFiveHundred = this.getAllChannelsByChunksOfFiveHundred();
      const responseChannels = {};

      const channelsWithoutReturnFromPubNub = [];

      for (const chunkOfFiveHundred of chunksOfFiveHundred) {
        const response = await this._pubnub?.fetchMessages({
          channels: chunkOfFiveHundred,
          count: 25,
        });

        if (response?.channels) {
          Object.assign(responseChannels, response?.channels);

          for (const channel of chunkOfFiveHundred) {
            if (response.channels[channel] && response.channels[channel].length >= 25) continue;
            channelsWithoutReturnFromPubNub.push(channel);
          }
        }
      }

      this.chats = {
        channels: responseChannels,
      };

      await this.getMyOldMessagesChannelsFromDb(channelsWithoutReturnFromPubNub);

      this.removeSoftDeletedChannels();
    },

    async getMyOldMessagesChannelsFromDb(channels: string[]) {
      const channelsWithSomeMessages = [];
      const channelsWithoutMessages = [];
      const requestBody = [];

      for (const channelName of channels) {
        if (channelName === `private_${this.userId}`) continue;
        if (this.chats.channels[channelName]?.length > 0) {
          const channelMessages = this.chats?.channels[channelName];
          const lastMessageTimetoken = channelMessages[0].timetoken;

          requestBody.push({
            channel_id: channelName,
            end: lastMessageTimetoken,
          });
          channelsWithSomeMessages.push(channelName);
          continue;
        }

        requestBody.push({
          channel_id: channelName,
        });
        channelsWithoutMessages.push(channelName);
      }

      if (!requestBody.length) return;
      const response = await MessageCenterServer.fetchMessages(requestBody).catch(this.catchError);

      const data = response?.data.data;

      for (const channelName of channelsWithoutMessages) {
        this.chats.channels[channelName] = [...data[channelName]].reverse();
      }

      for (const channelName of channelsWithSomeMessages) {
        const currentMessages = [...this.chats.channels[channelName]];
        this.chats.channels[channelName] = [...data[channelName]].reverse();
        this.chats.channels[channelName].push(...currentMessages);
      }
    },

    async restoreSoftDeletedChannel(channel: string) {
      if (!this.softDeletedChannels.channels[channel]) {
        return;
      }

      this.moveChatChannelToSoftDeleteChannel(channel, true);

      await this.removeSoftDeletedChannels([channel]);
    },

    moveChatChannelToSoftDeleteChannel(channel: string, reverse = false) {
      if (reverse) {
        this.chats.channels[channel] = this.softDeletedChannels.channels[channel];
        delete this.softDeletedChannels.channels[channel];
        return;
      }

      this.softDeletedChannels.channels[channel] = this.chats.channels[channel];
      delete this.chats.channels[channel];
    },

    async removeSoftDeletedChannels(channels: string[] = []) {
      const channelsToRemoveSoftDelete = channels;
      const userSoftDeletedChannels = this.getUserSoftDeletedChannels();

      if (channels.length === 0) {
        for (const softDeleteChannel of userSoftDeletedChannels) {
          const [channelName, timetoken] = softDeleteChannel.split('T');
          if (this.getChannelLastMessageTimetoken(channelName) === timetoken) {
            this.moveChatChannelToSoftDeleteChannel(channelName);

            continue;
          }

          channelsToRemoveSoftDelete.push(channelName);
        }
      }

      const haveNameToRemove = (channel: string) => {
        for (const channelToRemoveSoftDelete of channelsToRemoveSoftDelete) {
          if (channel.includes(channelToRemoveSoftDelete)) {
            return false;
          }
        }
        return true;
      };

      const channelsThatRemainDeleted = userSoftDeletedChannels.filter((cn: string) => haveNameToRemove(cn));

      this.setUserSoftDeleteMetadata(channelsThatRemainDeleted);
    },

    async getUserMetadata() {
      try {
        const response = await this._pubnub?.objects.getUUIDMetadata({
          include: {
            customFields: true,
          },
        });

        this.userMetadata = response?.data;
      } catch (status) {
        this.error = 'No user data' + status;
      }
    },

    getUserSoftDeletedChannels(): string[] {
      if (this.userMetadata?.custom?.soft_deleted_channels) {
        return JSON.parse(this.userMetadata.custom.soft_deleted_channels);
      }
      return [];
    },

    async setUserSoftDeleteMetadata(metadata: string[]) {
      try {
        await this._pubnub?.objects.setUUIDMetadata({
          data: {
            custom: {
              soft_deleted_channels: JSON.stringify(metadata),
            },
          },
        });

        await this.getUserMetadata();
      } catch (status) {
        this.error = 'Try to set user data ' + status;
      }
    },

    async removeChat(channel: string) {
      if (this.userMetadata?.custom?.soft_deleted_channels) {
        const softDeletedChannels = this.getUserSoftDeletedChannels();
        softDeletedChannels.push(channel + 'T' + this.getChannelLastMessageTimetoken(channel));
        await this.setUserSoftDeleteMetadata(softDeletedChannels);
        this.moveChatChannelToSoftDeleteChannel(channel);
        return;
      }

      await this.setUserSoftDeleteMetadata([channel + 'T' + this.getChannelLastMessageTimetoken(channel)]);
      this.moveChatChannelToSoftDeleteChannel(channel);
    },

    getChannelLastMessageTimetoken(channel: string) {
      const alias = this.chats.channels[channel];

      if (alias) {
        const lastMessage = alias[alias.length - 1];
        return `${lastMessage.timetoken}` ?? '0';
      }

      return '0';
    },

    getGroupChannelFromChannel(channel: string) {
      const allGroupChannels = Object.keys(this.subChannels);

      for (const groupChannel of allGroupChannels) {
        if (this.subChannels[groupChannel].includes(channel)) {
          return groupChannel;
        }
      }

      return null;
    },

    async getChannelsMetadata() {
      const channelsMetadata = [];

      let prev = '';
      let fetchedChannels = 0;
      let totalRecords = 0;

      do {
        const response = await this._pubnub?.objects.getMemberships({
          include: {
            customChannelFields: true,
            totalCount: true,
          },
          page: {
            prev,
          },
        });

        prev = response?.next as string;

        const returnedData = response?.data as [];
        totalRecords = response?.totalCount as number;
        fetchedChannels += returnedData.length;

        channelsMetadata.push(returnedData);
      } while (fetchedChannels < totalRecords);

      this.channelsMetadata = channelsMetadata.flat().reverse();
    },

    getLastSevenDaysTimeToken() {
      const nowDate = new Date();
      nowDate.setDate(nowDate.getDate() - 7);
      return (nowDate.getTime() * 10000).toString();
    },

    async getMyOldMessagesChannel(channel: string) {
      if (this.mySingleChannels.length === 0) return;

      const responseChannels = {};

      const response = await this._pubnub?.fetchMessages({
        channels: [channel],
        count: 25,
      });

      if (response?.channels) {
        Object.assign(responseChannels, response?.channels);
        Object.assign(responseChannels, this.chats.channels);
      }

      this.chats = {
        channels: responseChannels,
      };
    },

    async loadMoreMessages(channel: string) {
      if (this.loading || this.channelsWithoutOldMessages[channel] === true) return;

      this.loading = true;

      if (this.channelsWithoutOldMessages[channel] === undefined) {
        this.channelsWithoutOldMessages[channel] = 'pubnub';
      }

      if (this.channelsWithoutOldMessages[channel] === 'pubnub') {
        await this.loadMoreMessagesFromPubnub(channel);
      }

      if (this.channelsWithoutOldMessages[channel] === 'db') {
        await this.loadMoreMessagesFromDb(channel);
      }

      this.loading = false;
    },

    async loadMoreMessagesFromPubnub(channel: string) {
      const channelMessages = this.chats?.channels[channel];
      const lastMessageTimetoken = channelMessages[0].timetoken;

      this.loading = true;

      const response = await this._pubnub?.fetchMessages({
        channels: [channel],
        start: lastMessageTimetoken,
      });

      if (response?.channels && response?.channels[channel]) {
        channelMessages.unshift(...response.channels[channel]);

        if (response.channels[channel].length < 100) {
          this.channelsWithoutOldMessages[channel] = 'db';
        }
      }

      if (!response?.channels[channel]) {
        this.channelsWithoutOldMessages[channel] = 'db';
      }

      this.loading = false;
    },

    async loadMoreMessagesFromDb(channel: string) {
      const channelMessages = this.chats?.channels[channel];
      const lastMessageTimetoken = channelMessages[0].timetoken;

      this.loading = true;

      const response = await MessageCenterServer.fetchMessages([
        { channel_id: channel, end: lastMessageTimetoken.toString() },
      ]).catch(this.catchError);
      const data = response?.data.data;
      if (data[channel]) {
        const messages = [...data[channel]].reverse();

        channelMessages.unshift(...messages);

        if (messages.length < 100) {
          this.channelsWithoutOldMessages[channel] = true;
        }
      } else {
        this.channelsWithoutOldMessages[channel] = true;
      }

      this.loading = false;
    },

    getChannelChannelData(channel: string) {
      const channelNameCustom = this.channelsMetadata.find(
        (channelObject: { channel: { id: string } }) => channelObject.channel.id === channel,
      );

      return channelNameCustom?.channel;
    },
  },

  getters: {
    mySingleChannels: (state) => {
      const singleChannels = [];
      for (const groupChannel of Object.keys(state.subChannels)) {
        singleChannels.push(...state.subChannels[groupChannel]);
      }
      return singleChannels;
    },
    myGroupChannelsNames: (state) => state.myGroupChannels.map((c: ApiGroupChannelInterface) => c.group_channel),
    messages: (state) => {
      const channelMessages = state.chats?.channels[state.currentChannel];
      if (!channelMessages) return [];
      const clonedMessages = [...(channelMessages as [])]?.reverse();
      return clonedMessages ?? [];
    },
    currentChannelMetadata: (state) => {
      const channelNameCustom = state.channelsMetadata.find(
        (channelObject: { channel: { id: string } }) => channelObject.channel.id === state.currentChannel,
      );

      return channelNameCustom?.channel;
    },
    haveUnreadMessage: (state) => {
      for (const metadata of state.channelsMetadata) {
        const readMessages = metadata.channel?.custom?.read_messages;

        if (!readMessages) {
          continue;
        }

        const jsonData = JSON.parse(readMessages);

        const userData = jsonData.find((data: { person_id: string; read: boolean }) => data.person_id == state.userId);
        if (!userData.read) {
          return true;
        }
      }
      return false;
    },
    haveUnreadMessageChannel: (state) => {
      return (channelName: string) => {
        for (const metadata of state.channelsMetadata) {
          const channel = metadata.channel.id as string;

          if (channel !== channelName) {
            continue;
          }

          const readMessages = metadata.channel?.custom?.read_messages;
          if (!readMessages) {
            return false;
          }

          const jsonData = JSON.parse(readMessages);

          const userData = jsonData.find(
            (data: { person_id: string; read: boolean }) => data.person_id == state.userId,
          );

          return !userData.read;
        }
        return false;
      };
    },
  },
});
