import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

type RSMService = {
  dowloadRsmFile: (path: string) => ApiPromise;
};

const resource = 'rsm';

export const RSMService: RSMService = {
  dowloadRsmFile: (path: string) => {
    return HTTP.get(`${resource}/download-rsm-file`, {
      params: {
        path,
      },
    });
  },
};
