export enum REFERRAL_STATUS {
  NEW = 'New',
  ASSIGNED = 'Assigned',
  SCHEDULED = 'Scheduled',
  CLOSED = 'Closed',
}

export enum REFERRAL_CONCERN {
  ATTENDANCE = 'Attendance/Truancy',
  ANXIETY = 'Anxiety',
  BEHAVIOR = 'Behavior',
  BULLYING = 'Bullying',
  GRIEF = 'Death/Grief',
  DIVORCE = 'Divorce',
  SELF_HARM = 'Self Harm',
  THREAT_TO_OTHERS = 'Threat to Others',
  OTHER = 'Other',
}

export enum REFERRAL_URGENCY {
  END_OF_DAY = 'By end of day',
  END_OF_WEEK = 'By end of week',
  ADD_TO_CASELOAD = 'Add student to caseload for ongoing sessions',
  ADD_TO_GROUP = 'Add to existing group session',
}

export enum REFERRAL_SOURCE {
  PARENT = 'Parent',
  PEER_STUDENT = 'Peer Student',
  SELF = 'Self',
  TEACHER = 'Teacher/Admin',
}

export enum REFERRAL_TYPES {
  MTSS = 'MTSS Referral',
  INITIAL = 'Initial Evaluation',
  TRANSFER = 'Student Transfer',
  REASSIGNMENT = 'Reassignment',
}
