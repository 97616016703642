export const formatPhone = (value: string) => {
  let phone = value.replace(/\D/g, '');

  if (phone.length > 3 && phone.length <= 6) {
    phone = `${value.slice(0, 3)}-${phone.slice(3)}`;
  } else if (phone.length > 6) {
    phone = `${phone.slice(0, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  }

  return phone;
};

export const validatePhone = (value: string) => {
  const match = value.match(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/);

  if (match !== null) {
    return true;
  }

  return false;
};
