import { useDateTime } from '@/composables';
import { useRequestCancel } from '@/composables/useRequestCancel';
import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { REQUEST } from '@/enum';

export default class ReportService {
  static resource = 'reports';
  static requestCancel = useRequestCancel();

  static getReportOrganizationFilter(service_start_at?: Date, service_end_at?: Date): ApiPromise {
    const start_at = useDateTime().formatDateForApi(service_start_at);
    const end_at = useDateTime().formatDateForApi(service_end_at);
    return HTTP.get(`${this.resource}/filter/organizations`, {
      params: {
        start_at,
        end_at,
      },
    });
  }

  static getReportServiceProviderFilter(
    organizationsId?: Array<number>,
    startAt?: Date | string,
    endAt?: Date | string,
  ): ApiPromise {
    const start_at = useDateTime().formatDateForApi(startAt);
    const end_at = useDateTime().formatDateForApi(endAt);

    if (organizationsId && organizationsId.length > 0) {
      return HTTP.get(`${this.resource}/filter/service-providers`, {
        params: { organizations: organizationsId, start_at, end_at },
      });
    } else {
      return HTTP.get(`${this.resource}/filter/service-providers`, {
        params: { start_at, end_at },
      });
    }
  }

  static getReportAttendeeFilter(params: {
    organizationIds?: number[] | undefined;
    clinicianIds?: number[] | undefined;
    startAt?: Date | undefined;
    endAt?: Date | undefined;
    attendeeListId?: string;
  }): ApiPromise {
    const dateUtils = useDateTime();
    let resquestParams: {
      start_at: string;
      end_at: string;
      clinicians?: number[] | undefined;
      organizations?: number[] | undefined;
      attendee_list_id?: string;
    } = {
      start_at: dateUtils.formatDateForApi(params.startAt),
      end_at: dateUtils.formatDateForApi(params.endAt),
      clinicians: params.clinicianIds,
    };

    if (params.organizationIds) {
      resquestParams = { ...resquestParams, organizations: params.organizationIds };
    }
    if (params.attendeeListId) {
      resquestParams = { ...resquestParams, attendee_list_id: params.attendeeListId };
    }

    return HTTP.get(`${this.resource}/filter/attendees`, {
      params: resquestParams,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REPORT_ATTENDEES),
    });
  }
}
