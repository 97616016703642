import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class TimezonesService {
  static resource = 'timezones';
  static resource_tz = 'timezone';

  static getTimezones(): ApiPromise {
    return HTTP.get(`${this.resource}`);
  }

  static getTimezone(id: string | number): ApiPromise {
    return HTTP.get(`${this.resource_tz}/${id}`);
  }
}
