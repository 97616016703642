import { helpScoutConfigs } from '@/config/helpscout';

type userObject = {
  first_name?: string;
  email?: string;
  id?: number;
  timezone_id?: number;
};

/*eslint-disable */
declare global {
  interface Window {
    Beacon: any;
    HS: any;
    attachEvent: any;
  }
}

/*eslint-enable */
let publicBeacon = true;

const profileLink: string = publicBeacon ? helpScoutConfigs.public.profileLink : helpScoutConfigs.private.profileLink;

const formid: string = publicBeacon ? helpScoutConfigs.public.formid : helpScoutConfigs.private.formid;

const hsIdentify = (user: userObject | undefined) => {
  if (publicBeacon) {
    const userObject = {
      name: '',
      email: '',
      'user id': '',
      username: '',
      timezone: '',
      'profile link': '',
    };
    window.Beacon('identify', userObject);
    return;
  }

  const userObject = {
    name: user?.first_name,
    email: user?.email,
    'user id': user?.id,
    username: user?.first_name,
    timezone: user?.timezone_id,
    'profile link': `${profileLink}${user?.id}`,
  };

  window.Beacon('identify', userObject);
};

const config = (first_name: string | undefined) => {
  return {
    display: {
      style: publicBeacon ? 'icon' : 'manual',
      iconImage: 'question',
    },
    color: '#00a79c',
    messaging: {
      contactForm: {
        showName: !first_name,
      },
      chatEnabled: true,
    },
    poweredBy: false,
    attachment: true,
    labels: {
      suggestedForYou: 'Common topics',
    },
  };
};

export const useBeacon = (user: userObject | undefined) => {
  publicBeacon = !user;

  /*eslint-disable */
  if (typeof window.HS === 'undefined') {
    //@ts-ignore
    !(function (e, t, n) {
      function a() {
        var e = t.getElementsByTagName('script')[0],
          n = t.createElement('script');
        (n.type = 'text/javascript'),
          (n.async = !0),
          (n.src = 'https://beacon-v2.helpscout.net'),
          //@ts-ignore
          e.parentNode.insertBefore(n, e);
      }
      if (
        ((e.Beacon = n =
          //@ts-ignore
          function (t, n, a) {
            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
          }),
        (n.readyQueue = []),
        'complete' === t.readyState)
      ) {
        return a();
      }
      e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
    })(window, document, window.Beacon || (() => {}));

    window.Beacon('config', config(user?.first_name));
    window.Beacon('init', formid);

    hsIdentify(user);
  }
  /*eslint-enable */
};
