import { defineStore } from 'pinia';
import { TenantIntegrationService } from '@/services';
import { OAuthFormIntegration } from '@/types/OAuth';
import { extractErrorMessage } from '@/utils';

export const useOAuthStore = defineStore('oauthStore', {
  state: () => ({
    roles: [],
    users: [],
    integration: {} as OAuthFormIntegration,
    loading: false,
    error: null as string | null,
  }),

  actions: {
    async getRoles(tenantId: number) {
      this.loading = true;
      try {
        const response = await TenantIntegrationService.getRoles(tenantId);
        this.roles = response.data.data;
      } catch (err) {
        this.error = 'Failed to fetch available roles';
      } finally {
        this.loading = false;
      }
    },

    async getUsers(tenantId: number) {
      this.loading = true;
      try {
        const response = await TenantIntegrationService.getUsers(tenantId);
        this.users = response.data.data;
      } catch (err) {
        this.error = 'Failed to fetch available users';
      } finally {
        this.loading = false;
      }
    },

    async saveOAuthIntegration(tenantId: number, payload: FormData) {
      this.loading = true;
      this.error = null;
      try {
        const response = await TenantIntegrationService.saveIntegration(tenantId, payload);
        this.integration = response.data;
        // eslint-disable-next-line
      } catch (err: any) {
        this.error = extractErrorMessage(err);
      } finally {
        this.loading = false;
      }
    },

    async updateOAuthIntegration(tenantId: number, integrationId: number, payload: FormData) {
      this.loading = true;
      this.error = null;
      try {
        const response = await TenantIntegrationService.updateIntegration(tenantId, integrationId, payload);
        this.integration = response.data;
        // eslint-disable-next-line
      } catch (err: any) {
        this.error = extractErrorMessage(err);
      } finally {
        this.loading = false;
      }
    },

    async getOAuthIntegration(tenantId: number, provider: string) {
      this.loading = true;
      this.error = null;

      try {
        const { data } = await TenantIntegrationService.getIntegration(tenantId);
        this.integration = data.data.find(
          // eslint-disable-next-line
          (integration: any) => integration.integration_provider_type.slug === provider,
        );
        // eslint-disable-next-line
      } catch (err: any) {
        this.error = extractErrorMessage(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
