import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class ParentService {
  static resource = 'parents';

  static getStudentsForParent(parentId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${parentId}/students`);
  }

  static getAddStudentsModalData(parentId: number): ApiPromise {
    return HTTP.get(`${this.resource}/${parentId}/students/available-to-add`);
  }

  static attachStudentsToParent(students: number[], parentId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${parentId}/attach-students`, { students });
  }

  static detachStudentsFromParent(students: number[], parentId: number): ApiPromise {
    return HTTP.post(`${this.resource}/${parentId}/detach-students`, { students });
  }
}
