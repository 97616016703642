import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class DocumentService {
  static getDocuments(id: number): ApiPromise {
    return HTTP.get(`person/${id}/documents`);
  }

  static deleteDocument(id: number): ApiPromise {
    return HTTP.delete(`documents/${id}`);
  }

  static uploadDocument(id: number, document: FormData): ApiPromise {
    return HTTP.post(`person/${id}/documents`, document);
  }

  static getDocument(id: number): ApiPromise {
    return HTTP.get(`documents/${id}/download`, { responseType: 'blob' });
  }
}
