import { ApiPromise, ForgotPasswordInterface, ResetPasswordInterface } from '@/types';

import { CheckLinkResetPasswordInterface, LoginInterface } from '@/types/Auth';
import { HTTP } from '@/utils';

export default class AuthService {
  static url = 'auth';

  static login(payload: LoginInterface): ApiPromise {
    return HTTP.post(`${this.url}/login`, payload);
  }

  static logout(): ApiPromise {
    return HTTP.post(`${this.url}/logout`);
  }

  static forgotPassword(payload: ForgotPasswordInterface): ApiPromise {
    return HTTP.post(`${this.url}/forgot-password`, payload);
  }

  static checkLinkResetPassword(payload: CheckLinkResetPasswordInterface): ApiPromise {
    return HTTP.post(`${this.url}/verify-token-expired`, payload);
  }

  static resetPassword(payload: ResetPasswordInterface): ApiPromise {
    return HTTP.post(`${this.url}/reset-password`, payload);
  }

  static getConnections(): ApiPromise {
    return HTTP.get(`messages/connections`);
  }

  static createPassword(payload: ResetPasswordInterface): ApiPromise {
    return HTTP.post(`${this.url}/activate-account`, payload);
  }
}
