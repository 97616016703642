import { ApiPromise, EventReportFilterInterface } from '@/types';
import { HTTP } from '@/utils';
import ReportService from './ReportService';
import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';

export default class EventReportService extends ReportService {
  static resource = 'events';
  static requestCancel = useRequestCancel();

  static getEventReport(payload: EventReportFilterInterface): ApiPromise {
    return HTTP.get(`${ReportService.resource}/${this.resource}`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.EVENT_REPORT),
    });
  }

  static getEventsCount(payload: EventReportFilterInterface): ApiPromise {
    return HTTP.get(`${ReportService.resource}/${this.resource}/count`, {
      params: payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.EVENT_REPORT),
    });
  }

  static downloadEventReport(payload: EventReportFilterInterface, exportType: string): ApiPromise {
    return HTTP.get(`${ReportService.resource}/${this.resource}/download/${exportType}`, {
      params: payload,
      responseType: 'blob',
    });
  }
}
