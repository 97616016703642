import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { REQUEST } from '@/enum';
import { useRequestCancel } from '@/composables/useRequestCancel';

interface DateFilter {
  start_at: string;
  end_at: string;
}

const joinFilters = (filters: DateFilter, organizations: number[]) => {
  return (
    `${Object.keys(filters)
      .map((item) => `${item}=${filters[item as keyof DateFilter]}`)
      .join('&')}` +
    (organizations.length ? `&${organizations.map((organization) => `organizations[]=${organization}`).join('&')}` : '')
  );
};

export default class UserService {
  static resource = 'widget/attendance';
  static requestCancel = useRequestCancel();

  static getAll(filters: DateFilter, organizations: number[]): ApiPromise {
    return HTTP.get(`${this.resource}/all?${joinFilters(filters, organizations)}`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.ALL_ATTENDANCE),
    });
  }

  static getResults(attendee_id: number, filters: DateFilter, organizations: number[]): ApiPromise {
    return HTTP.get(`${this.resource}/results?attendee_id=${attendee_id}&${joinFilters(filters, organizations)}`);
  }

  static getLowAttendance(threshold: number, filters: DateFilter, organizations: number[]): ApiPromise {
    return HTTP.get(`${this.resource}/low-attendance?threshold=${threshold}&${joinFilters(filters, organizations)}`);
  }

  static getLowAttendanceDateRangePreference(personId: number): ApiPromise {
    return HTTP.get(`/people/${personId}/preferences?name=low_attendance_date_range`);
  }
}
