const progressNotesTemplate = `
<p><strong>Header:</strong></p>
<p><strong>Diagnosis:</strong></p>
<p><strong>Patient Presentation:</strong></p>
<p><strong>Symptom Description and Subjective Report:</strong></p>
<p><strong>Relevant Content:</strong></p>
<p><strong>Interventions Used:</strong></p>
<p><strong>Treatment Plan Progress:</strong></p>
<p><strong>Safety:</strong></p>
`;

const soapTemplate = `
<p><strong>Subjective Data:</strong></p>
<p><strong>Objective Data:</strong></p>
<p><strong>Assessment Information:</strong></p>
<p><strong>Plan Details:</strong></p>
`;

export default { progressNotesTemplate, soapTemplate };
