import { RRule, Weekday, WeekdayStr } from 'rrule';
import { isRef, Ref, ref, unref, watchEffect } from 'vue';
import { RRULE_WEEKDAY } from '@/enum';
import { DetailedEventInterface, RecurrenceDropdownOption } from '@/types';

/**
 * useGetInitialRRuleObject
 * Hook to retrieve an RRule ref based upon a range or date value passed in. If no date or string of
 * dates is passed in, an RRule based upon the current date and time will be returned.
 * @param date {string | Date} Can be a single date or a range object
 * @param update {Boolean} Is this updating an existing calendar event
 */
export function useGetInitialRRuleObject(
  event: DetailedEventInterface,
  update?: boolean,
): { initialRRule: Ref<Partial<RRule>> } {
  const startRef: Ref<Date> = ref(new Date());
  let initialRRule: Ref<Partial<RRule>>;

  if (update) {
    // If this is an event update, use the existing RRule and return it

    if (event.rrule) {
      initialRRule = ref(RRule.fromString(event.rrule));
      return { initialRRule };
    }

    initialRRule = ref(
      new RRule({
        dtstart: new Date(event.start_at),
        freq: RRule.WEEKLY,
        count: 1,
        until: new Date(event.end_at),
      }),
    );

    return { initialRRule };
  }

  // If this is a new event, use the date selected passed as the date parameter
  // Put the time of the new event based on the current time regardless of the event day
  const currentDate = new Date();
  const newEventDate = new Date();
  newEventDate.setHours(currentDate.getHours());
  newEventDate.setMinutes(currentDate.getMinutes());
  startRef.value = newEventDate;

  const minutes = (startRef.value as Date).getMinutes();
  // Set the minutes to be the next soonest 15 minute window
  (startRef.value as Date).setMinutes(minutes + (15 - (minutes % 15)));
  const start = new Date(
    // RRule recommends using UTC dates
    Date.UTC(
      startRef.value.getUTCFullYear(),
      startRef.value.getUTCMonth(),
      startRef.value.getUTCDate(),
      startRef.value.getUTCHours(),
      startRef.value.getUTCMinutes(),
      0,
      0,
    ),
  );
  // Add 30 minutes to the end date for a default 30 minute meeting
  const minutesAddThirty = startRef.value.getUTCMinutes() + 30;
  const end = new Date(
    Date.UTC(
      startRef.value.getUTCFullYear(),
      startRef.value.getUTCMonth(),
      startRef.value.getUTCDate(),
      startRef.value.getUTCHours(),
      minutesAddThirty,
      0,
      0,
    ),
  );

  initialRRule = ref<Partial<RRule>>(
    new RRule({
      dtstart: start,
      freq: RRule.WEEKLY,
      count: 1,
      until: end,
    }),
  );

  return { initialRRule };
}

/**
 * useGetValuesForRecurrenceDropdwon
 * Hook for updating recurring dropdown values anytime the provided rrules dtstart or until
 * properties change. Anytime the start or end date is changed, the recurring dropdown options must
 * be changed as well.
 *
 * Note: if the incomingRRule is not a ref, incomingRRule will not be watched and the function will
 * only be called one time.
 *
 * @param incomingRRule {Ref<RRule> | RRule} Initial RRule to make updates based upon
 */
export function useGetValuesForRecurrenceDropdown(
  incomingRRule: Ref<RRule> | RRule,
  update: boolean,
): {
  recurrenceOptions: Ref<RecurrenceDropdownOption[]>;
  initialSelectedOption: RecurrenceDropdownOption;
} {
  const recurrenceOptions = ref<RecurrenceDropdownOption[]>([]);
  const initialSelectedOption = ref<RecurrenceDropdownOption>();

  const updateRecurrenceValues = () => {
    /**
     * originalRRule
     * The watched RRule value that is used as the template for recurrence rrule values
     */
    const originalRRule: RRule = isRef(incomingRRule) ? unref(incomingRRule) : incomingRRule;

    /**
     * weekNumberStrings
     * Array of possible week number strings to use for readable dropdown values
     */
    const weekNumberStrings: string[] = ['1st', '2nd', '3rd', '4th', '5th'];

    /**
     * dayOfWeekNumber
     * Gets the day of the week for the currently selected event start date
     * Must be in range of 0(monday)- 6(sunday)
     */
    let day = 0;
    if (originalRRule?.options?.dtstart?.getUTCDay() - 1 < 0) {
      day = 6;
    } else {
      day = originalRRule?.options?.dtstart?.getUTCDay() - 1;
    }
    const dayOfWeekNumber: number = day;

    /**
     * rRuleDay
     * String value of abbreviated day of week. Used to access the rRuleWeekday
     */
    const rRuleDay: WeekdayStr = RRULE_WEEKDAY[dayOfWeekNumber]?.toUpperCase() as WeekdayStr;

    /**
     * rRuleWeekday
     * RRule compliant weekday property to use as the byweekday property in the rrule constructor
     */
    const rRuleWeekday: Weekday[] | number[] = [Weekday.fromStr(rRuleDay)] as Weekday[];

    /**
     * incomingWeekdays
     * The weekdays currently selected by the user to hold the meeting. Will not be stored if we do
     * not pass them here.
     */
    const incomingWeekdays: number[] = originalRRule?.options?.byweekday || rRuleWeekday;

    /**
     * actualWeekNumber
     * Bitwise OR function to get the weeknumber based upon current date set in the event modal
     */
    const actualWeekNumber: number = 0 | (originalRRule?.options?.dtstart?.getDate() / 7);

    /**
     * weekdayNthOffset
     * Offset value used by RRule monthly dropdown selection
     */
    const weekdayNthOffset: number = actualWeekNumber + 1;

    /**
     * weekNumberString
     * Human readable string passed to the monthly dropdown option
     */
    const weekNumberString = weekNumberStrings[actualWeekNumber];

    /**
     * dayStringForLabel
     * Human readable day string used in the weekly and monthly dropdown labels
     */
    const dayStringForLabel: string = originalRRule?.options?.dtstart.toLocaleString('default', { weekday: 'long' });

    /**
     * monthWithDayStringForLabel
     * Human readable day string used in the annually dropdwon selector
     */
    const monthWithDayStringForLabel: string = originalRRule?.options?.dtstart.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
    });

    /**
     * Recurrence dropdown options
     * Each references the original RRule but adds specific options create dynamic RRule event lists
     */
    const noRecurrence = {
      id: 0,
      value: new RRule({
        dtstart: originalRRule.options.dtstart,
        until: originalRRule.options.until,
        freq: RRule.WEEKLY,
        byweekday: null,
        count: 1,
      }),
      label: 'Does not repeat',
    };
    const everyday = {
      id: 1,
      value: new RRule({
        dtstart: originalRRule.options.dtstart,
        until: originalRRule.options.until,
        freq: RRule.DAILY,
        byweekday: null,
        count: null,
      }),
      label: 'Daily',
    };
    const weekly = {
      id: 2,
      value: new RRule({
        dtstart: originalRRule.options.dtstart,
        until: originalRRule.options.until,
        freq: RRule.WEEKLY,
        byweekday: [dayOfWeekNumber],
        count: null,
      }),
      label: `Weekly on ${dayStringForLabel}`,
    };
    const monthly = {
      id: 3,
      value: new RRule({
        dtstart: originalRRule.options.dtstart,
        until: originalRRule.options.until,
        freq: RRule.MONTHLY,
        byweekday: rRuleWeekday[0].nth(weekdayNthOffset),
        count: null,
      }),
      label: `Monthly on the ${weekNumberString} ${dayStringForLabel}`,
    };
    const yearly = {
      id: 4,
      value: new RRule({
        dtstart: originalRRule.options.dtstart,
        until: originalRRule.options.until,
        freq: RRule.YEARLY,
        byweekday: null,
        count: null,
      }),
      label: `Annually on ${monthWithDayStringForLabel}`,
    };
    const custom = {
      id: 5,
      value: new RRule({
        dtstart: originalRRule.options.dtstart,
        until: originalRRule.options.until,
        freq: originalRRule.options.freq,
        byweekday: incomingWeekdays,
        interval: originalRRule.options.interval,
        count: null,
      }),
      label: 'Custom Recurrence',
    };
    recurrenceOptions.value = [noRecurrence, everyday, weekly, monthly, yearly, custom];
    initialSelectedOption.value = recurrenceOptions.value[0] as RecurrenceDropdownOption;
  };

  if (isRef(incomingRRule)) {
    // Watches the incomingRRule ref for any changes, updates recurrenceValues on change
    watchEffect(updateRecurrenceValues);
  } else {
    // Does not watch incomingRRule for changes and invokes the updateRecurrenceValues only once
    updateRecurrenceValues();
  }

  // When it is updating an existing event, it needs to figure out if the original calendar event
  // was created with one of the recurrence options selected. This loops through the possible options
  // and even checks for a custom recurrence configuration.
  if (update) {
    const unrefIncomingRRule = unref(incomingRRule);
    for (const option of recurrenceOptions.value) {
      // if there is only one event generated from the RRule, it knows that the recurrence dropdown
      // was not set when the event was created.
      if (unrefIncomingRRule.count() < 2) {
        initialSelectedOption.value = recurrenceOptions.value[0] as RecurrenceDropdownOption;
        break;
      }

      // if the count of the events generated by the Rrule is more than 1,
      // the event is certainly a recurring event. here it checks the toText()
      // value(string) of the rrule against one of the recurring dropdown options
      if ((option.value as RRule).toText() == unrefIncomingRRule.toText()) {
        initialSelectedOption.value = option as RecurrenceDropdownOption;
        break;

        // If the toText didn't match, it checks individual properties of the RRule
        // against eachother. This catches any rrules that don't match the text test.
      } else if (unrefIncomingRRule.options.interval > 1) {
        if (unrefIncomingRRule.options.interval == option.value?.options?.interval) {
          if (option.value?.options?.freq == unrefIncomingRRule.options?.freq) {
            initialSelectedOption.value = option as RecurrenceDropdownOption;
            break;
          }
        }
        // If neither of the conditions match for recurring, we check for the pesky WEEKLY option
        // with some more precise targetting.
      } else if (unrefIncomingRRule.options.freq === RRule.WEEKLY) {
        if (unrefIncomingRRule.options.freq == option.value?.options?.freq) {
          if (!option.value?.options?.count) {
            initialSelectedOption.value = option as RecurrenceDropdownOption;
            break;
          }
        }
        // If the rrule has made it to this point, it either doesn't have a well-formed RRule,
        // or it is an annual rrule.
      } else if (unrefIncomingRRule.options.freq === RRule.YEARLY) {
        if (unrefIncomingRRule.options.freq == option.value?.options?.freq) {
          if (!option.value?.options?.count) {
            initialSelectedOption.value = option as RecurrenceDropdownOption;
            break;
          }
        }
      }
    }
  } else {
    // if it doesn't match anything, set it to the No Recurrence value
    initialSelectedOption.value = recurrenceOptions.value[0] as RecurrenceDropdownOption;
  }

  return { recurrenceOptions, initialSelectedOption: initialSelectedOption.value as RecurrenceDropdownOption };
}
