import { computed, Ref } from 'vue';
import { useProgressReportStore } from '@/store';
import { TreatmentPlanServiceStudentInterface } from '@/types';

export function useProgressReportDropdown(goalId: Ref<number>, type: 'intensity' | 'intensity_type' | 'quantity') {
  const progressReportStore = useProgressReportStore();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const progressReportDataTreatments = computed(() => progressReportStore.progress.data.treatment_services);

  const onChange = (event: Event & { value: number }) => {
    progressReportDataTreatments.value.forEach((service: TreatmentPlanServiceStudentInterface, serviceKey: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (service.goals.length) {
        const goalIndex = service.goals?.findIndex((goal) => goal.id === goalId.value);
        progressReportDataTreatments.value[serviceKey].goals[goalIndex][type] = event.value;
      }
    });
  };

  return { onChange };
}
