import { LAYOUTS, PERMISSIONS } from '@/enum';

const Quickbooks = () =>
  import(/* webpackChunkName: "tenant.quickbooks" */ '@/views/Admin/Tenants/components/Quickbooks');
const FeatureFlags = () =>
  import(/* webpackChunkName: "tenant.feature_flags" */ '@/views/Admin/Tenants/components/FeatureFlags');
const QuickbooksRedirect = () =>
  import(
    /* webpackChunkName: "tenant.quickbooks.redirect" */ '@/views/Admin/Tenants/components/Quickbooks/QuickbooksRedirect.vue'
  );
const Tenants = () => import(/* webpackChunkName: "tenants" */ '@/views/Admin/Tenants');
const TenantList = () => import(/* webpackChunkName: "tenant_list" */ '@/views/Admin/Tenants/components/TenantList');
const Tenant = () => import(/* webpackChunkName: "tenant" */ '@/views/Admin/Tenants/components/Tenant');
const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/Admin');
const Administrators = () => import(/* webpackChunkName: "administrators" */ '@/views/Admin/Administrators');
const TenantProfile = () => import(/* webpackChunkName: "tenant.profile" */ '@/views/Admin/Tenants/components/Profile');
const AdminList = () =>
  import(/* webpackChunkName: "administrators.list" */ '@/views/Admin/Administrators/components/UserList');
const AdminUser = () => import(/* webpackChunkName: "users.user" */ '@/views/Users/components/User');
const AdminUserProfile = () => import(/* webpackChunkName: "users.profile" */ '@/views/Users/components/UserProfile');
const Integrations = () =>
  import(/* webpackChunkName: "integrations" */ '@/views/Admin/Tenants/components/Integrations');

export default [
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'tenants',
        component: Tenants,
        children: [
          {
            path: '',
            name: 'tenantList',
            component: TenantList,
          },
          {
            path: '/admin/tenant/:id',
            name: 'tenant',
            component: Tenant,
            children: [
              {
                path: '',
                name: 'tenant.profile',
                component: TenantProfile,
              },
              {
                path: 'integrations',
                name: 'tenant.integrations',
                component: Integrations,
                children: [
                  {
                    path: 'quickbooks',
                    name: 'tenant.integrations.quickbooks',
                    component: Quickbooks,
                  },
                ],
              },
              {
                path: 'feature_flags',
                name: 'tenant.feature_flags',
                component: FeatureFlags,
              },
            ],
          },
        ],
      },
      {
        path: 'tenant/quickbooks_redirect',
        name: 'tenant.quickbooks_redirect',
        component: QuickbooksRedirect,
      },
      {
        path: 'administrators',
        name: 'administrators',
        component: Administrators,
        children: [
          {
            path: '',
            name: 'administrators.list',
            component: AdminList,
          },
          {
            path: ':id',
            name: 'administrators.user',
            component: AdminUser,
            children: [
              {
                path: '',
                name: 'administrators.userProfile',
                component: AdminUserProfile,
              },
            ],
          },
        ],
      },
    ],
    meta: {
      layout: LAYOUTS.ADMIN,
      title: 'Admin - eLuma Insight',
      permission: [PERMISSIONS.VIEW_ADMINISTRATION],
    },
  },
];
