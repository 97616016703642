import { defineStore } from 'pinia';
import { OrganizationService } from '@/services';
import { OrganizationInterface } from '@/types';

export default defineStore('ArchiveOrganizationStore', {
  state: () => {
    return {
      isShow: false,
      organization: {} as OrganizationInterface,
    };
  },
  actions: {
    async archive(organizacionId: number) {
      return await OrganizationService.archiveOrganization(organizacionId);
    },
    async unArchive(organizacionId: number) {
      return await OrganizationService.unArchiveOrganization(organizacionId);
    },
  },
});
