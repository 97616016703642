import { defineStore } from 'pinia';
import { can, useArrayUtilities } from '@/composables';
import { PERMISSIONS } from '@/enum';
import { ComplianceService } from '@/services';
import { ComplianceStoreInterface, ComplianceStudent } from '@/types';

const arrayUtilities = useArrayUtilities();

interface PreferenceInterface {
  id: number;
  person_id: number;
  name: string;
  value: string;
}

export default defineStore('ComplianceStore', {
  state: (): ComplianceStoreInterface => {
    return {
      loading: false,
      error: undefined,
      start_at: '',
      end_at: '',
      selectedStudentId: 0,
      inCompliance: [] as ComplianceStudent[],
      outOfCompliance: [] as ComplianceStudent[],
      outOfComplianceThreshold: 0,
      selectedStudent: {} as ComplianceStudent,
      studentSelectedKey: '',
    };
  },

  actions: {
    catchError(e: Error) {
      this.error = 'Sorry there was an error. please try again';
      this.loading = false;
      console.error(e);
    },

    setDefaultDates() {
      const userDateInterval = [-28, 0];
      const start = new Date();
      const end = new Date();
      end.setDate(end.getDate() + userDateInterval[1]);
      start.setDate(start.getDate() + userDateInterval[0]);
      this.setDates(start.toJSON().split('T')[0], end.toJSON().split('T')[0]);
    },

    setDates(startDate: string, endDate: string) {
      this.start_at = startDate;
      this.end_at = endDate;
    },

    resetSelectedStudent() {
      this.selectedStudent = {} as ComplianceStudent;
      this.studentSelectedKey = '';
    },

    async getOutOfComplianceDateRangePreference(personId: number) {
      if (!can([PERMISSIONS.WIDGET_COMPLIANCE, PERMISSIONS.DASHBOARD_OVERVIEW_COMPLIANCE_WIDGET_DATE_VIEW])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view compliance data.';
        console.error(this.error);
        return Promise.reject();
      }

      this.loading = true;
      const response = await ComplianceService.getOutOfComplianceDateRangePreference(personId).catch(this.catchError);
      const preference = response?.data?.data.find(
        (preference: PreferenceInterface) => preference.name === 'out_of_compliance_date_range',
      );
      this.loading = false;
      return preference ? preference.value.split(',').map((days: string) => Number(days)) : [-28, 0];
    },

    async getAllCompliance(threshold?: number, organizationsId?: number[]) {
      if (!can([PERMISSIONS.WIDGET_COMPLIANCE, PERMISSIONS.DASHBOARD_OVERVIEW_COMPLIANCE_WIDGET_PAGINATION_VIEW])) {
        this.loading = false;
        this.error = 'Insufficient permissions to view compliance data.';
        console.error(this.error);
        return Promise.reject();
      }

      if (!this.start_at.length && !this.end_at.length) {
        this.setDefaultDates();
      }

      this.loading = true;
      await ComplianceService.getAll({ start_at: this.start_at, end_at: this.end_at }, threshold, organizationsId || [])
        .then((response) => {
          this.inCompliance = arrayUtilities.useValueNameSort(response?.data.data.in_compliance);
          this.outOfCompliance = arrayUtilities.useValueNameSort(response?.data.data.out_of_compliance);
          this.outOfComplianceThreshold = response?.data.data.out_of_compliance_threshold;

          this.loading = false;
        })
        .catch(this.catchError);
    },
  },
  getters: {
    inComplianceStudents: (state) =>
      arrayUtilities.useValueNameSort(
        state.selectedStudentId
          ? state.inCompliance.filter((student) => state.selectedStudentId == student.id)
          : state.inCompliance,
      ),

    outOfComplianceStudents: (state) =>
      arrayUtilities.useValueNameSort(
        state.selectedStudentId
          ? state.outOfCompliance.filter((student) => state.selectedStudentId == student.id)
          : state.outOfCompliance,
      ),

    students: (state) => {
      const studentsIdsSet = new Set();
      return [...state.inCompliance, ...state.outOfCompliance].filter((s) => {
        if (!studentsIdsSet.has(s.id)) {
          studentsIdsSet.add(s.id);
          return true;
        }
        return false;
      });
    },
  },
});
