import { computed } from 'vue';
import { useAuthStore, usePeopleTypesStore } from '@/store';

const authAcronym = computed(() => useAuthStore().personType?.acronym);
const PEOPLE_TYPES = computed(() => usePeopleTypesStore().PEOPLE_TYPES);
export const usePersonTypes = {
  isClinician: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.CLINICIAN;
  },
  isFacilitator: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.FACILITATOR;
  },
  isSupportAdmin: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.SUPPORT_ADMIN;
  },
  isSchoolAdmin: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.SCHOOL_ADMIN;
  },
  isStudent: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.STUDENT;
  },
  isParent: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.PARENT;
  },
  isSupportAdminOrSchoolAdmin: (acronym?: string) => {
    const acronymToUse = acronym ?? authAcronym.value;
    return acronymToUse === PEOPLE_TYPES.value.SUPPORT_ADMIN || acronymToUse === PEOPLE_TYPES.value.SCHOOL_ADMIN;
  },
  isType: (type: string, acronyns: string[]) => {
    return acronyns.includes(type);
  },
  isSuperAdmin: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.SUPER_ADMIN;
  },
  isBillingAdmin: (acronym?: string) => {
    return (acronym ?? authAcronym.value) === PEOPLE_TYPES.value.BILLING_ADMIN;
  },
  isElumaAdmin: (acronym?: string) => {
    const acronymToUse = acronym ?? authAcronym.value;
    return (
      acronymToUse === PEOPLE_TYPES.value.SUPPORT_ADMIN ||
      acronymToUse === PEOPLE_TYPES.value.SUPER_ADMIN ||
      acronymToUse === PEOPLE_TYPES.value.BILLING_ADMIN
    );
  },
};
