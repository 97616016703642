import {
  ApiPromise,
  ExternalAssociationInterface,
  GeneralProfileInterface,
  LocationFormInterface,
  StudentInfoFormInterface,
} from '@/types';

import { HTTP } from '@/utils';

export default class ProfileService {
  static updateLocation(url: string, location: LocationFormInterface): ApiPromise {
    return HTTP.put(url, location);
  }

  static updateExternalId(url: string, external: ExternalAssociationInterface): ApiPromise {
    return HTTP.put(url, external);
  }

  static update(url: string, data: GeneralProfileInterface | StudentInfoFormInterface | string): ApiPromise {
    return HTTP.patch(url, data);
  }

  static getProfile(): ApiPromise {
    return HTTP.get('profile');
  }

  static getProfileById(id: number) {
    return HTTP.get(`people/${id}`);
  }
}
