import { defineStore } from 'pinia';
import { can } from '@/composables';
import { EvaluationService } from '@/services';
import { PERMISSIONS } from '@/enum';
import { EvaluationStoreInterface, EvaluationStudentInterface, EvaluationPersonInterface } from '@/types';

export default defineStore('EvaluationStore', {
  state: (): EvaluationStoreInterface => {
    return {
      loading: false,
      completed: 0,
      selectedStudentId: 0,
      students: [] as EvaluationPersonInterface[],
      overdue: [] as EvaluationStudentInterface[],
      upcoming: [] as EvaluationStudentInterface[],
    };
  },
  actions: {
    async getEvaluations(organizationIds: Array<number>) {
      if (!can([PERMISSIONS.WIDGET_EVALUATION])) {
        this.loading = false;
        console.error('Insufficient permissions to view/filter calendar events');
        return Promise.reject();
      }

      this.loading = true;
      await EvaluationService.get(organizationIds)
        .then((response) => {
          this.students = response.data.data.students;
          this.completed = response.data.data.completed;
          this.overdue = response.data.data.overdue;
          this.upcoming = response.data.data.upcoming;
        })
        .catch(() => {
          this.students = [];
          this.completed = 0;
          this.overdue = [];
          this.upcoming = [];
        });

      this.loading = false;
    },
  },
  getters: {
    overdueStudents: (state) =>
      state.selectedStudentId
        ? state.overdue.filter((student: EvaluationStudentInterface) => state.selectedStudentId == student.person.id)
        : state.overdue,
    upcomingStudents: (state) =>
      state.selectedStudentId
        ? state.upcoming.filter((student: EvaluationStudentInterface) => state.selectedStudentId == student.person.id)
        : state.upcoming,
  },
});
