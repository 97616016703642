import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { Component } from 'vue';
import { useAuthStore } from '@/store';
import { PERMISSIONS } from '@/enum';
import { OrganizationService } from '@/services';
import {
  ContractDetailInterface,
  ContractInterface,
  LocationInterface,
  OrganizationFilterInterface,
  OrganizationInterface,
  OrganizationPayloadInterface,
  OrganizationsInterface,
  OrganizationsParentsInterface,
  OrganizationTotalsInterface,
  SubOrganizationTableType,
} from '@/types';
import Connections from '@/views/Organizations/Connections';
import OrganizationContracts from '@/views/Organizations/OrganizationContracts';
import OrganizationDocuments from '@/views/Organizations/OrganizationDocuments';
import OrganizationIntegrations from '@/views/Organizations/OrganizationIntegrations';
import OrganizationLogs from '@/views/Organizations/OrganizationLogs';
import Profile from '@/views/Organizations/Profile';

interface OrganizationRoute {
  to: string;
  label: string;
  component: Component;
  permission?: string[];
  visible?: boolean;
  disabled?: boolean | (() => boolean);
}

const DefaultLocationModel = {
  address: undefined,
  website: undefined,
  phone: undefined,
  zipcode: undefined,
  city_id: undefined,
  city_name: undefined,
  state_id: undefined,
  country_id: undefined,
};

export default defineStore('OrganizationStore', {
  state: () => {
    return {
      loading: false,
      error: false,
      message: '',
      showArchived: false,
      organization: {} as OrganizationInterface,
      organizationPayload: {} as OrganizationPayloadInterface,
      organizations: {
        loading: false,
        data: [] as OrganizationsInterface[],
        total: 0,
      },
      contracts: [] as ContractInterface[],
      contract: {} as ContractDetailInterface,
      selectedOrgRoutes: [] as OrganizationRoute[],
      selectedOrganizationRoutes: [] as OrganizationRoute[],
      totals: {
        loading: false,
        data: {} as OrganizationTotalsInterface,
      },
      organizationParents: [] as OrganizationsParentsInterface[],
      location: {} as LocationInterface,
      organizationLogs: {
        loading: false,
        data: [],
        filters: {
          search: '',
          start_at: '',
          end_at: '',
          per_page: 15,
          page: 1,
        },
        total: 0,
      },
      selectedOrganizations: {} as OrganizationsParentsInterface,
      currentRoute: undefined,
    };
  },
  actions: {
    catchError(e: AxiosResponse) {
      this.error = true;
      this.message = e.data.message;
      this.doToast(this.message, 'Error', true);
      this.loading = false;
      throw e;
    },
    loaded(message = '') {
      if (!this.error) {
        this.loading = false;
        this.message = message;
        if (this.message) this.doToast(this.message);
      }
    },
    load() {
      this.error = false;
      this.message = '';
      this.loading = true;
    },
    /**
     * create the organization data from the API and save it to state.
     */
    async createOrganization(payload: OrganizationPayloadInterface) {
      this.load();
      this.organizationPayload = payload;
      const { parent_id } = this.organizationPayload;
      if (parent_id) this.organizationPayload.parent_id = Number(Object.keys(parent_id)[0]);
      await OrganizationService.createOrganization(this.organizationPayload).catch(this.catchError);
      this.loaded('The Organization has been saved');
    },

    /**
     * It gets the organization info from the server.
     * @param {number} organizationId - number - This is the id of the organization that you want to get
     * the information for.
     * @returns The response from the API call.
     */
    async getOrganizationInfo(organizationId: number): Promise<OrganizationInterface> {
      this.load();
      const response = await OrganizationService.getOrganization(organizationId).catch(this.catchError);
      this.loaded();
      return response.data.data;
    },
    async getOrganizationByName(name: string): Promise<OrganizationInterface> {
      this.load();
      const response = await OrganizationService.getOrganizationByName(name).catch(this.catchError);
      this.loaded();
      return response.data.data;
    },

    /**
     * Get the organization data from the API and save it to state.
     */
    async getOrganization(organizationId: number) {
      this.load();
      const response = await OrganizationService.getOrganization(organizationId).catch(this.catchError);
      this.organization = response.data.data;
      const id = parseInt(response.data.data.parent_id);
      if (id) this.organization.parent_id = { [id]: true };
      this.setLocation();
      this.setRoutes();
      this.loaded();
    },

    /* Setting the location of the organization. */
    setLocation() {
      if (this.organization) {
        if (this.organization.location) {
          const data: LocationInterface = this.organization.location;
          if (data) {
            this.location = {
              address: data.address,
              location_type_id: data.location_type?.id,
              website: data.website,
              phone: data.phone,
              zipcode: data.zipcode,
              city_id: data.city?.id,
              city_name: data.city?.name,
              state_id: data.state?.id,
              country_id: data.country?.id,
            };
          }
        } else {
          this.location = DefaultLocationModel;
        }
      }
    },

    /* Setting the routes for the organization. */
    setRoutes() {
      const authStore = useAuthStore();
      this.selectedOrganizationRoutes = [
        {
          to: `/organizations/${this.organization.id}`,
          label: 'Connections',
          component: Connections,
          permission: [PERMISSIONS.ORGANIZATIONS_CONNECTIONS],
          disabled: () => this.currentRoute === 'organization.connections',
        },
        {
          to: `/organizations/${this.organization.id}/profile`,
          label: 'Profile',
          component: Profile,
          permission: [PERMISSIONS.ORGANIZATION_SHOW],
          disabled: () => this.currentRoute === 'organization.profile',
        },
        {
          to: `/organizations/${this.organization.id}/documents`,
          label: 'Documents',
          component: OrganizationDocuments,
          permission: [PERMISSIONS.ORGANIZATION_DOCUMENTS_SHOW],
          disabled: () => this.currentRoute === 'organization.documents',
        },
        {
          to: `/organizations/${this.organization.id}/contracts`,
          label: 'Contracts',
          component: OrganizationContracts,
          permission: [PERMISSIONS.CONTRACTS_CONTRACT_BY_ORGANIZATION],
          visible: !!authStore.currentInstanceIsMasterTenant,
          disabled: () => this.currentRoute === 'organization.contract',
        },
        {
          to: `/organizations/${this.organization.id}/integrations`,
          label: 'Integrations',
          component: OrganizationIntegrations,
          permission: [PERMISSIONS.ORGANIZATION_INTEGRATIONS],
          visible: !!authStore.currentInstanceIsMasterTenant,
          disabled: () => this.currentRoute === 'organization.integrations',
        },
        {
          to: `/organizations/${this.organization.id}/logs`,
          label: 'Logs',
          component: OrganizationLogs,
          permission: [PERMISSIONS.ORGANIZATION_LOGS],
          disabled: () => this.currentRoute === 'organization.logs',
        },
      ];
    },
    /**
     * Get the organizations tree data from the API and save it to state.
     */
    async getOrganizations(filters?: OrganizationFilterInterface) {
      this.load();
      const response = await OrganizationService.getOrganizations(filters).catch(this.catchError);
      this.organizations.data = response.data.data;
      this.organizations.total = this.organizations.data.length;
      this.loaded();
      return this.organizations.data;
    },

    /**
     * Get the total number of organizations tree data from the API and save it to state. Actived and archived
     */
    async getTotals() {
      this.totals.loading = true;
      const response = await OrganizationService.getOrganizationsTotals().catch(this.catchError);
      this.totals.data = response.data.data;
      this.totals.loading = false;
    },

    /**
     * Get the parents organizations tree data from the API and save it to state.
     */
    async getParentsOrganizations() {
      this.load();
      this.organizationParents = [];
      const response = await OrganizationService.getOrganizationParents().catch(this.catchError);
      this.organizationParents = response.data.data;
      this.loaded();
    },

    /**
     * Get organization and sub organizations log data
     */
    async getOrganizationLogs(id: number) {
      this.organizationLogs.loading = true;
      const response = await OrganizationService.getOrganizationLogs(id, this.organizationLogs.filters).catch(
        this.catchError,
      );
      this.organizationLogs.data = response.data.data;
      this.organizationLogs.total = response.data.meta.total;
      this.organizationLogs.loading = false;
    },

    /**
     * Update the organization and return the response.
     */
    async updateOrganization(payload: OrganizationInterface, orgId: number) {
      this.load();
      const response = await OrganizationService.updateOrganization(payload, orgId).catch(this.catchError);
      this.organization = response.data.data;
      const id = parseInt(response.data.data.parent_id);
      if (id) this.organization.parent_id = { [id]: true };
      this.setLocation();
      this.loaded('Organization has been Updated');
    },

    /* Resetting the organization to an empty object. */
    async resetOrganization() {
      this.organization = {} as OrganizationInterface;
    },

    /**
     * getSubOrganizationsForTable
     *
     * Retrieve and format sub-org data for the organization page sub-org table
     * @return OrganizationInterface[]
     */
    async getSubOrganizationsForTable(
      forceRequest?: boolean,
      filter?: OrganizationFilterInterface,
    ): Promise<SubOrganizationTableType[]> {
      const organizationId = this.organization.id;
      const parentOrganization: OrganizationsInterface[] = [];

      if (!this.organizations.data.length || forceRequest || !!filter) {
        await this.getOrganizations(filter);
      }
      const parseOrganizations = (organization: OrganizationsInterface) => {
        if (organization.key === organizationId) {
          parentOrganization.push(organization);
          return;
        }
        for (const childOrganization of organization.children) {
          if (childOrganization.key === organizationId) {
            parentOrganization.push(childOrganization);
            break;
          }
          if (childOrganization.children.length) {
            parseOrganizations(childOrganization);
          }
        }
      };
      for (const organization of this.organizations.data) {
        if (parentOrganization.length) break;
        parseOrganizations(organization);
      }
      const children =
        parentOrganization[0].children.map((organization) => {
          const { name, id, archived_at } = organization.data;
          return {
            name: name as string,
            id: id as number,
            archived_at: archived_at as string,
          };
        }) || [];
      return this.showArchived ? children : children?.filter((item) => !item.archived_at);
    },

    /**
     * Retrieve organization available to attach to a school admin
     */
    async getAddOrganizationsModalData(): Promise<OrganizationsInterface[]> {
      if (!this.organizations.data.length) await this.getOrganizations();
      const availableToAddOrganizations: OrganizationInterface[] = [];

      this.organizations.data.forEach((organization) => {
        const isCurrentOrganization = this.organization.id === organization.data.id;
        const isParentOrganization = !!organization.children.length;
        const hasParentOrganization = !!organization.data.parent_id;
        const isArchived = !!organization.data.archived_at;

        if (isCurrentOrganization || isParentOrganization || hasParentOrganization || isArchived) return;

        availableToAddOrganizations.push(organization.data as OrganizationInterface);
      });

      return availableToAddOrganizations.map((organization) => {
        return {
          key: organization.id as number,
          data: { name: organization.name as string },
          children: [],
        } as OrganizationsInterface;
      });
    },
  },

  getters: {
    organizationsIdsSelected: (state) =>
      Object.keys(state.selectedOrganizations).map((organizationId) => Number(organizationId)),
  },
});
