import { defineStore } from 'pinia';
import { CitiesService } from '@/services';
import { CityInterface, CitySearchInterface } from '@/types';

export default defineStore('CitiesStore', {
  state: () => {
    return {
      loading: false,
      cities: {} as CityInterface[],
    };
  },

  actions: {
    /**
     * Search for cities with the provided country and state id along with the city name.
     */
    async searchCities(payload: CitySearchInterface) {
      this.loading = true;
      const response = CitiesService.searchCities(payload);
      this.loading = false;
      return response;
    },
  },
});
