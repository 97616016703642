import { LocationQuery } from 'vue-router';
import { ApiPromise, OrganizationDocumentsInterface } from '@/types';
import { HTTP } from '@/utils';

type OrganizationDocumentsService = {
  getOrganizationBillingDocuments: (id: number, payload?: LocationQuery) => ApiPromise;
  uploadBillingDocument: (id: number, document: FormData) => ApiPromise;
  downloadBillingDocument: (id: number) => ApiPromise;
  deleteBillingDocument: (id: number, orgId: number) => ApiPromise;
  getOrganizationGeneralDocuments: (
    id: number,
    payload?: LocationQuery,
  ) => ApiPromise<{ data: OrganizationDocumentsInterface[] }>;
  uploadGeneralDocument: (id: number, document: FormData) => ApiPromise;
  downloadGeneralDocument: (id: number, orgId: number) => ApiPromise;
  deleteGeneralDocument: (id: number, orgId: number) => ApiPromise;
  generateDataExport: (orgId: number) => ApiPromise;
  deleteDataExport: (orgId: number) => ApiPromise;
  getOrganizationDataExports: (orgId: number) => ApiPromise;
};

const resource = 'organization';

export const OrganizationDocumentsService: OrganizationDocumentsService = {
  deleteBillingDocument(id: number, orgId: number): ApiPromise {
    return HTTP.delete(`${resource}/${orgId}/billing-documents/${id}`);
  },
  getOrganizationBillingDocuments: (id: number, payload?: LocationQuery) => {
    let filter = '';

    if (payload && Object.keys(payload).length) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof LocationQuery]}`)
        .join('&')}`;
    }

    return HTTP.get(`${resource}/${id}/billing-documents/${filter}`);
  },
  uploadBillingDocument(id: number, document: FormData): ApiPromise {
    return HTTP.post(`${resource}/${id}/billing-documents`, document);
  },
  downloadBillingDocument(id: number): ApiPromise {
    return HTTP.get(`billing/${id}/download`, { responseType: 'blob' });
  },
  deleteGeneralDocument(id: number, orgId: number): ApiPromise {
    return HTTP.delete(`${resource}/${orgId}/documents/${id}`);
  },
  getOrganizationGeneralDocuments: (id: number, payload?: LocationQuery) => {
    return HTTP.get(`${resource}/${id}/documents`, { params: payload });
  },
  uploadGeneralDocument(id: number, document: FormData): ApiPromise {
    return HTTP.post(`${resource}/${id}/documents`, document);
  },
  downloadGeneralDocument(id: number, orgId: number): ApiPromise {
    return HTTP.get(`${resource}/${orgId}/documents/${id}/download`, { responseType: 'blob' });
  },
  generateDataExport(orgId: number): ApiPromise {
    return HTTP.post(`${resource}/${orgId}/generate-data-export`);
  },
  deleteDataExport(orgId: number): ApiPromise {
    return HTTP.delete(`${resource}/${orgId}/delete-data-export`);
  },
  getOrganizationDataExports(orgId: number): ApiPromise {
    return HTTP.get(`${resource}/${orgId}/data-exports`);
  },
};
