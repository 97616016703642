import { defineStore } from 'pinia';
import { StatesService } from '@/services';
import { StateInterface } from '@/types';

export default defineStore('StatesStore', {
  state: () => {
    return {
      loading: false,
      states: [] as StateInterface[],
    };
  },

  actions: {
    async getStates() {
      this.loading = true;
      const response = await StatesService.getStates();
      this.states = response.data.data;
      this.loading = false;
    },
  },
});
