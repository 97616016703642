import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { useAuthStore, useOrganizationStore } from '@/store';
import { OrganizationService } from '@/services';
import { SchoolAdmin, SchoolAdminModal, SchoolAdminPayload, SchoolAdminState, SchoolAdminTable } from '@/types';

export default defineStore('SchoolAdminStore', {
  state: (): SchoolAdminState => ({
    schoolAdmins: [],
    error: '',
    loading: false,
    showArchived: false,
  }),

  getters: {
    orgId: () => (useOrganizationStore().organization.id as number) || useAuthStore().organizationId,
  },

  actions: {
    catchError(e: string) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    /**
     *
     * attachSchoolAdmins
     *
     * Assign school-admins to a specific organization
     * @param schoolAdmins: number[]
     */
    async attachSchoolAdmins(schoolAdmins: number[]): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      this.loading = true;
      const payload = { school_admins: schoolAdmins } as SchoolAdminPayload;
      return await OrganizationService.attachPerson(payload, organizationId, 'school-admins')
        .then((result) => {
          this.loading = false;
          return result;
        })
        .catch(this.catchError);
    },

    /**
     *
     * detachSchoolAdmins
     *
     * Remove school-admins from a specific organization
     * @param school_admins: number[]
     */
    async detachSchoolAdmins(schoolAdmins: number[]): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      const payload = { school_admins: schoolAdmins } as SchoolAdminPayload;
      return await OrganizationService.detachPerson(payload, organizationId, 'school-admins')
        .then((result) => {
          this.loading = false;
          return result;
        })
        .catch(this.catchError);
    },

    /**
     *
     * getSchoolAdmins
     *
     * Retrieve all school-admins assigned to an organization
     *
     * @param commit
     */
    async getSchoolAdmins(filter: string): Promise<SchoolAdmin[]> {
      const organizationId = this.orgId;
      this.loading = true;
      return await OrganizationService.getPerson(organizationId, 'school-admins', filter)
        .then((result) => {
          this.loading = false;
          this.schoolAdmins = result.data?.data;
          return this.schoolAdmins;
        })
        .catch(this.catchError);
    },

    /**
     * getSchoolAdminsForTable
     *
     *
     * Retrieve and format school-admins data for the organization page student table
     * @return StudentTableInterface[]
     */
    async getSchoolAdminsForTable(filter: string): Promise<SchoolAdminTable[]> {
      await this.getSchoolAdmins(filter);
      const tableData = this.schoolAdmins.map((schoolAdmin) => {
        return {
          id: schoolAdmin.id,
          name: schoolAdmin.display_name as string,
          archived: !!schoolAdmin.archived_at,
        };
      });
      return this.showArchived ? tableData : tableData.filter((item) => !item.archived);
    },

    /**
     *
     * getSchoolAdmins
     *
     * Retrieve all school-admins assigned to an organization
     *
     * @param commit
     */
    async getSchoolAdminsAvailableToAdd(): Promise<SchoolAdminModal[]> {
      const organizationId = this.orgId;
      this.loading = true;
      return await OrganizationService.getPersonAvailableToAdd(organizationId, 'school-admins')
        .then((result) => {
          this.loading = false;
          return result.data?.data
            .filter((item) => !item.archived_at)
            .map((schoolAdmin) => {
              return {
                value: schoolAdmin.id,
                name: schoolAdmin.display_name as string,
              };
            });
        })
        .catch(this.catchError);
    },
  },
});
