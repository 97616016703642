import { ApiPromise, TreatmentPlanGeneralFormInterface } from '@/types';
import { AxiosResponse } from 'axios';
import { HTTP } from '.';
import { UserTreatmentPlanInterface } from '@/types/User';

export default class TreatmentPlanService {
  static resource = 'treatment-plan-types';

  /**
   * get all TreatmentPLan Form Data objects from the API
   * @param getters
   * @returns Promise
   */
  static fetchAllTreatmentPlanFormData(getters: string[]): Promise<AxiosResponse[]> {
    const httpGetters = getters.map((url) => {
      return HTTP.get(url);
    });
    return Promise.all(httpGetters);
  }

  static addTreatmentPlanForPerson(
    userTreatmentPlans: TreatmentPlanGeneralFormInterface,
    personId: string | number,
  ): ApiPromise {
    const requestPayload = {
      treatment_plan_type_id: userTreatmentPlans.treatment_plan_type_id,
      treatment_plan_category_id: userTreatmentPlans.treatment_plan_category_id,
      start_at: userTreatmentPlans.start_at,
    };

    return HTTP.post(`person/${personId}/treatment-plans`, requestPayload);
  }

  static updateTreatmentPlan(url: string, plan: UserTreatmentPlanInterface): ApiPromise {
    return HTTP.patch(url, plan);
  }
}
