import { LocationQuery } from 'vue-router';
import { ApiPromise, BillingInvoicesFilterInterface } from '@/types';
import { HTTP } from '@/utils';

const joinFilters = (filters: LocationQuery) => {
  return `${Object.keys(filters)
    .map((item) => {
      if (item !== 'dates') {
        let replaceInt;
        if (item === 'third_party' || item === 'prepay') {
          replaceInt = filters[item] === 'true' ? '1' : '0';
          return `${item}=${replaceInt}`;
        }
        return `${item}=${filters[item as keyof BillingInvoicesFilterInterface]}`;
      }
    })
    .join('&')}`;
};

export default class BillingService {
  static invoice_resource = 'billing/invoices';
  static billing_resource = 'billing/';

  static getAllInvoices(filters: LocationQuery): ApiPromise {
    return HTTP.get(`${this.invoice_resource}?${joinFilters(filters)}`);
  }

  static generateInvoices(invoices: number[]): ApiPromise {
    return HTTP.post(`${this.invoice_resource}/run`, { invoices });
  }

  static sendInvoices(invoices: number[]): ApiPromise {
    return HTTP.post(`${this.invoice_resource}/send`, { invoices });
  }

  static downloadInvoice(id: number): ApiPromise {
    return HTTP.get(`${this.billing_resource}${id}/download`, { responseType: 'blob' });
  }
}
