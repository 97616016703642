import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class OAuthService {
  static url = 'oauth';

  static redirect(provider: string): ApiPromise {
    return HTTP.get(`${this.url}/${provider}/redirect`);
  }

  static callback(provider: string, code: string): ApiPromise {
    return HTTP.get(`${this.url}/${provider}/callback?code=${code}`);
  }
}
