import { ActivityLogFilterInterface } from '@/types/ActivityLog';
import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';
import { useRequestCancel } from '@/composables/useRequestCancel';
import { REQUEST } from '@/enum';

export default class ActivityLogDashboardService {
  static requestCancel = useRequestCancel();

  static queryFilter(payload: ActivityLogFilterInterface) {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .filter((item) => payload[item as keyof ActivityLogFilterInterface] !== '')
        .map((item) => `${item}=${payload[item as keyof ActivityLogFilterInterface]}`)
        .join('&')}`;
    }

    return filter;
  }

  static getLogs(payload: ActivityLogFilterInterface, page: number, organizationsIds?: Array<number>): ApiPromise {
    return HTTP.get(`dashboard-activity${this.queryFilter(payload)}&page=${page}`, {
      params: {
        organizations: organizationsIds,
      },
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.LOGS),
    });
  }

  static getDashboardActivityDateRangePreference(personId: number): ApiPromise {
    return HTTP.get(`/people/${personId}/preferences?name=dashboard_activity_date_range`);
  }

  static getDashboardActivityStudents(): ApiPromise {
    return HTTP.get(`dashboard-activity/students`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.ACTIVITY_STUDENTS),
    });
  }
}
