<script setup lang="ts"></script>

<template>
  <div class="w-full">
    <Card>
      <template #content></template>
    </Card>
  </div>
</template>

<style scoped lang="scss">
:deep(.p-card-body) {
  padding: 0px;
}
</style>
