import { ApiPromise } from '@/types';

import { HTTP } from '@/utils';
import { TenantSettingsInterface } from '@/types';

export default class TenantService {
  static resource = 'tenant-settings';

  static getTenantSettings(): ApiPromise {
    return HTTP.get(`${this.resource}`);
  }

  static saveTenantSettings(payload: TenantSettingsInterface): ApiPromise {
    return HTTP.post(`${this.resource}/store`, payload);
  }

  static updateTenantSettings(payload: TenantSettingsInterface): ApiPromise {
    return HTTP.put(`${this.resource}/update/${payload.id}`, payload);
  }
}
