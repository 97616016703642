export enum INVOICE_STATUS {
  PENDING = 'pending',
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  QB_QUEUED = 'qb_queued',
  DONE = 'done',
  IN_QUICKBOOKS = 'in_quickbooks',
  EMAIL_QUEUED = 'email_queued',
  EMAILED = 'emailed',
  QUEUED = 'queued',
}
