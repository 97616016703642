import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class UtilizationService {
  static utilizationResource = 'utilization-analytics';

  static getDashboardWidgetData(end_at: string): ApiPromise {
    return HTTP.get(`${this.utilizationResource}?end_at=${end_at}`);
  }
}
