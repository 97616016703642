export enum FREQUENCY_INTERVALS {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  QUARTERLY = 'quarterly',
  SEMESTERLY = 'semesterly',
}

export const FREQUENCY_INTERVALS_OPTIONS = [
  {
    label: 'Daily',
    value: FREQUENCY_INTERVALS.DAILY,
  },
  {
    label: 'Weekly',
    value: FREQUENCY_INTERVALS.WEEKLY,
  },
  {
    label: 'Monthly',
    value: FREQUENCY_INTERVALS.MONTHLY,
  },
  {
    label: 'Quarterly',
    value: FREQUENCY_INTERVALS.QUARTERLY,
  },
  {
    label: 'Semesterly',
    value: FREQUENCY_INTERVALS.SEMESTERLY,
  },
  {
    label: 'Annually',
    value: FREQUENCY_INTERVALS.YEARLY,
  },
];

export const FREQUENCY_AMOUNT = [
  { label: '1 Time', value: 1 },
  { label: '2 Times', value: 2 },
  { label: '3 Times', value: 3 },
  { label: '4 Times', value: 4 },
  { label: '5 Times', value: 5 },
];
export enum SHORTCUT_FREQUENCY_TYPES {
  WEEK2 = '2xWEEK',
  WEEK3 = '3xWEEK',
  MONTH2 = '2xMONTH',
}

export const ALL_FREQUENCY_TYPES = { ...SHORTCUT_FREQUENCY_TYPES, ...FREQUENCY_INTERVALS };

export enum ABRIDGED_FREQUENCY_INTERVALS {
  WEEK = 'Week',
  MONTH = 'Month',
}

export enum TREATMENT_PLAN_TYPE_ACRONYM {
  IEP = 'iep',
  GEP = 'gep',
}
