import { ApiPromise, DateFilterInterface } from '@/types';
import { HTTP } from '@/utils';

const joinFilters = (filters: DateFilterInterface, organizations: number[], clinicians: number[]) => {
  const returnVal = `${Object.keys(filters)
    .map((item) => `${item}=${filters[item as keyof DateFilterInterface]}`)
    .join('&')}&${organizations.map((organization) => `organizations[]=${organization}`).join('&')}
      &${clinicians.map((clinician) => `clinicians[]=${clinician}`).join('&')}`;

  return returnVal.slice(-1) === '&' ? returnVal.slice(0, -1).trim() : returnVal.trim();
};

export default class PayrollReportService {
  static resource = 'service-provider/payments';

  static getPayrollReport(
    filters: DateFilterInterface,
    organizations: number[] = [],
    clinicians: number[] = [],
  ): ApiPromise {
    return HTTP.get(`${this.resource}?${joinFilters(filters, organizations, clinicians)}`);
  }

  static getCurrAuthUserData(filters: DateFilterInterface, organizations: number[] = []): ApiPromise {
    const params = joinFilters(filters, organizations, [])
      .split('&')
      .filter((item) => item)
      .join('&');
    return HTTP.get(`${this.resource}/me?${params}`);
  }

  static downloadPayrollReport(
    filters: DateFilterInterface,
    organizations: number[] = [],
    clinicians: number[] = [],
    type = 'csv',
  ): ApiPromise {
    return HTTP.get(`${this.resource}/download/${type}?${joinFilters(filters, organizations, clinicians)}`, {
      responseType: 'blob',
    });
  }
}
