export enum FORMS {
  FLAT_FEE,
  HOURLY,
  PER_STUDENT,
}

export enum CONTRACT_BILLING_TYPE {
  FLAT_FEE = 'flat_fee',
  HOURLY = 'hourly',
  PER_STUDENT = 'per_student',
}

export enum CONTRACT_TYPE {
  HOURLY_CAP = 'hourly_cap',
  FLAT_FEE = 'flat_fee',
  HOURLY = 'hourly',
  PER_STUDENT = 'per_student',
}

export enum CONTRACT_EDIT_TYPES {
  NEW = 'new',
  RENEW = 'renew',
  EDIT = 'edit',
}

export enum CONTRACT_VERSION_TYPE {
  NEW = 'New',
  RENEWAL = 'Renewal',
  EXPANSION = 'Expansion',
}
