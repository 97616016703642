import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { LocationQuery } from 'vue-router';
import { useDownload } from '@/composables';
import BillingService from '@/services/BillingService';
import { BillingInvoicesFilterInterface, BillingStateInterface, InvoiceInterface } from '@/types';

const defaultFilters: BillingInvoicesFilterInterface = {
  search: '',
  start_at: '',
  end_at: '',
  third_party: false,
  prepay: false,
  per_page: 15,
  page: 0,
};

export default defineStore('BillingStore', {
  state: (): BillingStateInterface => ({
    invoices: [] as InvoiceInterface[],
    reviewInvoices: [] as InvoiceInterface[],
    error: false,
    generate_error: false,
    message: '',
    loading: false,
    filters: { ...defaultFilters },
    generating: false,
    downloading: false,
    sending: false,
  }),

  actions: {
    catchError(e: AxiosResponse) {
      this.error = true;
      this.loading = false;
      this.message = e.data.message;
      throw e;
    },
    load() {
      this.error = false;
      this.loading = true;
      this.message = '';
    },
    loaded() {
      this.loading = false;
      this.error = false;
    },
    async getAllInvoices(filters: LocationQuery) {
      this.load();
      const response = await BillingService.getAllInvoices(filters).catch(this.catchError);
      this.invoices = response.data.data;
      this.loaded();
    },
    async getReviewInvoices(filters: LocationQuery) {
      this.load();
      const response = await BillingService.getAllInvoices(filters).catch(this.catchError);
      this.reviewInvoices = response.data.data;
      this.loaded();
    },
    async downloadInvoice(id: number, name = 'invoice', extension = 'xlsx') {
      this.downloading = true;
      const response = await BillingService.downloadInvoice(id).catch(this.catchError);
      useDownload(response.data, name, extension);
      this.downloading = false;
    },
    async generateInvoices(invoices: number[]) {
      this.generating = true;
      this.generate_error = false;
      await BillingService.generateInvoices(invoices)
        .then((response) => {
          this.message = response.data.message;
        })
        .catch((e: AxiosResponse) => {
          this.generate_error = true;
          this.message = e.data.message;
          this.doToast(this.message as string, 'Error', true);
        });

      this.generating = false;
    },
    async sendInvoices(invoices: number[]) {
      this.sending = true;
      this.error = false;
      await BillingService.sendInvoices(invoices)
        .then((response) => {
          this.message = response.data.message;
        })
        .catch(this.catchError);
      this.sending = false;
    },
  },
});
