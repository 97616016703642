<template>
  <div class="ml-1 info-icon-container flex justify-content-start">
    <Icon name="info" class="text-gray-500 text-s" />
    <div class="p-tooltip p-component p-tooltip-right" style="width: fit-content; z-index: 99999; padding: 0">
      <div class="p-tooltip-arrow"></div>
      <div class="p-tooltip-text white-space-normal flex flex-column px-3">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.info-icon-container {
  height: 2rem;
  width: 2rem;
  align-items: center;
  justify-content: center;
  position: relative;

  .p-tooltip {
    min-width: 200px;
    position: relative;
    right: -5px !important;

    .p-tooltip-arrow {
      left: -4px !important;
    }
    .p-tooltip-text {
      min-width: 200px;

      padding: 0.5rem 0;
      span {
        font-size: 11px;
      }
    }
  }
  &:hover {
    .p-tooltip {
      display: block !important;
    }
  }
}
</style>
