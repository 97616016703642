import { defineStore } from 'pinia';
import { useDisciplineDropdown } from '@/composables';
import { EVENT_TYPE } from '@/enum';
import { OrganizationService, ReviewReportService } from '@/services';
import { useReportStore } from '@/store';
import {
  ReviewReportClinicianInterface,
  ReviewReportClinicianMinuteInterface,
  ReviewReportExitedStudentInterface,
  ReviewReportFilterInterface,
  ReviewReportStateInterface,
  ReviewReportStudentInterface,
  ReviewReportTherapyResultInterface,
  ReviewReportTrackInterface,
  ServiceInterface,
} from '@/types';

export default defineStore('ReviewReportStore', {
  state: (): ReviewReportStateInterface => {
    return {
      queryParams: undefined,
      track: {
        loading: false,
        data: [] as ReviewReportTrackInterface[],
      },
      clinicians: {
        loading: false,
        data: [] as ReviewReportClinicianInterface[],
      },
      clinicianMinutes: {
        loading: false,
        data: {} as ReviewReportClinicianMinuteInterface,
      },
      therapyResult: {
        loading: false,
        data: [] as ReviewReportTherapyResultInterface[],
      },
      students: {
        loading: false,
        data: {} as ReviewReportStudentInterface,
      },
      exitedStudents: {
        loading: false,
        data: [] as ReviewReportExitedStudentInterface[],
      },
      filters: {
        disciplines: {
          options: [] as { name: string; value: number }[],
          selected: null as number[] | null,
          loading: false,
        },
        eventTypes: {
          options: [] as { name: string; value: number }[],
          selected: null as string[] | null,
          loading: false,
        },
      },
    };
  },

  actions: {
    defineFilters(filters: ReviewReportFilterInterface) {
      if (filters.organizations !== undefined) {
        filters.organizations = filters.organizations.toString().split(',');
      }

      if (filters.clinicians !== undefined) {
        filters.clinicians = filters.clinicians.toString().split(',');
      }

      if (filters.disciplines !== undefined) {
        filters.disciplines = filters.disciplines.toString().split(',');
      }

      if (filters.event_types !== undefined) {
        filters.event_types = filters.event_types.toString().split(',');
      }

      return filters;
    },

    async resetReviewReportData() {
      this.track.data = [];

      this.clinicianMinutes.data = {
        provided: 0,
        assigned: 0,
      };
      this.therapyResult.data = [];
      this.students.data = {
        total: 0,
        active: 0,
        inactive: 0,
      };
      this.exitedStudents.data = [];

      this.track.loading = true;
      this.clinicianMinutes.loading = true;
      this.therapyResult.loading = true;
      this.students.loading = true;
      this.exitedStudents.loading = true;
    },

    /**
     * Get the report track data with or without any selected filters.
     */
    async getReviewReportTrack(filters: ReviewReportFilterInterface) {
      const response = await ReviewReportService.getReviewReportTrack(filters);
      this.track.data = response.data;
      this.track.loading = false;
    },

    /**
     * Get the report clinician data with or without any selected filters.
     */
    async getReviewReportClinicians(filters: ReviewReportFilterInterface) {
      this.clinicians.loading = true;
      this.clinicians.data = [];
      const response = await ReviewReportService.getReviewReportClinicians(filters);
      this.clinicians.data = response.data;
      this.clinicians.loading = false;
    },

    /**
     * Get the report clinician minute data with or without any selected filters.
     */
    async getReviewReportClinicianMinutes(filters: ReviewReportFilterInterface) {
      const response = await ReviewReportService.getReviewReportClinicianMinutes(filters);
      this.clinicianMinutes.data = response.data;
      this.clinicianMinutes.loading = false;
    },

    /**
     * Get the report therapy result data with or without any selected filters.
     */
    async getReviewReportTherapyResult(filters: ReviewReportFilterInterface) {
      const response = await ReviewReportService.getReviewReportTherapyResult(filters);
      this.therapyResult.data = response.data;
      this.therapyResult.loading = false;
    },

    /**
     * Get the report students data with or without any selected filters.
     */
    async getReviewReportStudents(filters: ReviewReportFilterInterface) {
      const response = await ReviewReportService.getReviewReportStudents(filters);
      this.students.data = response.data;
      this.students.loading = false;
    },

    /**
     * Get the report exited students data with or without any selected filters.
     */
    async getReviewReportExitedStudents(filters: ReviewReportFilterInterface) {
      const response = await ReviewReportService.getReviewReportExitedStudents(filters);
      this.exitedStudents.data = response.data;
      this.exitedStudents.loading = false;
    },

    /**
     * Get the disciplines available for the specific organization.
     */
    async getDisciplines() {
      const { disciplines } = this.filters;
      disciplines.loading = true;
      const organizationIds = useReportStore().organizationIds;
      const response = await OrganizationService.getOrganizationDisciplines(organizationIds);
      const disciplineOptions = useDisciplineDropdown(response.data.data);
      disciplines.options = disciplineOptions;
      disciplines.loading = false;
    },

    /**
     * Get the event types (services) available for the specific organization.
     */
    async getEventTypes() {
      const { eventTypes } = this.filters;
      eventTypes.loading = true;
      const organizationIds = useReportStore().organizationIds;
      const response = await OrganizationService.getOrganizationServices(organizationIds);
      const only: string[] = [
        EVENT_TYPE['CONSULTATION'],
        EVENT_TYPE['EVALUATION'],
        EVENT_TYPE['RESPONSE_TO_INTERVENTION'],
        EVENT_TYPE['SESSION'],
      ];

      const eventTypeOptions = response.data.data
        .filter((item: ServiceInterface) => only.includes(item.name))
        .map(({ name }: ServiceInterface) => ({ name: name, value: name }));
      eventTypes.options = eventTypeOptions;
      eventTypes.loading = false;
    },
  },
});
