import { AxiosResponse } from 'axios';
import { OrganizationService } from '@/services';
import { defineStore } from 'pinia';
import { useAuthStore, useOrganizationStore } from '@/store';
import { FacilitatorInterface, FacilitatorStateInterface, FacilitatorTableInterface } from '@/types/Facilitator';
import FacilitatorService from '@/services/FacilitatorService';
import { OrganizationsInterface } from '@/types';

export default defineStore('FacilitatorStore', {
  state: (): FacilitatorStateInterface => ({ facilitators: [], error: '', loading: false, showArchived: false }),

  getters: {
    orgId: () => (useOrganizationStore().organization.id as number) || useAuthStore().organizationId,
  },

  actions: {
    catchError(e: string) {
      this.loading = false;
      this.error = e;
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * attachFacilitators
     *
     * Assign facilitators to a specific organization
     * @param payload {facilitators: number[]}
     */
    async attachFacilitators(payload: { facilitators: number[] }): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      const response = await OrganizationService.attachPerson(payload, organizationId, 'facilitators').catch(
        this.catchError,
      );
      this.error = undefined;

      return response;
    },

    /**
     * detachFacilitators
     *
     * Remove facilitators from a specific organization
     * @param payload {facilitators: number[]}
     */
    async detachFacilitators(payload: { facilitators: number[] }): Promise<AxiosResponse<unknown>> {
      const organizationId = this.orgId;
      const response = await OrganizationService.detachPerson(payload, organizationId, 'facilitators').catch(
        this.catchError,
      );
      this.error = undefined;

      return response;
    },

    /**
     * getFacilitators
     *
     * Retrieve all facilitators assigned to an organzation
     *
     * @return FacilitatorInterface[]
     */
    async getFacilitators(filter: string): Promise<FacilitatorInterface[]> {
      this.loading = true;
      const organizationId = this.orgId;
      const request = await OrganizationService.getPerson(organizationId, 'facilitators', filter).catch(
        this.catchError,
      );
      this.facilitators = request.data?.data;
      this.error = undefined;
      this.loading = false;
      return this.facilitators as FacilitatorInterface[];
    },

    /**
     * getFacilitatorsForTable
     *
     * @return FacilitatorTableInterface[]
     */
    async getFacilitatorsForTable(filter: string): Promise<FacilitatorTableInterface[]> {
      const rawFacilitatorsData = await this.getFacilitators(filter);
      const tableData = rawFacilitatorsData.map((facilitator) => {
        return {
          id: facilitator.id,
          name: facilitator.display_name as string,
          organizations: facilitator.organizations,
          archived: !!facilitator.archived_at,
        };
      });
      return this.showArchived ? tableData : tableData.filter((item) => !item.archived);
    },

    async getAddFacilitatorsModalData(): Promise<{ name: string; value: number }[]> {
      const organizationId = this.orgId;
      const request = await OrganizationService.getPersonAvailableToAdd(organizationId, 'facilitators').catch(
        this.catchError,
      );
      const availableToAdd: FacilitatorInterface[] = request.data?.data.filter((item) => !item.archived_at);

      return availableToAdd.map((facilitator) => {
        return {
          value: facilitator.id,
          name: facilitator.display_name as string,
        };
      });
    },

    /**
     * Remove organization from a specific facilitator
     */
    async detachOrganizations(payload: {
      organizations: number[];
      facilitatorId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { facilitatorId, organizations } = payload;
      const response = await FacilitatorService.detachOrganizationsFromFacilitator(organizations, facilitatorId).catch(
        this.catchError,
      );

      this.loaded();

      return response;
    },

    /**
     * Assign organization to a specific school admin
     */
    async attachOrganizations(payload: {
      organizations: number[];
      facilitatorId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { facilitatorId, organizations } = payload;
      const response = await FacilitatorService.attachOrganizationsToFacilitator(organizations, facilitatorId).catch(
        this.catchError,
      );

      this.loaded();

      return response;
    },

    /**
     * Retrieve organization available to attach to a facilitator
     */
    async getAddOrganizationsModalData(facilitatorId: number): Promise<OrganizationsInterface[]> {
      const response = await FacilitatorService.getAddOrganizationsModalData(facilitatorId).catch(this.catchError);
      const availableToAdd: OrganizationsInterface[] = response.data?.data;

      return availableToAdd;
    },
  },
});
