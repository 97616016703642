import { defineStore } from 'pinia';
import { useReportStore } from '@/store';
import { useDownload } from '@/composables';
import PayrollReportService from '@/services/PayrollReportService';
import { DateFilterInterface, PayrollReportInterface } from '@/types';

export default defineStore('PayrollReportStore', {
  state: () => {
    return {
      queryParams: undefined,
      payroll: {
        downloading: false,
        loading: false,
        data: [] as PayrollReportInterface[],
        total: 0,
        limit: 5000,
      },
      showAllColumns: true,
    };
  },

  actions: {
    /**
     * Get the payroll data
     */
    //eslint-disable-next-line
    async getPayroll(filters: DateFilterInterface) {
      this.payroll.loading = true;
      const clinicians = useReportStore().filters.clinicians.selected;
      const organizations = Object.keys(useReportStore().filters.organizations.selected).map((organizationId) =>
        Number(organizationId),
      );
      const request = await PayrollReportService.getPayrollReport(filters, organizations, clinicians);

      this.payroll.data = request.data.data.length ? request.data.data : [];
      this.payroll.total = request.data.meta.total;
      this.payroll.loading = false;
    },
    async downloadPayrollReport(filter: DateFilterInterface, type = 'csv') {
      this.payroll.downloading = true;
      const clinicians = useReportStore().filters.clinicians.selected;
      const organizations = Object.keys(useReportStore().filters.organizations.selected).map((organizationId) =>
        Number(organizationId),
      );
      const request = await PayrollReportService.downloadPayrollReport(filter, organizations, clinicians, type);

      useDownload(request.data, 'payroll_report', type);
      this.payroll.downloading = false;
    },
  },
});
