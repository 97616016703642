export enum CALENDAR_FILTER_DEFAULTS {
  EVENT_TYPE = 'All Event Types',
  STATUS = 'All Event Statuses',
  CLINICIAN = 'All Service Providers',
  ORGANIZATION = 'All Organizations',
  DISCIPLINE = 'All Disciplines',
}

export const CALENDAR_STATUS_FILTERS = ['Documented', 'Undocumented'];

export enum CALENDAR_COLOR {
  EVENT_BACKGROUND_COLOR = '#ffffff',
  EVENT_BORDER_COLOR = '#f1f3f3',
  EVENT_TEXT_COLOR = '#3B4242',
}

export enum GOAL_TYPE {
  TRIALS = 'Trials',
  PERCENTAGE = 'Percentage',
}

export enum ACTIVITY_EVENT_TYPE {
  IMPORT = 'IMPORT',
  RSM_FILE_PROCESSED = 'RSM_FILE_PROCESSED',
  THERAPY_RESULT = 'THERAPY_RESULT',
  BULK_ATTENDANCE = 'BULK_ATTENDANCE',
  IMPERSONATE = 'IMPERSONATE',
  PERSON_ARCHIVE = 'PERSON_ARCHIVE',
  PERSON_CREATE = 'PERSON_CREATE',
  PERSON_UNARCHIVE = 'PERSON_UNARCHIVE',
}

export enum EVENT_TYPE {
  SESSION = 'Session',
  CONSULTATION = 'Consultation',
  EVALUATION = 'Evaluation',
  IEP_MEETING = 'IEP Meeting',
  RECORD_REVIEW = 'Record Review',
  RESPONSE_TO_INTERVENTION = 'Response to Intervention',
  CASELOAD_MANAGEMENT = 'Caseload Management',
}

export enum DISCIPLINE_ATTENDEE_REQUIRED_EVENT_TYPE {
  SESSION = EVENT_TYPE.SESSION,
  EVALUATION = EVENT_TYPE.EVALUATION,
  RESPONSE_TO_INTERVENTION = EVENT_TYPE.RESPONSE_TO_INTERVENTION,
  CONSULTATION = EVENT_TYPE.CONSULTATION,
  REVORD_REVIEW = EVENT_TYPE.RECORD_REVIEW,
  IEP_MEETING = EVENT_TYPE.IEP_MEETING,
}

export enum SESSION_TYPE {
  REGULAR = 'Regular',
  APPROVED_COMPENSATION = 'Approved Compensation',
  EXTENDED_SCHOOLYEAR = 'Extended School Year',
  MAKEUP = 'Make-up',
}

export enum CASELOAD_MANAGEMENT_TYPE {
  EVALUATION = 'Evaluations Report',
  PROGRESS = 'Progress Report',
  PREP = 'Prep/Planning/Paperwork',
  SUPERVISION = 'Supervision',
}

export enum RRULE_WEEKDAY {
  Mo = 0,
  Tu = 1,
  We = 2,
  Th = 3,
  Fr = 4,
  Sa = 5,
  Su = 6,
}

export enum ATTENDANCE_TYPE_ID {
  PRESENT = 1,
  ABSENT = 2,
  CLINICIAN_CANCELLED = 3,
  ATTENDEE_CANCELLED = 4,
  NO_SCOOL = 5,
  LATE_CANCEL = 6,
}

export enum TRIAL_GOAL_PARAMS {
  MAX_ALLOWED = 50,
  MIN_ALLOWED = 2,
}

export enum PERCENT_GOAL_PARAMS {
  MAX_ALLOWED = 100,
  MIN_ALLOWED = 0,
}

export enum THERAPY_RESULT_TYPES {
  PRESENT = 1,
  ABSENT = 2,
  CLINICIAN_CANCELLED = 3,
  CUSTOMER_CANCELLED = 4,
  NO_SCHOOL = 5,
  LATE_CANCEL = 6,
}

export enum THERAPY_RESULT_TYPES_NAME {
  PRESENT = 'Present',
  NO_SCHOOL = 'No School',
  NO_SHOW = 'No Show',
  ABSENT = 'Absent',
  LATE_CANCEL = 'Late Cancel',
  CLINICIAN_CANCELLED = 'Service Provider Cancelled',
  CUSTOMER_CANCELLED = 'Customer Cancelled',
}

export enum THERAPY_EVENT_ICONS {
  SESSION = 'perm_contact_calendar',
  CONSULTATION = 'assignment',
  EVALUATION = 'assignment_turned_in',
  IEP_MEETING = 'assignment_ind',
  RECORD_REVIEW = 'rate_review',
  RESPONSE_TO_INTERVENTION = 'support',
  CASELOAD_MANAGEMENT = 'settings',
}
