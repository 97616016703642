import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

type TenantIntegrationService = {
  getRoles: (tenantId: number) => ApiPromise;
  getUsers: (tenantId: number) => ApiPromise;
  saveIntegration: (tenantId: number, payload: FormData) => ApiPromise;
  updateIntegration: (tenantId: number, integrationId: number, payload: FormData) => ApiPromise;
  getIntegration: (tenantId: number) => ApiPromise;
};

const resource = 'tenant';

export const TenantIntegrationService: TenantIntegrationService = {
  getRoles: (tenantId: number) => {
    return HTTP.get(`${resource}/${tenantId}/roles`);
  },
  getUsers: (tenantId: number) => {
    return HTTP.get(`${resource}/${tenantId}/users`);
  },
  saveIntegration: (tenantId: number, payload: FormData) => {
    return HTTP.post(`${resource}/${tenantId}/integrations`, payload);
  },
  updateIntegration: (tenantId: number, integrationId: number, payload: FormData) => {
    return HTTP.post(`${resource}/${tenantId}/integrations/${integrationId}`, payload);
  },
  getIntegration: (tenantId: number) => {
    return HTTP.get(`${resource}/${tenantId}/integrations`);
  },
};
