export enum REQUEST {
  ACTIVITY_STUDENTS = 'ACTIVITY_STUDENTS',
  ALL_ATTENDANCE = 'ALL_ATTENDANCE',
  ALL_COMPLIANCE = 'ALL_CONMPLIANCE',
  COMPLIANCE = 'COMPLIANCE',
  EVALUATION = 'EVALUATION',
  OVERLAPPING_EVENTS = 'OVERLAPPING_EVENTS',
  EVENTS = 'EVENTS',
  EVENT_BY_ORGANIZATION = 'EVENT_BY_ORGANIZATION',
  EVENT_REPORT = 'EVENT_REPORT',
  REPORT_REVIEW_TRACK = 'REPORT_REVIEW_TRACK',
  REPORT_REVIEW_CLINICIANS = 'REPORT_REVIEW_CLINICIANS',
  REPORT_REVIEW_CLINICIAN_MINUTES = 'REPORT_REVIEW_CLINICIAN_MINUTES',
  REPORT_REVIEW_ATTENDANCE = 'REPORT_REVIEW_ATTENDANCE',
  REPORT_REVIEW_STUDENTS = 'REPORT_REVIEW_STUDENTS',
  REPORT_REVIEW_EXITED_STUDENTS = 'REPORT_REVIEW_EXITED_STUDENTS',
  LOGS = 'LOGS',
  PEOPLE_TOTALS = 'PEOPLE_TOTALS',
  PEOPLE = 'PEOPLE',
  PEOPLE_TYPES = 'PEOPLE_TYPES',
  ORGANIZATION_TYPES = 'ORGANIZATION_TYPES',
  REPORT_ATTENDEES = 'REPORT_ATTENDEES',
  REPORT_STUDENT_TOTAL = 'REPORT_STUDENT_TOTAL',
  REFERRALS_STUDENTS = 'REFERRALS_STUDENTS',
  REFERRALS_STUDENTS_ASSIGN = 'REFERRALS_STUDENTS_ASSIGN',
  REFERRALS_OPTIONS = 'REFERRALS_OPTIONS',
  REFERRALS_APPROVE = 'REFERRALS_APPROVE',
  REFERRALS_ORGANIZATIONS = 'REFERRALS_ORGANIZATIONS',
  REFERRALS_PROCESS = 'REFERRALS_PROCESS',
}
