import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class UserService {
  static resource = 'user';

  static getOrganization(): ApiPromise {
    return HTTP.get(`${this.resource}/organization`);
  }

  static getOrganizations(listFormat?: boolean): ApiPromise {
    const filter = listFormat ? '?list_format=1' : '';
    return HTTP.get(`${this.resource}/organizations${filter}`);
  }

  static getUserRole(): ApiPromise {
    return HTTP.get(`${this.resource}/roles-permissions`);
  }

  static getPermissions(): ApiPromise {
    return HTTP.get(`${this.resource}/permissions`);
  }
}
