import { ApiPromise, FetchMessagesPayload, MessageCenterNewChatPayload } from '@/types';
import { HTTP } from '@/utils';

export default class MessageCenterServer {
  static url = 'person-group-channel';

  static getMyGroupChannels(): ApiPromise {
    return HTTP.get(`${this.url}`);
  }

  static getRecipients(search: string): ApiPromise {
    return HTTP.get(`message-center/recipients?search=${search}`);
  }

  static createGroupChannel(person_id: string | number): ApiPromise {
    return HTTP.post(`${this.url}`, { people: [person_id] });
  }

  static startNewChatConversations(payload: MessageCenterNewChatPayload, message: string): ApiPromise {
    return HTTP.post(`message-center/new-chat`, {
      message: message,
      people_ids: payload.singlePeopleIds,
      teams: payload.teams,
    });
  }

  static getPeopleNames(peopleIds: string[] | number[]): ApiPromise {
    return HTTP.get('message-center/people-names?' + peopleIds.map((personId) => `people_ids[]=${personId}`).join('&'));
  }
  static getCareTeamsByChannels(channels: string[] | number[]): ApiPromise {
    return HTTP.get(
      'message-center/channels-care-team-metadata?' + channels.map((channel) => `channels[]=${channel}`).join('&'),
    );
  }

  static fetchMessages(channelsRequest: FetchMessagesPayload[]): ApiPromise {
    return HTTP.get(`message-center/messages?channels=${JSON.stringify(channelsRequest)}`);
  }
}
