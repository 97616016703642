import { Ref, ref } from 'vue';

import { FileInterface } from '@/types';

/**
 * this composable handles files waiting to be
 * uploaded to the API, or removed from a list.
 * it takes multiple files or a single file in an array
 *
 * @returns {files, addFiles, removeFiles}
 */

export default function () {
  const files: Ref<FileInterface[]> = ref([]);

  const addFiles = (newFiles: FileInterface[]) => {
    const newUploadableFiles: FileInterface[] = [...newFiles]
      .map((file) => new UploadableFile(file))
      .filter((file) => !fileExists(file.id));
    files.value = files.value.concat(newUploadableFiles);
  };

  const fileExists = (otherId: string) => {
    return files.value.some(({ id }) => id === otherId);
  };

  const removeFile = (file: FileInterface) => {
    let index;
    if (file) {
      index = files.value.indexOf(file);
      if (index > -1) files.value.splice(index, 1);
    }
  };

  return { files, addFiles, removeFile };
}

/**
 * UploadableFile class
 */
class UploadableFile {
  constructor(file: FileInterface) {
    this.file = file;
    this.name = file.name;
    this.size = file.size;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
  }

  public file;
  public id;
  public size;
  public name;
}
