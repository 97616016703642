import { ApiPromise } from '@/types';
import { HTTP } from '@/utils';

export default class ServicesService {
  static resource = 'services';

  static getServices(): ApiPromise {
    return HTTP.get(`${this.resource}`);
  }

  static getOrganizationServices(organizationId: number): ApiPromise {
    return HTTP.get(`organizations/${organizationId}/${this.resource}`);
  }

  static syncOrganizationServices(organizationId: number, payload: Record<string, string[]>): ApiPromise {
    return HTTP.post(`organizations/${organizationId}/sync-services`, payload);
  }

  static getClinicianServices(clinicianId: number): ApiPromise {
    return HTTP.get(`clinicians/${clinicianId}/${this.resource}`);
  }
}
