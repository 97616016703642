import { HTTP } from '@/utils';
import { REQUEST } from '@/enum';
import {
  ApiPromise,
  ReferralPayloadInterface,
  UserReferralListFilterInterface,
  ReferralCaseloadsFilterInterface,
  ReferralProcessInterface,
} from '@/types';
import { useRequestCancel } from '@/composables/useRequestCancel';

export default class ReferralService {
  static resource = 'referral';
  static requestCancel = useRequestCancel();

  static getReferralCaseload(payload?: ReferralCaseloadsFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof ReferralCaseloadsFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.resource}/caseload${filter}`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REFERRALS_STUDENTS),
    });
  }

  static getReferralOrganizations(): ApiPromise {
    return HTTP.get(`${this.resource}/organizations`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REFERRALS_ORGANIZATIONS),
    });
  }

  static getReferralAssign(payload?: UserReferralListFilterInterface): ApiPromise {
    let filter = '';

    if (payload) {
      filter = `?${Object.keys(payload)
        .map((item) => `${item}=${payload[item as keyof UserReferralListFilterInterface]}`)
        .join('&')}`;
    }

    return HTTP.get(`${this.resource}/assign${filter}`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REFERRALS_STUDENTS_ASSIGN),
    });
  }

  static getCliniciansAvailableForReferral(organizationIds: number[]): ApiPromise {
    return HTTP.get(`${this.resource}/available-to-assign`, {
      params: {
        organizations: organizationIds,
      },
    });
  }

  static getReferrralOptions(): ApiPromise {
    return HTTP.get(`${this.resource}/options`, {
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REFERRALS_OPTIONS),
    });
  }

  //POST CALLS
  static process(payload: ReferralProcessInterface): ApiPromise {
    return HTTP.post(`${this.resource}/process`, {
      ...payload,
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REFERRALS_PROCESS),
    });
  }

  static approveReferrals(payload: ReferralPayloadInterface): ApiPromise {
    return HTTP.post(`${this.resource}/approve/${payload.id}`, {
      ...{ service_provider_id: payload.service_provider_id },
      ...this.requestCancel.requestSaverSetCancellation(REQUEST.REFERRALS_APPROVE),
    });
  }
}
