import { StudentService } from '@/services';
import { defineStore } from 'pinia';
import { StudentFacilitatorInterface, StudentFacilitatorStateInterface } from '@/types/Student';
import { AxiosResponse } from 'axios';

export default defineStore('StudentFacilitatorStore', {
  state: (): StudentFacilitatorStateInterface => ({
    facilitators: [],
    facilitatorsForStudentSelected: [],
    error: '',
    loading: false,
  }),
  getters: {},
  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },
    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * getAddFacilitatorsModalData
     *
     * Retrieve all facilitators available to attach to a student
     *
     */
    async getAddFacilitatorsModalData(studentId: number): Promise<{ name: string; value: number }[]> {
      const request = await StudentService.getAddFacilitatorsModalData(studentId).catch(this.catchError);
      const availableToAdd: StudentFacilitatorInterface[] = request.data?.data;

      return availableToAdd.map((facilitator) => {
        return {
          value: facilitator.id,
          name: facilitator.display_name as string,
        };
      });
    },

    async attachFacilitators(payload: { facilitators: number[]; studentId: number }): Promise<AxiosResponse<unknown>> {
      const { studentId, facilitators } = payload;
      const response = await StudentService.attachFacilitatorsToStudent(facilitators, studentId).catch(this.catchError);
      this.loaded();
      return response;
    },

    async detachFacilitators(payload: {
      facilitatorsIds: number[];
      studentId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { studentId, facilitatorsIds } = payload;
      const response = await StudentService.detachFacilitatorsToStudent(facilitatorsIds, studentId).catch(
        this.catchError,
      );

      this.loaded();
      return response;
    },
  },
});
