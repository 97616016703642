import { AxiosResponse } from 'axios';
import { defineStore } from 'pinia';
import { ClinicianService, OrganizationService } from '@/services';
import { OrganizationsInterface } from '@/types';
import {
  ClinicianInterface,
  ClinicianOrganizationInterface,
  ClinicianOrganizationStateInterface,
} from '@/types/Clinician';

export default defineStore('ClinicianOrganizationStore', {
  state: (): ClinicianOrganizationStateInterface => ({
    organizations: [],
    organizationsForClinicianSelected: [],
    error: '',
    loading: false,
    clinicians: [],
  }),

  getters: {},

  actions: {
    catchError(e: Error) {
      this.loading = false;
      this.error = 'error';
      throw e;
    },

    loaded() {
      this.loading = false;
      this.error = undefined;
    },

    /**
     * Assign organization to a specific clinician
     */
    async attachOrganizations(payload: {
      organizations: number[];
      clinicianId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { clinicianId, organizations } = payload;
      const response = await ClinicianService.attachOrganizationsRequest(organizations, clinicianId).catch(
        this.catchError,
      );

      this.loaded();
      return response;
    },

    /**
     * Remove organization from a specific clinician
     */
    async detachOrganizations(payload: {
      organizations: number[];
      clinicianId: number;
    }): Promise<AxiosResponse<unknown>> {
      const { clinicianId, organizations } = payload;
      const response = await ClinicianService.detachOrganizationsRequest(organizations, clinicianId).catch(
        this.catchError,
      );

      this.loaded();
      return response;
    },

    /**
     * getOrganizationForclinician
     *
     * Retrieve organization assigned to a clinician
     *
     */
    async getOrganizationsForClinician(clinicianId: number): Promise<ClinicianOrganizationInterface[]> {
      this.loading = true;
      const response = await ClinicianService.getOrganizationsForClinicianRequest(clinicianId).catch(this.catchError);
      this.organizationsForClinicianSelected = response.data?.data as ClinicianOrganizationInterface[];

      if (Array.isArray(this.organizationsForClinicianSelected) && !this.organizationsForClinicianSelected.length) {
        return [];
      } else if (!Array.isArray(this.organizationsForClinicianSelected)) {
        this.organizationsForClinicianSelected = [this.organizationsForClinicianSelected];
      }
      this.loaded();
      return this.organizationsForClinicianSelected;
    },

    /**
     * Retrieve organization available to attach to a clinician
     */
    async getAddOrganizationsModalData(clinicianId: number): Promise<OrganizationsInterface[]> {
      const request = await ClinicianService.getAddOrganizationsModalData(clinicianId).catch(this.catchError);
      const availableToAdd: OrganizationsInterface[] = request.data?.data;

      return availableToAdd;
    },

    async getCliniciansForOrganizationRequest(id: number) {
      this.loading = true;
      const request = await ClinicianService.getCliniciansForOrganizationRequest(id);
      this.clinicians = request.data.data;
      this.loading = false;
    },

    /**
     * Retrieve clinicians available to attach to a contract
     */
    async getCliniciansAvailableToAddToContract(organizationId: number): Promise<ClinicianInterface[]> {
      this.loading = true;
      const request = await OrganizationService.getPersonAvailableToAdd(organizationId, 'clinicians');
      this.loading = false;
      return request.data.data;
    },
  },
});
