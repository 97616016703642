import { defineStore } from 'pinia';
import { ProgressReportService, ReportService } from '@/services';
import { useReportStore } from '@/store';
import { useDownload, usePersonDropdown, useRequestCancel } from '@/composables';
import {
  ProgressReportFilterInterface,
  ProgressReportInterface,
  ProgressReportStateInterface,
  ProgressReportDownloadInterface,
} from '@/types';
import { REQUEST } from '@/enum';

export default defineStore('ProgressReportStore', {
  state: (): ProgressReportStateInterface => {
    return {
      queryParams: undefined,
      progress: {
        downloading: false,
        loading: false,
        data: [] as ProgressReportInterface[],
      },
      filters: {
        attendees: {
          options: [] as { name: string; value: number }[],
          selected: null as number | null,
          loading: false,
        },
        cm: false,
      },
    };
  },

  getters: {
    attendeeName: (state) => {
      const attendees = state.filters.attendees;
      const findAttendee = attendees.options.find((item) => item.value === attendees.selected);
      return findAttendee ? findAttendee.name : '';
    },
  },

  actions: {
    /**
     * Get the attendees available for the specific organization or clinician.
     */
    async getAttendees() {
      const clinicianIds = useReportStore().filters.clinicians.selected;
      const noClinicians = !clinicianIds || !Object.keys(clinicianIds).length;
      const organizationIds = Object.keys(useReportStore().filters.organizations.selected).map((organizationId) =>
        Number(organizationId),
      );
      const startAt = useReportStore().filters.dates[0];
      const endAt = useReportStore().filters.dates[1];
      const attendeeListId = useReportStore().attendeeListCacheId;
      const { attendees } = this.filters;
      attendees.loading = true;
      const params = noClinicians
        ? {
            organizationIds,
            startAt,
            endAt,
            attendeeListId,
          }
        : {
            organizationIds,
            clinicianIds,
            startAt,
            endAt,
            attendeeListId,
          };
      if (attendees.loading) useRequestCancel().requestSaverAbort(REQUEST.REPORT_ATTENDEES);

      const response = await ReportService.getReportAttendeeFilter(params);
      if (response?.data?.data) {
        const attendeeOptions = usePersonDropdown(response.data.data);
        attendees.options = attendeeOptions;
      }
      attendees.loading = false;
    },

    /**
     * Get the progress report data with or without any selected filters.
     */
    async getProgressReport(filters: ProgressReportFilterInterface) {
      this.progress.loading = true;
      const response = await ProgressReportService.getProgressReport(filters);
      this.progress.data = response.data;
      this.progress.loading = false;
    },

    /**
     * Download the progress report data with or without any selected filters.
     */
    async downloadProgressReport(payload: ProgressReportDownloadInterface) {
      this.progress.downloading = true;
      const response = await ProgressReportService.downloadProgressReport(payload);
      const fileName = `Progress Report For ${payload.data.full_name}.pdf`;
      useDownload(response.data, fileName, 'pdf');
      this.progress.downloading = false;
    },
  },
});
